<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Field_Archiving_Archived" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-field-signature-revoked">
    <rect id="Icon_Bounding_Box_16x16" width="32" height="32" fill="#bf1718" opacity="0"/>
    <path class="a" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(1 1)" fill="#001366"/>
    <path class="b" d="M7514.046,6117.572a8.286,8.286,0,0,0,0-11.089c-.147-.17-.3-.331-.458-.484l-4.08,4.072V6103.9s-.006,0-.008,0l.008-.027V6100H7519.6l-3.389,3.383c.209.212.419.438.62.673a11.993,11.993,0,0,1,0,15.944Z" transform="translate(-7498.684 -6094)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "FieldArchivingArchived"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
  .a {
    fill: currentColor;
  }
  .b {
    fill: #FFF;
  }
  .theme--dark .b {
    fill: #000;
  }
</style>

