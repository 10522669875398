import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import MtIconFieldError from '../../src/lib-components/icons/MtIconFieldError.vue'
import MtIconFieldSuccess from '../../src/lib-components/icons/MtIconFieldSuccess.vue'
import MtIconFieldWarning from '../../src/lib-components/icons/MtIconFieldWarning.vue'
import MtActionTaskCreate from '../../src/lib-components/icons/MtActionTaskCreate.vue'
import MtDialogFinished from '../../src/lib-components/icons/MtDialogFinished.vue'
import MtDialogInformation from '../../src/lib-components/icons/MtDialogInformation.vue'
import MtDialogWarning from '../../src/lib-components/icons/MtDialogWarning.vue'
import MtDialogError from '../../src/lib-components/icons/MtDialogError.vue'
import MtFieldSuccess from '../../src/lib-components/icons/MtFieldSuccess.vue'
import MtFieldError from '../../src/lib-components/icons/MtFieldError.vue'
import MtFieldWarning from '../../src/lib-components/icons/MtFieldWarning.vue'
import MtFieldFavorite from '../../src/lib-components/icons/MtFieldFavorite.vue'
import MtFieldSignatureStep0 from '../../src/lib-components/icons/MtFieldSignatureStep0.vue'
import MtFieldSignatureStep1 from '../../src/lib-components/icons/MtFieldSignatureStep1.vue'
import MtFieldSignatureStep2 from '../../src/lib-components/icons/MtFieldSignatureStep2.vue'
import MtFieldSignatureStep3 from '../../src/lib-components/icons/MtFieldSignatureStep3.vue'
import MtFieldSignatureRevoked from '../../src/lib-components/icons/MtFieldSignatureRevoked.vue'
import MtFieldArchivingArchived from '../../src/lib-components/icons/MtFieldArchivingArchived.vue'
import MtFieldArchivingUndone from '../../src/lib-components/icons/MtFieldArchivingUndone.vue'
import MtOverlayBusy from '../../src/lib-components/icons/MtOverlayBusy.vue'
import MtOverlayFinished from '../../src/lib-components/icons/MtOverlayFinished.vue'

Vue.use(Vuetify);

// TODO icons lib separate + right path definitiona for prod
// TODO theme conf separate

export default new Vuetify( {
        icons: {
            iconfont: 'mdi',
            values: {
                mtIconFieldError: {
                    component: MtIconFieldError
                },
                mtIconFieldSuccess: {
                    component: MtIconFieldSuccess
                },
                mtIconFieldWarning: {
                    component: MtIconFieldWarning
                },
				mtActionTaskCreate: {
                    component: MtActionTaskCreate
                },
				mtDialogFinished: {
                    component: MtDialogFinished
                },
				mtDialogInformation: {
                    component: MtDialogInformation
                },
				mtDialogWarning: {
                    component: MtDialogWarning
                },
				mtDialogError: {
                    component: MtDialogError
                },
				mtFieldSuccess: {
                    component: MtFieldSuccess
                },
				mtFieldError: {
                    component: MtFieldError
                },
				mtFieldWarning: {
                    component: MtFieldWarning
                },
				mtFieldFavorite: {
                    component: MtFieldFavorite
                },
				mtFieldSignatureStep0: {
                    component: MtFieldSignatureStep0
                },
				mtFieldSignatureStep1: {
                    component: MtFieldSignatureStep1
                },
				mtFieldSignatureStep2: {
                    component: MtFieldSignatureStep2
                },
				mtFieldSignatureStep3: {
                    component: MtFieldSignatureStep3
                },
				mtFieldSignatureRevoked: {
                    component: MtFieldSignatureRevoked
                },
				mtFieldArchivingArchived: {
                    component: MtFieldArchivingArchived
                },
				mtFieldArchivingUndone: {
                    component: MtFieldArchivingUndone
                },
				mtOverlayBusy: {
                    component: MtOverlayBusy
                },
				mtOverlayFinished: {
                    component: MtOverlayFinished
                }
            },
        },
        theme: {
            themes: { // TODO colors binding and dark theme
                light: {
                    error: '#e6223a',
                    info: '#0524AD',
                    primary: '#001366',
                    secondary: '#001366',
                    success: '#168A00',
                    warning: '#FFCF33',
                    accent: '#6271B6',
                    sun_glow: '#ffcf33',
                    white_nectar: '#fcf8d9',
                    drover: '#FDEBA0',
                    sweet_corn: '#FEDD6B',
                    la_palma: '#35b51c',
                    lima: '#3fcb24',
                    lime_green: '#4ce52e',
                    green: '#168A00',
					green_2: '#157B00',
					myrtle: '#0C4B00',
                    panache: '#ebf8e8',
                    blue_romance: '#D9F0D1',
                    snowy_mint: '#CAE8C5',
                    madang: '#B4DDAD',
                    alizarin: '#e6223a',
                    white_smoke: '#fcefef',
                    pale_pink: '#FADCDE',
                    we_peep: '#F8CDD1',
                    light_pink: '#F6B8BF',
                    wild_blue_yonder: '#8089b2',
                    midnight_blue: '#001366',
                    bunker: '#0f141a',
                    port_gore: '#222252',
                    pickled_bluewood: '#384063',
                    fiord: '#3f4769',
                    blue_boyoux: '#505982',
                    waikawa_gray2: '#576087',
                    waikawa_grey1: '#5b6699',
                    east_bay: '#4d5587',
                    lynch: '#656f99',
                    blue_violet: '#6271b5',
                    manatee: '#8b8bab',
                    cadet_blue: '#979ebd',
                    chetwode_blue: '#5766C7',
                    logan: '#adb3cc',
                    danube: '#6272d6',
					international_klein_blue: '#0524AD',
                    corn_flower_blue: '#576ed9',
                    cold_purple: '#abb5df',
                    sail: '#b0befa',
                    lavender_blue: '#bfcbff',
                    periwinkel: '#d3dbff',
                    titan_white: '#e0e6ff',
                    athens_grey: '#e4e6ef',
                    white_lilac: '#f1f3fa',
                    alabaster: '#f7f7f7',
                    solitude: '#eff1fc',
                    white: '#ffffff',
                    black: '#000000',
                },
				dark: {
					error: '#E6223A',
                    info: '#0524AD',
                    primary: '#eff1fc',
                    secondary: '#eff1fc',
                    success: '#3BCC1F',
                    warning: '#FFCF33',
                    accent: '#6271B6',
                    sun_glow: '#ffcf33',
                    white_nectar: '#fcf8d9',
                    drover: '#FDE998',
                    sweet_corn: '#FEDD6B',
                    la_palma: '#35b51c',
                    lima: '#3fcb24',
                    lime_green: '#4ce52e',
                    green: '#168A00',
					green_2: '#157B00',
					myrtle: '#0C4B00',
                    panache: '#ebf8e8',
                    blue_romance: '#DAF0D6',
                    snowy_mint: '#CAE8C5',
                    madang: '#B4DDAD',
                    alizarin: '#e6223a',
                    white_smoke: '#fcefef',
                    pale_pink: '#FADCDE',
                    we_peep: '#F8CDD1',
                    light_pink: '#F6B8BF',
                    wild_blue_yonder: '#8089b2',
                    midnight_blue: '#eff1fc',  // solitude
                    bunker: '#0f141a',
                    port_gore: '#222252',
                    pickled_bluewood: '#384063',
                    fiord: '#3f4769',
                    blue_boyoux: '#505982',
                    waikawa_gray2: '#576087',
                    waikawa_grey1: '#5b6699',
                    east_bay: '#4d5587',
                    lynch: '#656f99',
                    blue_violet: '#6271b5',
                    manatee: '#8b8bab',
                    cadet_blue: '#979ebd',
                    chetwode_blue: '#8d9bd9',
                    logan: '#adb3cc',
                    danube: '#6272d6',
					international_klein_blue: '#0524AD',
                    corn_flower_blue: '#576ed9',
                    cold_purple: '#abb5df',
                    sail: '#212B38',  // custom color
                    lavender_blue: '#383C4F',  // custom color
                    periwinkel: '#30333E',  // custom color
                    titan_white: '#383C4F',  // custom color
                    athens_grey: '#e4e6ef',
                    white_lilac: '#f1f3fa',
                    alabaster: '#f7f7f7',
                    solitude: '#eff1fc',
                    white: '#ffffff',
                    black: '#000000',
				}
            },
            options: {
                customProperties: true,
                variations: false
            },
        },
}
);
