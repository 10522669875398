<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Dialog_Information" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-dialog-information">
    <rect id="Icon_Bounding_Box" width="32" height="32" fill="#bf1718" opacity="0"/>
    <g id="Pfad_961" data-name="Pfad 961" transform="translate(1 1)" fill="#0524ad">
      <path d="M 15 29.5 C 11.12691020965576 29.5 7.485640048980713 27.99173927307129 4.746950149536133 25.25304985046387 C 2.008260011672974 22.51436042785645 0.5 18.87309074401855 0.5 15 C 0.5 11.12691020965576 2.008260011672974 7.485640048980713 4.746950149536133 4.746950149536133 C 7.485640048980713 2.008260011672974 11.12691020965576 0.5 15 0.5 C 18.87309074401855 0.5 22.51436042785645 2.008260011672974 25.25304985046387 4.746950149536133 C 27.99173927307129 7.485640048980713 29.5 11.12691020965576 29.5 15 C 29.5 18.87309074401855 27.99173927307129 22.51436042785645 25.25304985046387 25.25304985046387 C 22.51436042785645 27.99173927307129 18.87309074401855 29.5 15 29.5 Z" stroke="none"/>
      <path d="M 15 1 C 11.26046943664551 1 7.744760513305664 2.456249237060547 5.100509643554688 5.100509643554688 C 2.456249237060547 7.744760513305664 1 11.26046943664551 1 15 C 1 18.73952865600586 2.456249237060547 22.25523948669434 5.100509643554688 24.89949035644531 C 7.744760513305664 27.54375076293945 11.26046943664551 29 15 29 C 18.73952865600586 29 22.25523948669434 27.54375076293945 24.89949035644531 24.89949035644531 C 27.54375076293945 22.25523948669434 29 18.73952865600586 29 15 C 29 11.26046943664551 27.54375076293945 7.744760513305664 24.89949035644531 5.100509643554688 C 22.25523948669434 2.456249237060547 18.73952865600586 1 15 1 M 15 0 C 23.28426933288574 0 30 6.715730667114258 30 15 C 30 23.28426933288574 23.28426933288574 30 15 30 C 6.715730667114258 30 0 23.28426933288574 0 15 C 0 6.715730667114258 6.715730667114258 0 15 0 Z" stroke="none" fill="#fff"/>
    </g>
    <path id="Pfad_960" data-name="Pfad 960" d="M626,1273v-11.018h-2V1259h7v14Zm0-17v-4h5v4Z" transform="translate(-612 -1247)" fill="#fff"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "DialogInformation"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
</style>

