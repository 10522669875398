<template>
  <i class="mt-icon-tricolor">
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-action-task-create">
    <path class="a" d="M16,25a10.075,10.075,0,0,1,.3-2.5,11.8,11.8,0,0,1-2,.4v2.4L7.5,21.5l6.8-3.8v3.1A8.07,8.07,0,0,0,18,19.3,7.993,7.993,0,0,1,19.3,18a7.165,7.165,0,0,0,.1-8.9l1.7-1.3A9.75,9.75,0,0,1,23,13.5a12.606,12.606,0,0,1-.4,2.8A9.863,9.863,0,0,1,25,16a8.807,8.807,0,0,1,1.7.2,12.413,12.413,0,0,0,.3-2.7A13.5,13.5,0,1,0,13.5,27a12.413,12.413,0,0,0,2.7-.3A8.807,8.807,0,0,1,16,25ZM5.9,19.2A9.75,9.75,0,0,1,4,13.5a9.457,9.457,0,0,1,7.8-9.3V1.7l6.8,3.8L11.8,9.3v-3a7.5,7.5,0,0,0-5.7,7.2,7.1,7.1,0,0,0,1.5,4.4Z"/>
    <path fill="#168a00" d="M32,25a6.957,6.957,0,0,1-7,7c-3.2,0-7-3.1-7-7a7,7,0,0,1,14,0Z"/>
    <path fill="#fff" d="M26,24V20H24v4H20v2h4v4h2V26h4V24Z"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "ActionTaskCreate"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
  .a {
    fill: currentColor;
  }
</style>
