<template>
  <div class="colors-page">

    <mt-page-header :scrollNavigationList="scrollNavigationList">Colors</mt-page-header>

    <!-- Elements Title -->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="vuetifybasecolors">Vuetify Base Colors</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section -->
    <v-container>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler primary">Primary</div>
          <span>class|prop: primary</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler secondary">Secondary</div>
          <span>class|prop: secondary</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler info">Info</div>
          <span>class|prop: info</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler success">Success</div>
          <span>class|prop: success</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler warning">Warning</div>
          <span>class|prop: warning</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler error">Error</div>
          <span>class|prop: error</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler accent">Accent</div>
          <span>class|prop: accent</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler"></div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler"></div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements Title -->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="statuscolors">Status Colors</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section -->
    <v-container>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler green">Green</div>
          <span>class|prop: green</span>
        </v-col>
		<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler snowy_mint dark">Snowy Mint</div>
          <span>class|prop: snowy_mint</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler blue_romance dark">Blue Romance</div>
          <span>class|prop: blue_romance</span>
        </v-col>      
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler panache dark">Panache</div>
          <span>class|prop: panache</span>
        </v-col>
		<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler sun_glow dark">Sunglow</div>
          <span>class|prop: sun_glow</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler drover dark">Drover</div>
          <span>class|prop: drover</span>
        </v-col>       
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler white_nectar dark">White Nectar</div>
          <span>class|prop: white_nectar</span>
        </v-col>
		<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler alizarin">Alizarin</div>
          <span>class|prop: alizarin</span>
        </v-col>
		<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler we_peep dark">We Peep</div>
		  <span>class|prop: we_peep</span>
        </v-col>     
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler pale_pink dark">Pale Pink</div>
          <span>class|prop: pale_pink</span>
        </v-col>
		<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler white_smoke dark">White Smoke</div>
          <span>class|prop: white_smoke</span>
        </v-col>
		<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler international_klein_blue">International Klein Blue</div>
		  <span>class|prop: international_klein_blue</span>
        </v-col>		
      </v-row>

    </v-container>

    <!-- Elements Title -->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="guidancecolors">Guidance Colors</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section -->
    <v-container>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler lime_green dark">Lime Green</div>
          <span>class|prop: lime_green</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler green_2">Green 2</div>
          <span>class|prop: green_2</span>
        </v-col>
		<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler myrtle">Myrtle</div>
          <span>class|prop: myrtle</span>
        </v-col>
      </v-row>

      <br>

      <h3>Gradients:</h3>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler gradient1_a">Lime Green | Green 2 (A)</div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler gradient1_b">Lime Green | Green 2 (B)</div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="col-filler"></div>
        </v-col>
      </v-row>

    </v-container>

    <!-- Elements Title -->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="userinterfacecolors">User Interface Colors</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section -->
    <v-container>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler white dark">White</div>
          <span>class|prop: white</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler solitude dark border">Solitude</div>
          <span>class|prop: solitude</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler alabaster dark">Alabaster</div>
          <span>class|prop: alabaster</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler white_lilac border dark">White Lilac</div>
          <span>class|prop: white_lilac</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler athens_grey dark">Athens Grey</div>
          <span>class|prop: athens_grey</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler titan_white dark">Titan White</div>
          <span>class|prop: titan_white</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler periwinkel dark">Periwinkel</div>
          <span>class|prop: periwinkel</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler lavender_blue dark">Lavender Blue</div>
          <span>class|prop: lavender_blue</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler sail dark">Sail</div>
          <span>class|prop: sail</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler cold_purple dark">Cold Purple</div>
          <span>class|prop: cold_purple</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler corn_flower_blue">Corn Flower Blue</div>
          <span>class|prop: corn_flower_blue</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler danube">Danube</div>
          <span>class|prop: danube</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler logan dark">Logan</div>
          <span>class|prop: logan</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler chetwode_blue">Chetwode Blue</div>
          <span>class|prop: chetwode_blue</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler cadet_blue">Cadet Blue</div>
          <span>class|prop: cadet_blue</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler manatee">Manatee</div>
          <span>class|prop: manatee</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler blue_violet">Blue Violet</div>
          <span>class|prop: blue_violet</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler lynch">Lynch</div>
          <span>class|prop: lynch</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler east_bay">East Bay</div>
          <span>class|prop: east_bay</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler waikawa_grey1">Waikawa Grey 1</div>
          <span>class|prop: waikawa_grey1</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler waikawa_grey2">Waikawa Grey 2</div>
          <span>class|prop: waikawa_grey2</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler blue_boyoux">Blue Boyoux</div>
          <span>class|prop: blue_boyoux</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler fiord">Fiord</div>
          <span>class|prop: fiord</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler pickled_bluewood">Pickled Bluewood</div>
          <span>class|prop: pickled_bluewood</span>
        </v-col>
      </v-row>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler port_gore">Port gore</div>
          <span>class|prop: port_gore</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler bunker">Bunker</div>
          <span>class|prop: bunker</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler midnight_blue">Midnight Blue</div>
          <span>class|prop: midnight_blue</span>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler wild_blue_yonder">Wild Blue Yonder</div>
          <span>class|prop: wild_blue_yonder</span>
        </v-col>
      </v-row>

      <br>

      <h3>Gradients:</h3>

      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler port_gore gradient2">Periwinkel | Sail</div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler bunker gradient3">Corn Flower Blue | East Bay</div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler"></div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <div class="col-filler"></div>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
	  scrollNavigationList: [
	    {
		  linkType: 'position',
		  name: 'Vuetify Base Colors',
		  link: '#vuetifybasecolors'
		},
		{
		  linkType: 'position',
		  name: 'Status Colors',
		  link: '#statuscolors'
		},
		{
		  linkType: 'position',
		  name: 'Guidance Colors',
		  link: '#guidancecolors'
		},
		{
		  linkType: 'position',
		  name: 'User Interface Colors',
		  link: '#userinterfacecolors'
		}
	  ]
    }
  },
  mounted() {
  }
}
</script>
<style scoped>
.col-filler {
  height: 72px;
  text-align: center;
  line-height: 72px;
  color: #FFFFFF;
}

.col-filler.dark {
  color: #000000;
}

.col-filler.border {
  border: 1px solid #000000 !important;
}

.col-filler.gradient1_a {
  background: linear-gradient(90deg, var(--v-lime_green-base) 0%, var(--v-green_2-base) 82%, var(--v-green_2-base) 100%);
}

.col-filler.gradient1_b {
  background: linear-gradient(90deg, var(--v-lime_green-base) 0%, var(--v-green_2-base) 37%, var(--v-green_2-base) 100%);
}

.col-filler.gradient2 {
  background: rgb(211,219,255);
  background: linear-gradient(90deg, rgba(211,219,255,1) 0%, rgba(176,190,250,1) 100%);
}

.col-filler.gradient3 {
  background: rgb(115,136,230);
  background: linear-gradient(90deg, rgba(115,136,230,1) 0%, rgba(77,85,135,1) 100%);
}

.col-filler + span {
  display: block;
  text-align: center;
}
</style>