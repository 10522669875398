<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Field_Archiving_Undone" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-field-signature-undone">
    <rect id="Icon_Bounding_Box_16x16" width="32" height="32" fill="#bf1718" opacity="0"/>
    <path class="a" d="M7509.509,6116.127l-.008-.024a.012.012,0,0,1,.008,0v-6.17l4.083,4.07c.156-.153.308-.314.454-.481a8.291,8.291,0,0,0,0-11.094l2.789-2.427a12,12,0,0,1,0,15.945c-.2.236-.412.459-.621.674L7519.6,6120l-10.094,0Z" transform="translate(-7498.684 -6093.999)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    <g id="Pfad_1190" data-name="Pfad 1190" transform="translate(1 1)" fill="none">
      <path d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" stroke="none"/>
      <path class="a" d="M 15 1 C 11.26046943664551 1 7.744760513305664 2.456249237060547 5.100509643554688 5.100509643554688 C 2.456249237060547 7.744760513305664 1 11.26046943664551 1 15 C 1 18.73952865600586 2.456249237060547 22.25523948669434 5.100509643554688 24.89949035644531 C 7.744760513305664 27.54375076293945 11.26046943664551 29 15 29 C 18.73952865600586 29 22.25523948669434 27.54375076293945 24.89949035644531 24.89949035644531 C 27.54375076293945 22.25523948669434 29 18.73952865600586 29 15 C 29 11.26046943664551 27.54375076293945 7.744760513305664 24.89949035644531 5.100509643554688 C 22.25523948669434 2.456249237060547 18.73952865600586 1 15 1 M 15 0 C 23.28426933288574 0 30 6.715730667114258 30 15 C 30 23.28426933288574 23.28426933288574 30 15 30 C 6.715730667114258 30 0 23.28426933288574 0 15 C 0 6.715730667114258 6.715730667114258 0 15 0 Z" stroke="none""/>
    </g>
  </svg>
  </i>
</template>

<script>
export default {
  name: "FieldArchivingUndone"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
  .a {
    fill: currentColor;
  }
</style>

