<template>
  <div class="mt-6">
    <slot></slot>
    <v-data-table
        dense
        :headers="headers"
        :items="propsData"
        hide-default-footer
        calculate-widths
    >
      <template v-slot:item.name="{ item }">
        {{ item.name }}<span v-if="item.required">*</span>
      </template>
      <template v-slot:item.desc="{ item }">
        <span v-html="item.desc"></span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "PropsTable",
  props: {
    propsData: Array
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          width: '20%'
        },
        {text: 'Type', value: 'type'},
        {text: 'Default', value: 'default'},
        {text: 'Description', value: 'desc'},
      ],
    }
  }
}
</script>
