<template>
  <v-text-field
      :class="[state ? 'v-input--is-state--'+state : '']"
      v-bind="computedProps"
      v-on="$listeners"
  >
    <!-- Dynamically inherit slots from parent -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>
<script>
import { VTextField } from 'vuetify/lib';
export default {
  name: "MtEmailField",
  extends: VTextField,
  data() {
    return {
    }
  },
  props: {
    rules: {
      type: Array,
      default: () => [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      ]
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    state: null
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.v-input {

}
</style>