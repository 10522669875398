<template>
  <div class="demo-page-app-icons">
    <!-- Page Title-->
    <v-container class="mb-3">
      <v-row>
        <v-col>
          <h1>App Icons and Favicon</h1>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col>

          <!-- Buttons-->
          <div class="element-example-wrap">
            <h2 class="mb-10">Size Basic</h2>
            <status-badge status="draft">Currently under development</status-badge>
            BASIC KNOWLEGDE ABOUT APP ICON AND FAVICON AND WHAT SIZES ARE NEEDED. CURRENTLY IN DEVELOPMENT

            <!-- Element usage description-->
            <v-sheet class="pa-4 mt-5" elevation="2">
              <h3 class="mb-3">Usage</h3>
              <v-divider></v-divider>

              <!-- Element code snippet-->
              <pre class="my-3" v-highlightjs><code class="html">{{ sourceCode.app_icon_meta }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  data() {
    return {
      sourceCode: {
        app_icon_meta: '<head>' +
            '<link rel="icon" href="https://www.cubera.ch/app/uploads/cropped-favicon-1-150x150.png" sizes="32x32" />\n' +
            '<link rel="icon" href="https://www.cubera.ch/app/uploads/cropped-favicon-1.png" sizes="192x192" />\n' +
            '<link rel="apple-touch-icon-precomposed" href="https://www.cubera.ch/app/uploads/cropped-favicon-1.png" />' +
            '</head>'
      }
    }
  }
}
</script>