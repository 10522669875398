<template>
  <div class="accessibility-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Accessibility</mt-page-header>
	
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="accessibility">Accessibility</h2>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
		
		  <div class="element-example-wrap">
		
            <h3>Important</h3>
            <ul>
              <li>Where ever possible always use native HTML instead of ARIA-labels.</li>
              <li>Implement a semantically correct structure (H1 followed by H2, P used for text, …) Eg. never use a table for layout purpose.</li>
              <li>Keep in mind that the application is usable by keyboard only and in the desired order</li>
              <li>When images are displayed use alt tags to add more information</li>
              <li>Contrast should always be as good as possible <a href="https://www.tpgi.com/color-contrast-checker/" target="_blank">www.tpgi.com/color-contrast-checker/</a> </li>
              <li>Use ARIA Labels when no native HTML use used <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-label_attribute" target="_blank">mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-label_attribute</a> </li>
            </ul>
			<br>
		  
		    <h3>Usage:</h3>
		  
		    <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.usage_aria_label }}</code></pre>
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.usage_image }}</code></pre>
            </v-sheet>
		  </div>
		  
        </v-col>
      </v-row>
    </v-container>
	
  </div>
</template>


<script>
export default {
  name: "Accessibility",
  data() {
    return {
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Accessibility',
          link: '#accessibility'
        }
      ],
      sourceCode: {
        usage_aria_label: '<button aria-label="Close" onclick="myDialog.close()">X</button>',
        usage_image: '<img alt="Graph for the value 123" src=".../myimage.png" />'
      }
    }
  }
}
</script>

<style scoped>

</style>