<script>
import cell from '../mixins/cell/cell';

export default {
    name: 'VueAdsCell',
    mounted() {

      },
    mixins: [
        cell,
    ],

    render (createElement) {
        let span = createElement('span', {
          class: this.titleClasses,
          on: this.clickEvents,

        }, this.value(createElement))

        return createElement('td', {
            class: this.cellClasses,
            style: this.style,
        }, [
            span
        ]);
    },
};
</script>


