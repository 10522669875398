<template>
  <div class="visual-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Visual Settings</mt-page-header>
  
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="appearance">Appearance</h2>
		  <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row class="grid-demo">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          
		  <mt-select
		    name="appearance"
			item-text="text"
            item-value="value"
            :items="selectAppearance"
			v-model="$vuetify.theme.dark"
            value="active"
			@change="changeAppearance"
		  ></mt-select>
		  
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></v-col>
	    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></v-col>
      </v-row>
	</v-container>  

	<!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="density">Density</h2>
		  <status-badge status="draft" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          
		  <mt-select
		    name="density"
            :items="selectDensity"
            value="active"
			label="Change Density"
		  ></mt-select>
		  
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></v-col>
	    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Visual",
  data() {
    return {
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Appearance',
          link: '#appearance'
        },
		{
          linkType: "position",
          name: 'Density',
          link: '#density'
        }
      ],
	  selectAppearance: [
	    {
	      text: 'Dark mode',
		  value: true
		},
		{
		  text: 'Light mode',
		  value: false
		}
	  ],
	  selectDensity: [
	    'Default',
		'Compact'
	  ]
	}
  },
  methods: {
    changeAppearance(e) {
	  if (e) this.$vuetify.theme.dark
	  else this.$vuetify.theme.light
    }
  }
}
</script>

<style scoped>

</style>