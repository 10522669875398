import ArgumentControls from "./argument-components";
import Fields from "./search-folder-definition.json";
import Mapping from "./operator-class-mapping.json";
import validationUtil from "./validationUtil";
const _ = require("lodash");

export default {

  /**
   * Gets the possible options for the field entry.
   * @return {Array}           Returns ahe possible options for the field entry.
   */
  fieldTypes: () => {
    return Object.keys(Fields.criteria);
  },

  /**
   * Gets the operator types based on the selected field
   * @param  {string} field     The name of the key of the field.
   *
   * @return {Array}           Returns an Array of the available operator types.
   */
  operatorTypes(field) {
    let selectedOperator = this.getOperatorsClass(field);
      let [operatorsArray] = this.selectChildFrom(selectedOperator, Mapping);
      return Object.keys(operatorsArray);
  },

  /**
   * Returns the argumentType for a specific Search folder entry.
   * @param  {string} field     The name of the key of the field.
   * @param  {string} operator  The name of the key of the operator.
   *
   * @return {Object}         Returns the argumentType defined in the contracts.
   */
  getArgumentType(field, operator){
    // get operators from fields
    let operatorClass = this.getOperatorsClass(field);
    // get argumentType from operators and fields
    let [operatorsAvailable] = this.selectChildFrom(operatorClass, Mapping);
    // get argumentType from operators and fields
    let [argumentType] = this.selectChildFrom(operator, operatorsAvailable);
    return argumentType;
  },

  /**
   * Maps the field and operator defined in the DataContract
   * to the UI component that will be displayed in the browser.
   * @param  {string} field         The name of the key of the field.
   * @param  {string} operator      The name of the key of the operator.
   *
   * @return {Object}         Returns the argument to create dynamic swapable component.
   */
  argumentsControl: function (field, operator) {
     let argumentType = this.getArgumentType(field, operator);
      switch (argumentType) {
        case "string":
          return ArgumentControls.stringArg;
        case "stringList":
          return ArgumentControls.stringListArg;
        case "none":
          return ArgumentControls.noneArg;
        case "number":
          return ArgumentControls.stringArg;
        case "numberList":
          return ArgumentControls.stringListArg;
        case "enum":
          return ArgumentControls.enumArg;
        case "enumList":
          return ArgumentControls.enumListArg;
        case "timeSpan":
          return ArgumentControls.timeArg;
        case "dateTime":
          return ArgumentControls.datetimeArg;
        case "pastTimeSelection":
          return ArgumentControls.pastTimeSelection;
        case "quantifiedPastTimeSelection":
          return ArgumentControls.quantifiedPastTimeSelection;
        default:
          return ArgumentControls.stringArg;
      }
  },

  /**
   * Returns all validations for a specific Search folder entry.
   * @param  {string} field     The name of the key of the field.
   * @param  {string} operator  The name of the key of the field.
   *
   * @return {Object}         Returns all validation Methods for a specific Search folder entry.
   */
  validationRules: function (field, operator) {
    let argumentType = this.getArgumentType(field, operator);

    let argumentValidations = this.getArgumentValidation(field);

    if (!argumentValidations)
      return true;

    var [validators] = this.selectChildFrom(argumentType, argumentValidations);

    var validatorRules = Object.entries(validators)?.map(([key, value]) => {
      return this.mapValidators(key, value);
    });

    if(!validatorRules)
      return true;

    return validatorRules;
  },

  /**
   * Maps the Validators defined in the DataContract
   * to the validation method of the UI
   * the underlying datatype e.g string, datetime...
   * @param  {string} validator  The name of the key of the validator.
   * @param  {Object} args       The arguments that will pass to the validation method.
   *
   * @return {Object}         Returns the validation Method.
   */
  mapValidators(validator, args) {
    switch (validator.toString()) {
      case "textRangeValidator":
        return validationUtil.rules.textLengthValidator(args);
      case "cannotBeNullOrUndefinedValidator":
        return validationUtil.rules.cannotBeNullOrUndefinedValidator;
      case "isIntegerValidator":
        return validationUtil.rules.integerValidator;
      case "internalIDValidator":
        return validationUtil.rules.internalIdValidator;
      case "collectionCountValidator":
        return validationUtil.rules.collectionCountValidator;
      default:
        return true;
    }
  },

  /**
   * Gets the operatorClass from the fieldArgument.
   * The operatorClass is a set of operators that depend on
   * the underlying datatype e.g string, datetime...
   * @param  {string} fields  The key of the Child that should be selected.
   *
   * @return {Object}         Returns the operatorClass defined in the search-folder-definition.json
   */
  getOperatorsClass(fields) {
    let selectedFieldEntry = this.selectChildFrom(fields, Fields.criteria);
    let [operatorClassValue] = selectedFieldEntry.map((el) => {
      return el.operatorClass;
    });

    return operatorClassValue;
  },

  /**
   * Gets the Enum of the DataContract
   * Defined in the search-folder-definition.json
   * @param  {string} fields  The key of the Child that should be selected.
   *
   * @return {Object}         Returns the enum defined in the search-folder-definition.json
   */
  getEnum(fields) {
    let selectedFieldEntry = this.selectChildFrom(fields, Fields.criteria);
    let [operatorClassValue] = selectedFieldEntry.map((el) => {
      return el.enums;
    });
    return operatorClassValue;
  },

  /**
   * Gets the ArgumentValidation of the DataContract
   * Defined in the search-folder-definition.json
   * @param  {string} fields  The key of the Child that should be selected.
   *
   * @return {Object}         Returns the argumentValidation defined in the search-folder-definition.json
   */
  getArgumentValidation(fields) {
    let selectedFieldEntry = this.selectChildFrom(fields, Fields.criteria);
    let [argumentValidationValue] = selectedFieldEntry.map((el) => {
      return el.argumentValidation;
    });

    if (!argumentValidationValue)
      return;

    return argumentValidationValue;
  },

  /**
   * Selects a child entry from an jsObject.
   * @param  {string} keyField    The key of the Child that should be selected.
   * @param  {Object} jsonObject  The Js Object structured as a key value pair.
   *
   * @return {Object}      Returns the Child selected from the jsonObject.
   */
  selectChildFrom(keyField, jsonObject) {
    if (!keyField)
      return;

    return _.flow([
      Object.entries,
      (arr) => arr.filter(([key, value]) => key == keyField),
      Object.fromEntries,
      Object.values,
    ])(jsonObject);
  },
};
