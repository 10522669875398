<template>
  <div class="demo-data-table">
    <mt-page-header :scrollNavigationList="scrollNavigationList">Data Table</mt-page-header>
    <v-container>
      <v-row>
        <v-col>
          <v-tabs v-model="currentDemoTab.key" class="demo-tabs mb-3" color="primary" slider-color="primary" background-color="titan_white">
            <v-menu
                v-if="demoTabs.length"
                bottom
                right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    class="align-self-center mr-4"
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ currentDemoTab.title }}
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item
                    v-for="item in demoTabs"
                    :key="item.key"
                    @click="switchTab(item)"
                >
                  {{ item.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tabs>

          <v-tabs-items v-model="currentDemoTab.key">
            <v-tab-item
                :value="'tab-basic'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table basic -->
                  <div class="element-example-wrap" id="table-basic">
                    <h2>Data Table - Basic
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table :headers="headers" :items="desserts"></mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTable }}</code></pre>
                    </v-sheet>

                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-readonly'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table readonly -->
                  <div class="element-example-wrap" id="table-readonly">
                    <h2>Data Table - readonly
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table :headers="headers" :items="desserts" readonly></mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableReadonly }}</code></pre>
                    </v-sheet>
                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-actionbar'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table with Actionbar -->
                  <div class="element-example-wrap" id="table-actionbar">
                    <h2>Data Table - Actionbar
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table
                        :headers="headers"
                        :items="desserts"
                        actionbar="Action Bar Title"
                    >
                      <template slot="actionbarButtons">
                        <v-btn
                            small
                            text
                        >
                          <v-icon>mdi-plus</v-icon>
                          Example Button1
                        </v-btn>
                        <v-btn
                            small
                            icon
                        >
                          <v-icon>mt-action-folder-edit</v-icon>

                        </v-btn>
                      </template>

                      <template slot="actionbarDescription">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                          invidunt voluptua.</p>
                      </template>
                    </mt-data-table>


                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableActionbar }}</code></pre>
                    </v-sheet>


                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-expand'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table expanded rows (default) -->
                  <div class="element-example-wrap" id="table-expand-rows">
                    <h2>Data Table - Expand rows
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table :headers="headersExpand1" :items="desserts" show-expand>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <span>More info about {{ item.data.name.value }}</span>
                        </td>
                      </template>
                    </mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableExpand }}</code></pre>
                    </v-sheet>

                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-select'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table select rows -->
                  <div class="element-example-wrap" id="table-select-rows">
                    <h2>Data Table - Select rows
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table :headers="headers" :items="desserts" show-select></mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableSelect }}</code></pre>
                    </v-sheet>

                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-drag'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table drag columns -->
                  <div class="element-example-wrap" id="table-drag-columns">
                    <h2>Data Table – Drag columns
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table :headers="headers" :items="desserts" drag-columns></mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableDrag }}</code></pre>
                    </v-sheet>

                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-resize'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table resize columns -->
                  <div class="element-example-wrap" id="table-resize-columns">
                    <h2>Data Table – Resize columns
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table resize-columns :headers="headers" :items="desserts"></mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableResize }}</code></pre>
                    </v-sheet>

                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-choose'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table choose columns -->
                  <div class="element-example-wrap" id="table-choose-columns">
                    <h2>Data Table – Choose columns (show/hide)
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table choose-columns :headers="headers" :items="desserts">

                    </mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableChoose }}</code></pre>
                    </v-sheet>

                  </div>


                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-combination'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table Combination  -->
                  <div class="element-example-wrap" id="table-combinations">
                    <h2>Data Table – Choose columns + Expand rows + Select rows
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table :headers="headersExpand2" :items="desserts" show-expand show-select choose-columns>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <span>More info about {{ item.data.name.value }}</span>
                        </td>
                      </template>
                    </mt-data-table>

                    <h3 class="mt-5">Usage:</h3>
                    <!-- Element usage description -->
                    <v-sheet class="pa-6">
                      <!-- Element code snippet -->
                      <pre v-highlightjs><code class="html pa-0">{{ sourceCode.dataTableCombo }}</code></pre>
                    </v-sheet>

                  </div>


                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
                :value="'tab-combination-2'"
            >
              <v-card flat>
                <v-card-text>

                  <!-- Data Table expanded rows / select -->
                  <div class="element-example-wrap" id="table-exp-sel-drag">
                    <h2>Data Table - Expand rows + Select rows + Drag columns
                      <status-badge status="review_design" inline="inline"></status-badge>
                    </h2>
                    <h3>Demo:</h3>
                    <mt-data-table :headers="headersExpand3" :items="desserts" show-expand show-select drag-columns>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <span>More info about {{ item.data.name.value }}</span>
                        </td>
                      </template>
                    </mt-data-table>
                  </div>

                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <h3 id="table-usage">General usage:</h3>
          <!-- Element usage description -->
          <v-sheet class="pa-6">
            <!-- Element code snippet -->
            <h2>Headers / Columns</h2>
            <pre v-highlightjs><code class="javascript pa-0">{{ sourceCode.headers }}</code></pre>

            <h2 class="mt-5">Item / Row</h2>


            <pre v-highlightjs><code class="javascript pa-0">{{ sourceCode.desserts }}</code></pre>

            <props-table :props-data="propsData"></props-table>

          </v-sheet>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import MtDataTable from "../../src/lib-components/MtDataTable";
import PropsTable from "../docs-components/PropsTable";
import MtLoadingOverlay from "../../src/lib-components/MtLoadingOverlay";
import MtExpandableList from "../../src/lib-components/MtExpandableList";

export default {
  name: "DataTablePage",
  components: {MtExpandableList, MtLoadingOverlay, PropsTable, MtDataTable},
  data() {
    return {
      currentDemoTab: {
        title: 'Basic',
        key: 'tab-basic',
      },
      items: [
        'Web', 'Shopping', 'Videos', 'Images',
      ],
      demoTabs: [
        {
          title: 'Basic',
          key: 'tab-basic',
        },
        {
          title: 'Readonly',
          key: 'tab-readonly',
        },
        {
          title: 'Actionbar',
          key: 'tab-actionbar',
        },
        {
          title: 'Expand rows',
          key: 'tab-expand',
        },
        {
          title: 'Select rows',
          key: 'tab-select',
        },
        {
          title: 'Drag columns',
          key: 'tab-drag',
        },
        {
          title: 'Resize columns',
          key: 'tab-resize',
        },
        {
          title: 'Choose columns',
          key: 'tab-choose',
        },
        {
          title: 'Combinations',
          key: 'tab-combination',
        },
        {
          title: 'Combination 2',
          key: 'tab-combination-2',
        },
      ],
      isColumnChooser: 0,
      scrollNavigationList: [
        {
          linkType: "position",
          name: 'Demo + specific usage',
          link: '#table-demos'
        },
        {
          linkType: "position",
          name: 'General usage',
          link: '#table-usage'
        },
      ],
      propsData: [
        {
          name: 'bold',
          type: 'Boolean',
          default: '',
          desc: 'Bold text',
          required: true
        },
        {
          name: 'through',
          type: 'Boolean',
          default: '',
          desc: 'Strikethrough text ',
          required: true
        },
        {
          name: 'status',
          type: 'String',
          default: '',
          desc: 'Used to display icon per cell:<br><code>success</code>, <code>warning</code>, <code>error</code>',
          required: true
        },
        {
          name: 'meta.expanded',
          type: 'String',
          default: '',
          desc: 'Content of expandable row ',
          required: false
        },
        {
          name: 'meta.background',
          type: 'String',
          default: '',
          desc: 'Background color of row: <br><code>white</code>,' +
              '<code style="word-break:">solitude</code>,' +
              '<code>panache</code>,' +
              '<code>blue_romance</code>,' +
              '<code>white_nectar</code>,' +
              '<code>drover</code>,' +
              '<code>white_smoke</code>,' +
              '<code>pale_pink</code>',
          required: true
        },
      ],
      sourceCode: {
        dataTable: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="items"\n' +
            '></mt-data-table>',
        dataTableReadonly: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="items"\n' +
            '     readonly\n' +
            '></mt-data-table>',
        dataTableActionbar: '<mt-data-table \n' +
            '     :headers="headers" \n' +
            '     :items="items" \n' +
            '     actionbar="Action Bar Title"\n' +
            '>\n' +
            '    <template slot="actionbarButtons">\n' +
            '      <v-btn \n' +
            '                 small\n' +
            '                 text\n' +
            '              >\n' +
            '       <v-icon>mdi-plus</v-icon>\n' +
            '         Example Button1\n' +
            '       </v-btn>\n' +
            '      <v-btn \n' +
            '                 small\n' +
            '                 text\n' +
            '              >\n' +
            '       <v-icon>mdi-plus</v-icon>\n' +
            '         Example Button2\n' +
            '       </v-btn>\n' +
            '     </template>\n' +
            '\n' +
            '     <template slot="actionbarDescription">\n' +
            '         <p class="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscinginvidunt voluptua.</p>\n' +
            '     </template>\n' +
            '</mt-data-table>',
        dataTableExpand: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="items"\n' +
            '     show-expand\n' +
            '>\n' +
            '     <template v-slot:expanded-item="{ headers, item }">\n' +
            '           <td :colspan="headers.length">\n' +
            '               <span>More info about {{ item.data.name.value }}</span>\n' +
            '           </td>\n' +
            '     </template>\n' +
            '</mt-data-table>',
        dataTableSelect: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="items"\n' +
            '     show-select\n' +
            '></mt-data-table>',
        dataTableDrag: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="items"\n' +
            '     drag-columns\n' +
            '></mt-data-table>',
        dataTableResize: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="desserts"\n' +
            '     resize-columns\n' +
            '></mt-data-table>',
        dataTableChoose: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="items"\n' +
            '     choose-columns\n' +
            '></mt-data-table>',
        dataTableCombo: '<mt-data-table \n' +
            '     :headers="headers"\n' +
            '     :items="items"\n' +
            '     choose-columns\n' +
            '     show-select\n' +
            '     show-expand\n' +
            '>\n' +
            '     <template v-slot:expanded-item="{ headers, item }">\n' +
            '           <td :colspan="headers.length">\n' +
            '               <span>More info about {{ item.data.name.value }}</span>\n' +
            '           </td>\n' +
            '     </template>\n' +
            '</mt-data-table>',
        headers: '[\n' +
            '   {text: \'Dessert\', value: \'data.name.value\', width: \'20%\'},\n' +
            '   {text: \'Calories\', value: \'data.calories.value\', width: \'20%\'},\n' +
            '   {text: \'Fat (g)\', value: \'data.fat.value\', width: \'20%\'},\n' +
            '   {text: \'Carbs (g)\', value: \'data.carbs.value\', width: \'20%\'},\n' +
            '   {text: \'Protein (g)\', value: \'data.protein.value\', width: \'20%\'}\n' +
            ']',
        desserts: '[\n' +
            '   {\n' +
            '     id: 1,\n' +
            '     data: {\n' +
            '       name: {\n' +
            '         value: \'Frozen Yogurt\',\n' +
            '         bold: false,\n' +
            '         through: false,\n' +
            '         status: null,\n' +
            '       },\n' +
            '       calories: {\n' +
            '         value: 13,\n' +
            '         bold: false,\n' +
            '         through: false,\n' +
            '         status: \'success\'\n' +
            '       },\n' +
            '       fat: {\n' +
            '         value: 6.0,\n' +
            '         bold: false,\n' +
            '         through: false,\n' +
            '         status: \'warning\'\n' +
            '       },\n' +
            '       carbs: {\n' +
            '         value: 24,\n' +
            '         bold: false,\n' +
            '         through: false,\n' +
            '         status: \'error\'\n' +
            '       },\n' +
            '       protein: {\n' +
            '         value: 4.0,\n' +
            '         bold: false,\n' +
            '         through: false,\n' +
            '         status: null\n' +
            '       },\n' +
            '     },\n' +
            '     meta: {\n' +
            '       expanded: \'Content for expanded area\',\n' +
            '       background: \'white\',\n' +
            '     }\n' +
            '   }\n' +
            '],',
      },
      headersExpand1: [
        {text: 'Dessert', value: 'data.name.value', width: '20%'},
        {text: 'Calories', value: 'data.calories.value', width: '20%'},
        {text: 'Fat (g)', value: 'data.fat.value', width: '20%'},
        {text: 'Carbs (g)', value: 'data.carbs.value', width: '20%'},
        {text: 'Protein (g)', value: 'data.protein.value', width: '20%'}
      ],
      headersExpand2: [
        {text: 'Dessert', value: 'data.name.value', width: '20%'},
        {text: 'Calories', value: 'data.calories.value', width: '20%'},
        {text: 'Fat (g)', value: 'data.fat.value', width: '20%'},
        {text: 'Carbs (g)', value: 'data.carbs.value', width: '20%'},
        {text: 'Protein (g)', value: 'data.protein.value', width: '20%'}
      ],
      headersExpand3: [
        {text: 'Dessert', value: 'data.name.value', width: '20%'},
        {text: 'Calories', value: 'data.calories.value', width: '20%'},
        {text: 'Fat (g)', value: 'data.fat.value', width: '20%'},
        {text: 'Carbs (g)', value: 'data.carbs.value', width: '20%'},
        {text: 'Protein (g)', value: 'data.protein.value', width: '20%'}
      ],
      headers: [
        {text: 'Dessert', value: 'data.name.value', width: '20%'},
        {text: 'Calories', value: 'data.calories.value', width: '20%'},
        {text: 'Fat (g)', value: 'data.fat.value', width: '20%'},
        {text: 'Carbs (g)', value: 'data.carbs.value', width: '20%'},
        {text: 'Protein (g)', value: 'data.protein.value', width: '20%'}
      ],
      desserts: [
        {
          id: 1,
          data: {
            name: {
              value: 'Frozen Yogurt',
              bold: true,
              status: null,
            },
            calories: {
              value: 13,
              bold: true,
              status: null,
            },
            fat: {
              value: 6.0,
              bold: false,
              status: null,
            },
            carbs: {
              value: 24,
              bold: false,
              status: null
            },
            protein: {
              value: 4.0,
              bold: false,
              status: null
            },
          },
          meta: {
            expanded: 'Content for expanded area',
            background: 'white',
          }
        },
        {
          id: 2,
          data: {
            name: {
              value: 'Ice cream sandwich',
              bold: false,
              through: true,
              status: null
            },
            calories: {
              value: 159,
              bold: true,
              status: null
            },
            fat: {
              value: 6.0,
              bold: false,
              through: false,
              status: null
            },
            carbs: {
              value: 24,
              bold: false,
              status: null
            },
            protein: {
              value: 4.0,
              bold: false,
              status: null
            },
          },
          meta: {
            background: 'solitude',
          }
        },
        {
          id: 3,
          data: {
            name: {
              value: 'Eclair',
              bold: true,
              status: 'success'
            },
            calories: {
              value: 159,
              bold: true,
              status: 'success'
            },
            fat: {
              value: 6.0,
              bold: false,
              status: 'warning'
            },
            carbs: {
              value: 24,
              bold: false,
              status: 'error'
            },
            protein: {
              value: 4.0,
              bold: false,
              status: 'error'
            },
          },
          meta: {
            background: 'panache',
          }
        },
        {
          id: 4,
          data: {
            name: {
              value: 'Eclair',
              bold: true,
              status: 'success'
            },
            calories: {
              value: 159,
              bold: true,
              status: 'success'
            },
            fat: {
              value: 6.0,
              bold: false,
              status: 'warning'
            },
            carbs: {
              value: 24,
              bold: false,
              status: 'warning'
            },
            protein: {
              value: 4.0,
              bold: false,
              status: 'warning'
            },
          },
          meta: {
            background: 'blue_romance',
          }
        },
        {
          id: 5,
          data: {
            name: {
              value: 'Eclair',
              bold: true,
              status: 'success'
            },
            calories: {
              value: 159,
              bold: true,
              status: 'success'
            },
            fat: {
              value: 6.0,
              bold: false,
              status: 'warning'
            },
            carbs: {
              value: 24,
              bold: false,
              status: 'warning'
            },
            protein: {
              value: 4.0,
              bold: false,
              status: 'warning'
            },
          },
          meta: {
            background: 'white_nectar',
          }
        },
        {
          id: 6,
          data: {
            name: {
              value: 'Eclair',
              bold: true,
              status: 'success'
            },
            calories: {
              value: 159,
              bold: true,
              status: 'success'
            },
            fat: {
              value: 6.0,
              bold: false,
              status: 'warning'
            },
            carbs: {
              value: 24,
              bold: false,
              status: 'warning'
            },
            protein: {
              value: 4.0,
              bold: false,
              status: 'warning'
            },
          },
          meta: {
            background: 'drover',
          }
        },
        {
          id: 7,
          data: {
            name: {
              value: 'Eclair',
              bold: true,
              status: 'success'
            },
            calories: {
              value: 159,
              bold: true,
              status: 'success'
            },
            fat: {
              value: 6.0,
              bold: false,
              status: 'warning'
            },
            carbs: {
              value: 24,
              bold: false,
              status: 'warning'
            },
            protein: {
              value: 4.0,
              bold: false,
              status: 'warning'
            },
          },
          meta: {
            background: 'white_smoke',
          }
        },
        {
          id: 8,
          data: {
            name: {
              value: 'Eclair',
              bold: true,
              status: 'success'
            },
            calories: {
              value: 159,
              bold: true,
              status: 'success'
            },
            fat: {
              value: 6.0,
              bold: false,
              status: 'warning'
            },
            carbs: {
              value: 24,
              bold: false,
              status: 'warning'
            },
            protein: {
              value: 4.0,
              bold: false,
              status: 'warning'
            },
          },
          meta: {
            background: 'pale_pink',
          }
        },

      ],
    }
  },
  computed: {
  },
  mounted() {
    const urlHashValidItem = this.demoTabs.find(element => element.key === location.hash.replace("#", "") );
    if(urlHashValidItem) {
      this.switchTab(urlHashValidItem)
    }
  },
  methods: {
    switchTab (item) {
      this.$nextTick(() => { this.currentDemoTab = item })
    },
  }
}
</script>

<style>
.element-example-wrap .v-data-table {
  margin-bottom: 48px;
}

.v-card__subtitle, .v-card__text {
  font-size: 1rem;
}

.demo-tabs .v-sheet.v-card,
.demo-tabs.v-tabs--vertical > .v-window {
  background-color: transparent;
}

.demo-tabs .v-sheet {
  border-color: transparent;
}

.demo-data-table .v-toolbar__title {
  font-size: 1rem;
}

.demo-data-table .v-tab {
  text-transform: capitalize;
}


</style>