<template>
  <v-tooltip
    :disabled="!this.collapsed"
	right>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item :to="menuItem.link" @click="$emit('click')" v-bind="attrs" v-on="on">
        <v-list-item-action>
          <v-img :src="menuItem.icon" v-if="menuItem.icon" width="24" height="24"/>
          <v-icon style="font-size: 24px" v-if="menuItem.mdIcon">{{ menuItem.mdIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <span>{{ menuItem.name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'MtSideBarItem',
  props: {
    menuItem: Object,
	collapsed: Boolean
  }
}
</script>