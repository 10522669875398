import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Home from './views/Home.vue';
/* Components */
import Buttons from './views/Buttons.vue';
import DataTable from './views/DataTable.vue';
import Dialog from './views/Dialog.vue';
import FormInputs from './views/FormInputs.vue';
import Progress from './views/Progress.vue';
import SearchTable from './views/SearchTable.vue';
import Tiles from './views/Tiles.vue';
import TreeTable from './views/TreeTable2.vue';
/* Layout */
import Colors from './views/Colors.vue';
import Columns from './views/Columns.vue';
import LayoutHelperLeftRightCol from './views/LayoutHelperLeftRightCol.vue';
import PageHeader from './views/PageHeader.vue';
import Typography from './views/Typography.vue';
/* Settings */
import Visual from './views/Visual.vue';
import Locale from './views/Locale.vue';

import Apps from "./views/Apps";
import AppIcons from "./views/AppIcons";
import ExpansionPanels from "./views/ExpansionPanels";
import Icons from "./views/Icons";
import Accessibility from "./views/Accessibility";
import Grids from "./views/Grids";
import Sparklines from "./views/Sparklines";

const routes = [
    { path: '/', component: Home },
    { path: '/buttons', component: Buttons },
    { path: '/data-table', component: DataTable },	
    { path: '/dialog', component: Dialog },	
    { path: '/form-inputs', component: FormInputs },	
    { path: '/progress', component: Progress },
    { path: '/search-table', component: SearchTable },
    { path: '/tiles', component: Tiles },
    { path: '/tree-table', component: TreeTable },
    { path: '/colors', component: Colors },	
    { path: '/columns', component: Columns },
    { path: '/layout-helper-left-right-col', component: LayoutHelperLeftRightCol },
    { path: '/page-header', component: PageHeader },
	{ path: '/typography', component: Typography },
	{ path: '/visual', component: Visual },
	{ path: '/locale', component: Locale },
    { path: '/app-icons', component: AppIcons },
    { path: '/expansion-panel', component: ExpansionPanels },
    { path: '/icons', component: Icons },
    { path: '/grids', component: Grids },
    { path: '/accessibility', component: Accessibility },
    { path: '/sparklines', component: Sparklines },
	{ path: '/apps', component: Apps }
]

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes
})

export default router