export default {
  stringArg: {
    type: "mt-text-field",
    args: {},
  },
  noneArg: {
    type: "mt-text-field",
    args: {
      disabled: true,
    },
  },
  stringListArg: {
    type: "mt-chip-list",
    args: {},
  },
  enumArg: {
    type: "mt-select",
    args: {},
  },
  enumListArg: {
    type: "mt-select",
    args: {
      multiple: true,
    },
  },
  pastTimeSelection: {
    type: "past-time-selection",
    args: {},
  },
  quantifiedPastTimeSelection: {
    type: "quantified-past-time-selection",
    args: {},
  },
  datetimeArg: {
    type: "mt-date-time-picker",
    args: {},
  },
  timeArg: {
    type: "mt-time-picker",
    args: {},
  },
};
