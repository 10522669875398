<template>
  <v-menu v-model="menu" :close-on-content-click="false" max-width="360">
    <template #activator="{ on, textFieldProps }">
      <mt-text-field
        clearable
        v-model="formattedDateTime"
        :label="label"
        :disabled="disabled"
        :rules="[...validators.dateTimeValidator()]"
        v-on="updateProperty(formattedDateTime)"
        v-bind="textFieldProps"
        append-icon="mdi-calendar-clock"
        @click:append="menu = true"
        @click:clear="formattedDateTime = null"
      ></mt-text-field>
    </template>
    <v-card v-model="formattedDateTime" width="360" @change="menu = false">
        <v-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateTab"> Date </slot>
          </v-tab>
          <v-tab key="timer" :disabled="getDateSelected">
            <slot name="timeTab"> Time </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="dateArgs"
              full-width
              @input="showTimePicker"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              v-model="time"
              v-bind="timeArgs"
              full-width
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
    </v-card>
  </v-menu>
</template>

<script lang="js">
import { format, parse } from "date-fns";
import validationUtil from "./validationUtil";

const defaultDateFormat = "yyyy-MM-dd";
const defaultTimeFormat = "HH:mm:ss";

export default {
  name: "MtDateTimePicker",
  model: {
    prop: "datetime",
    event: "input",
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    value: {
        type: String,
        default: ''
      },
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dateFormat: {
      type: String,
      default: defaultDateFormat,
    },
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
    dateArgs: {
      type: Object,
    },
    timeArgs: {
      type: Object,
    },
  },
  data() {
    return {
      activeTab: 0,
      menu: false,
      date: '',
      time: '',
    };
  },
  computed: {
    validators: {
      get: function () {
        return validationUtil.rules;
      },
    },
    formattedDateTime: {
      get: function () {
        return this.getFormattedDatetime;
      },
      set: function (val) {
        this.$emit('input', val)
      },
    },
    getDateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    getDefaultDateTimeFormat() {
      return defaultDateFormat + " " + defaultTimeFormat;
    },
    getFormattedDatetime() {
      return this.getSelectedDatetime
        ? format(this.getSelectedDatetime, this.getDateTimeFormat)
        : "";
    },
    getSelectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }
        return parse(datetimeString, this.getDefaultDateTimeFormat, new Date());
      } else {
        return null;
      }
    },
    getDateSelected() {
      return !this.date;
    },
  },
  methods: {
    updateProperty(val){
      this.$emit('input', val);
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
};
</script>

<style scoped>
.v-picker.v-card {
  border: none;
}

.theme--light.v-sheet {
  border-color: #000;
}

.v-tab {
  text-transform: capitalize;
}
</style>