<template>
  <v-textarea
      :class="[state ? 'v-input--is-state--'+state : '']"
      v-bind="computedProps"
      v-on="$listeners"
  >
    <!-- Dynamically inherit slots from parent -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-textarea>
</template>
<script>
import { VTextarea } from 'vuetify/lib';

export default {
  name: "MtTextArea",
  extends: VTextarea,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    state: null
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
      }
    }
  }
}
</script>