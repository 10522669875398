<template>
  <div>
    <!-- Table Actionbar -->
    <data-table-actionbar v-if="actionbar != ''">
      {{ actionbar }}
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" v-if="slot.startsWith('actionbar')" />
      </template>
    </data-table-actionbar>
    <!-- Table -->
    <table-container
        ref="table"
        :classes="classes"
        :columns="columns"
        :rows="rows"
        :filter="filter"
        :page="page"
        @filter-change="filterChanged"
        @page-change="pageChanged"
    >
      <!-- Dynamically inherit slots from parent -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>

    </table-container>
  </div>
</template>

<script>
import TableContainer from './treetable/components/TableContainer'; // actual copy of vue-ads-tree-table
import DataTableActionbar from "./DataTableActionbar";
export default {
  name: "TreeTable",
  components: {
    TableContainer,
    DataTableActionbar
  },
  data() {
    return {
      page: 0,
      itemsPerPage: 10, // TODO pagination paused (maybe not needed)
      filter: '',
      total: this.rows.length,
      start: null,
      end: null,
      loading: false,
      classes: {
        table: {
          'mt-tree-table': true,
        },
        info: {
          'mt-tree-info': true,
          'vue-ads-text-center': true,
          'vue-ads-py-6': true,
          'vue-ads-text-sm': true,
          'vue-ads-border-t': true,
        },
        group: {
          'vue-ads-font-bold': true,
          'vue-ads-border-b': true,
          'vue-ads-italic': true,
        },
        selected: {
          'vue-ads-bg-teal-100': true,
        },
        'all/': {
          'hover:vue-ads-bg-gray-200': true,
        },
        'all/all': {
          'vue-ads-px-4': true,
          'vue-ads-py-2': true,
          'vue-ads-text-sm': true,
        },
        'even/': {
          'vue-ads-bg-gray-100': true,
        },
        'odd/': {
          'vue-ads-bg-white': true,
        },
        '0/': {
          'vue-ads-bg-gray-100': false,
          'hover:vue-ads-bg-gray-200': false,
        },
        '0/all': {
          'vue-ads-px-4': true,
          'vue-ads-py-2': true,
          'vue-ads-text-left': true,
        },
        '0_-1/': {
          'vue-ads-border-b': true,
        },
        '/0_-1': {
          'vue-ads-border-r': true,
        },
      },
    }
  },
  props: {
    rows: Array,
    columns: Array,
    pagination: {
      type: Boolean,
      default: false,
    },
    actionbar: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.total = this.rows.length
  },
  methods: {
    rangeChanged (start, end) {
      this.start = start
      this.end = end
    },
    filterChanged (filter) {
      this.filter = filter;
    },
    pageChanged (page) {
      this.page = page;
      this.$emit('page-change', page);
    },
  },
}
</script>

<style scoped>
  .mt-tree-table {
    background: #fff;
    border: 1px solid var(--v-cold_purple-base);
    background: var(--v-titan_white-base);
  }

  .tree-table-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: var(--v-titan_white-base);
    border: 1px solid var(--v-cold_purple-base);
    border-top: none;
  }

  /deep/ tr {
    background: var(--v-titan_white-base);
  }

  /deep/ table tbody tr:nth-of-type(odd) {
    background: var(--v-titan_white-base);
  }

  /* override hover */
  /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: var(--v-periwinkel-base);
  }
</style>