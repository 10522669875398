<template>
  <v-container fluid>
    <ul class="grid">
      <slot></slot>
    </ul>
  </v-container>
</template>

<script>
export default {
  name: "MtAppGrid",
}
</script>

<style scoped>
/* Grid layout */
.grid {
  list-style: none;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  width: 70%;
}

.grid li {
  display: inline-block;
  position: relative;
  width: 33%;
}

.grid li:after {
  display: block;
  padding: 150px !important;
}


</style>