<template>
  <div>
    <!--    TODO filter / search (if needed) -->
    <!--    <slot name="top"
              :filter="filter"
              :filter-changed="filterChanged"
        >
          <div class="vue-ads-flex vue-ads-py-3">
            <div class="vue-ads-w-3/4"></div>
            <div class="vue-ads-w-1/4 vue-ads-flex">
              <vue-ads-form
                  :class="filterClasses"
                  :style="{'min-width': 0}"
              >
                <vue-ads-form-group>
                  <vue-ads-text :value="filter" placeholder="Filter..." @input="filterChanged" :style="{'min-width': 0}"/>
                </vue-ads-form-group>
              </vue-ads-form>
            </div>
          </div>

        </slot>-->
    <vue-ads-table
        ref="table"
        :columns="columns"
        :rows="rows"
        :filter="debouncedFilter"
        :selectable="selectable"
        :start="start"
        :end="end"
        :classes="classes"
        :call-rows="callRowsFunction"
        :call-children="callChildrenFunction"
        :call-temp-rows="callTempRowsFunction"
        :slots="$scopedSlots"
        @total-filtered-rows-change="totalFilteredRowsChanged"
        @selection-change="selectionChanged"
    />
    <slot name="bottom"
          :total="total"
          :page="page"
          :itemsPerPage="itemsPerPage"
          :pageChanged="pageChanged"
          :rangeChanged="rangeChanged"
          v-if="pagination"
    >

      <vue-ads-pagination
          class="mt-table-tree-pagination"
          :total-items="total"
          :page="page"
          :items-per-page="itemsPerPage"
          @page-change="pageChanged"
          @range-change="rangeChanged"
      >

        <template slot-scope="props">

          <div class="mr-auto d-flex align-center">
            <span>Rows per page</span>
            <v-select
                class="mt-rows-per-page mt-0"
                background-color="titan_white"
                dense
                solo
                flat
                hide-details
                :value="itemsPerPage"
                :items="rowsPerPage"
                @change="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </div>

          <div class="vue-ads-leading-loose">
            Page {{ props.start }} of {{ props.end }} ({{ props.total }} items)
          </div>
        </template>

      </vue-ads-pagination>
    </slot>
  </div>
</template>

<script>
/*import {
  VueAdsForm,
  VueAdsFormGroup,
  VueAdsText,
} from 'vue-ads-form-builder';*/
import VueAdsPagination from './Pagination';
import debounce from '../services/debounce';
import VueAdsTable from './Table';
import defaultClasses from '../services/defaultClasses';

export default {
  name: 'VueAdsTableContainer',
  components: {
    VueAdsTable,
//   VueAdsText,
//   VueAdsForm,
//   VueAdsFormGroup,
    VueAdsPagination,
  },
  props: {
    pagination: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      default: '',
    },
    selectable: {
      type: Boolean,
      default: false,
    },

    classes: {
      type: Object,
      default: () => defaultClasses,
    },
    callRows: {
      type: Function,
    },
    callTempRows: {
      type: Function,
    },
    callChildren: {
      type: Function,
    },
    debounceFilterTime: {
      type: Number,
      default: 500,
    },
    page: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rowsPerPage: [5, 10, 15],
      itemsPerPage: 10,
      total: this.rows.length,
      start: null,
      end: null,
      debouncedFilter: this.filter,
      debounce: debounce(
          this.filterChange,
          this.debounceFilterTime
      ),
    };
  },
  watch: {
    rows: 'rowsChanged',
    filter: {
      handler: 'filterChanged',
      immediate: true,
    },
  },
  computed: {
    filterClasses() {
      return {
        'vue-ads-flex-grow': true,
      };
    },
    callRowsFunction() {
      return this.callRows || (() => []);
    },
    callTempRowsFunction() {
      return this.callTempRows || (() => []);
    },
    callChildrenFunction() {
      return this.callChildren || (() => []);
    },
  },
  methods: {
    rowsChanged() {
      this.total = this.rows.length;
    },
    filterChanged(filter) {
      if (this.callRows) {
        this.debounce(filter);

        return;
      }
      this.filterChange(filter);
    },
    async filterChange(filter) {
      this.debouncedFilter = filter;
      this.$emit('filter-change', filter);
      this.$emit('page-change', 0);
    },
    selectionChanged(rows) {
      this.$emit('selection-change', rows);
    },
    pageChanged(page) {
      this.$emit('page-change', page);
    },
    rangeChanged(start, end) {
      this.start = start;
      this.end = end;
    },
    totalFilteredRowsChanged(total) {
      this.total = total;
    },
  },
};
</script>

<style scoped>
/deep/ .mt-rows-per-page > .v-input__control {
  min-height: 32px !important;
  max-width: 80px;
}

</style>

<style scoped>
/deep/ th .v-data-table-header__icon {
  opacity: 1;
}
</style>