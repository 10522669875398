<template>
  <div class="mt-header">
    <v-system-bar app height="64" ref="headerSystemBar">
      <div style="width: 100%; height: 64px;">
        <div class="d-flex justify-space-between" style="width: 100% ">
          <v-container class="pa-0" fluid>
            <v-row class="justify-space-between" no-gutters>
              <v-col cols="auto" sm="4">
                <img class="labXLogoImg" :src="require('./icons/labx.png')" width="36" height="36"/>
                <div class="labXLogoTxt d-none d-sm-inline-block">
                  <v-icon color="solitude">mt-labx-text</v-icon>
                </div>
              </v-col>
              <v-col cols="auto" sm="4" class="text-center">
                <img :src="require('./icons/mettlertoledo.png')" width="176" height="48" style="margin-top: 8px"/>
              </v-col>
              <v-col cols="auto" sm="4" class="text-right">

                <div class="system-bar-cta-wrap">

                  <div class="cta-element">
                    <v-badge
                        class="notification-badge"
                        :content="99"
                        color="alizarin"
                        overlap
                    >
                      <v-icon color="white">
                        mdi-alert-circle
                      </v-icon>
                    </v-badge>
                  </div>
                  <div class="cta-element">
                    <a href="#">
                      <v-icon color="white">
                        mt-navigation-user
                      </v-icon>
                      <span class="d-none d-md-inline-block">Predefined User</span>
                    </a>
                  </div>

                  <div class="cta-element">
                    <v-icon color="white">
                      mt-navigation-help
                    </v-icon>
                  </div>

                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-system-bar>
    <v-app-bar top clipped-left app ref="headerAppBar" :height="isMobile ? '144' : '72'">

      <div class="header-fix-left">
        <slot name="headerLeft"></slot>
      </div>

      <v-tabs
          icons-and-text
          show-arrows
          class="rounded-0"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
      >
          <v-tabs-slider></v-tabs-slider>
          <slot name="headerMiddle"></slot>

      </v-tabs>

      <div class="header-fix-right">
        <slot name="headerRight"></slot>
      </div>

    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "MtHeader",
  provide() {
    return {
      MtHeader: this.MtHeader
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      appBarHeight: 0,
      breakpointHeader: 768,
      height: 0
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth <= this.breakpointHeader
    }
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize(e) {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>

.notification-badge i {
  font-size: 24px
}

.v-tabs {
  margin-top: 0;
  margin-bottom: 0;
}

.labXLogoImg {
  float: left;
  height: 36px;
  margin-left: 8px;
  margin-top: 14px;
}

.labXLogoTxt {
  float: left;
  height: 48px;
  line-height: 64px;
  color: #ffffff;
  font-size: 20px;
  padding-left: 6px;
}

.labXLogoTxt i {
  font-size: 20px;
}

.system-bar-cta-wrap {
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .cta-element {
    margin: 0 15px;
    color: #fff;
    font-size: 1rem;

    @media only screen and (max-width: 768px) {
      margin: 0 7px;
    }
  }

  a {
    color: #fff;
  }
}

.system-bar-cta-wrap img {
  margin: 12px 8px 0px 8px;
  height: 16px;
  width: 16px;
  top: 2px;
  position: relative;
}

</style>
