<template>
  <i class="mt-icon-tricolor">
    <svg id="Icon_Field_Signature_Step_0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-field-signature-step-0">
      <g id="Icon_Field_Signature_Step_0" transform="translate(1 1)">
        <rect id="Icon_Bounding_Box_32x32" width="32" height="32" fill="none" stroke="#7e88b1" stroke-width="2" opacity="0"/>
        <g id="Pfad_1197" data-name="Pfad 1197" transform="translate(1 1)" fill="none">
          <path d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" stroke="none"/>
          <path d="M 15 2 C 11.5275707244873 2 8.262990951538086 3.352239608764648 5.807609558105469 5.807609558105469 C 3.352239608764648 8.262990951538086 2 11.5275707244873 2 15 C 2 18.47243118286133 3.352239608764648 21.73701095581055 5.807609558105469 24.19239044189453 C 8.262990951538086 26.64776039123535 11.5275707244873 28 15 28 C 18.47243118286133 28 21.73701095581055 26.64776039123535 24.19239044189453 24.19239044189453 C 26.64776039123535 21.73701095581055 28 18.47243118286133 28 15 C 28 11.5275707244873 26.64776039123535 8.262990951538086 24.19239044189453 5.807609558105469 C 21.73701095581055 3.352239608764648 18.47243118286133 2 15 2 M 15 0 C 23.28426933288574 0 30 6.715730667114258 30 15 C 30 23.28426933288574 23.28426933288574 30 15 30 C 6.715730667114258 30 0 23.28426933288574 0 15 C 0 6.715730667114258 6.715730667114258 0 15 0 Z" stroke="none" fill="#7e88b1"/>
        </g>
        <g id="Rechteck_6880" data-name="Rechteck 6880" transform="translate(6 15)" fill="none" stroke="#7e88b1" stroke-width="2">
          <rect width="20" height="2"/>
        </g>
      </g>
    </svg>
  </i>
</template>

<script>
export default {
  name: "FieldSignatureStep0"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
</style>

