<template>
  <div class="locale-page">
  
    <mt-page-header>Locale</mt-page-header>
	
	<!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="appearance">Locale</h2>
		  <status-badge status="draft" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
  
  </div>
</template>

<script>

export default {
  name: 'LocalePage',
  components: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>

</style>


