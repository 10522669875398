<template>
  <div class="icons-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Icons</mt-page-header>
	
	<v-container>
      <v-row>
        <v-col>
	      <mt-text-field
		    v-model="searchTerm"
            label="Search icons"
	        clearable
			prepend-icon="mdi-magnify"
           ></mt-text-field>
		</v-col>
      </v-row>
    </v-container>
    
    <v-container>
      <v-row>
        <v-col>		
          <h2 class="pa-0" id="navigation">Main App Left Navigation</h2>
          <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap">
		  
			<v-row>
              <v-col v-show="isVisible('navigation-analysis-and-data')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-analysis-and-data</v-icon></div>
				<span>Navigation_Analysis_and_Data</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-analysis-and_data') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-resources')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-resources</v-icon></div>
                <span>Navigation_Resources</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-resources') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-system')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-system</v-icon></div>
                <span>Navigation_System</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-system') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('navigation-user')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-user</v-icon></div>
                <span>Navigation_User</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-user') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('navigation-help')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-help</v-icon></div>
                <span>Navigation_Help</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-help') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
			
		  </div>

        </v-col>
      </v-row>
    </v-container>	

    <v-container>
      <v-row>
        <v-col>		
          <h2>App Left Navigation Titles</h2>
          <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap">
		  
		    <v-row>
              <v-col v-show="isVisible('navigation-task-creation')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-task-creation</v-icon></div>
				<span>Navigation_Task_Creation</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-task-creation') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-resources-monitoring')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-resources-monitoring</v-icon></div>
                <span>Navigation_Resources_Monitoring</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-resources-monitoring') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-data-integrity')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-data-integrity</v-icon></div>
                <span>Navigation_Data_Integrity</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-data-integrity') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('navigation-method-editing')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-method-editing</v-icon></div>
				<span>Navigation_Method_Editing</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-method-editing') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-resources-history')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-resources-history</v-icon></div>
                <span>Navigation_Resources_History</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-resources-history') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-user-management')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-user-management</v-icon></div>
                <span>Navigation_User_Management</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-user-management') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('navigation-results-processing')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-results-processing</v-icon></div>
				<span>Navigation_Results_Processing</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-results-processing') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-settings-history')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-settings-history</v-icon></div>
                <span>Navigation_Settings_History</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-settings-history') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-statistical-evaluation')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-statistical-evaluation</v-icon></div>
                <span>Navigation_Statistical_Evaluation</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-statistical-evaluation') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('navigation-enterprise-structure')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-enterprise-structure</v-icon></div>
				<span>Navigation_Enterprise_Structure</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-enterprise-structure') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-weights-management')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-weights-management</v-icon></div>
                <span>Navigation_Weights_Management</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-weights-management') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('navigation-system-integration')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-navigation-system-integration</v-icon></div>
                <span>Navigation_System_Integration</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-navigation-system-integration') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
			
		  </div>

        </v-col>
      </v-row>
    </v-container>	

    <v-container>
      <v-row>
        <v-col>		
          <h2>Local Navigation Items</h2>
          <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap">
		  
		    <v-row>
              <v-col v-show="isVisible('action-local-arrow')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-arrow</v-icon></div>
				<span>Action_Local_Arrow_Down</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-arrow') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-local-crosslink')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-crosslink</v-icon></div>
                <span>Action_Local_Crosslink</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-crosslink') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="false" cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></v-col>
            </v-row>
			
		  </div>

        </v-col>
      </v-row>
    </v-container>	

    <v-container>
      <v-row>
        <v-col>		
          <h2 id="tilemenu">Tile Menu</h2>
          <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap">
		  
		    <v-row>
              <v-col v-show="isVisible('app-task-creation')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-task-creation</v-icon></div>
				<span>App_Task_Creation</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-task-creation') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-resources-monitoring')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-resources-monitoring</v-icon></div>
                <span>App_Resources_Monitoring</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-resources-monitoring') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-data-integrity')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-data-integrity</v-icon></div>
                <span>App_Data_Integrity</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-data-integrity') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('app-workflow-management')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-workflow-management</v-icon></div>
				<span>App_Workflow_Management</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-workflow-management') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-resources-history')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-resources-history</v-icon></div>
                <span>App_Resources_History</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-resources-history') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-user-management')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-user-management</v-icon></div>
                <span>App_User_Management</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-user-management') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('app-results-processing')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-results-processing</v-icon></div>
				<span>App_Results_Processing</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-results-processing') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-settings-history')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-settings-history</v-icon></div>
                <span>App_Settings_History</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-settings-history') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-enterprise-structure')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-enterprise-structure</v-icon></div>
                <span>App_Enterprise_Structure</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-enterprise-structure') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('app-statistical-evaluation')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-statistical-evaluation</v-icon></div>
				<span>App_Statistical_Evaluation</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-statistical-evaluation') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-weights-management')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-weights-management</v-icon></div>
                <span>App_Weights_Management</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-weights-management') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('app-system-integration')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-system-integration</v-icon></div>
                <span>App_System_Integration</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-system-integration') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('app-performance-analysis')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-app-performance-analysis</v-icon></div>
				<span>App_Performance_Analysis</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-app-performance-analysis') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
			
		  </div>

        </v-col>
      </v-row>
    </v-container>	

    <v-container>
      <v-row>
        <v-col>		
          <h2 id="appbar">App Bar</h2>
          <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap">
		  
		    <v-row>
              <v-col v-show="isVisible('action-menu-applications')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-menu-applications</v-icon></div>
				<span>Action_Menu_Applications</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-menu-applications') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-navigation-menu')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-navigation-menu</v-icon></div>
                <span>Action_Navigation_Menu</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-navigation-menu') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-object-new')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-object-new</v-icon></div>
                <span>Action_Object_New</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-object-new') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-object-edit')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-object-edit</v-icon></div>
				<span>Action_Object_Edit</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-object-edit') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-folder-add')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-folder-add</v-icon></div>
                <span>Action_Folder_Add</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-folder-add') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-folder-edit')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-folder-edit</v-icon></div>
                <span>Action_Folder_Edit</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-folder-edit') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-folder-delete')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-folder-delete</v-icon></div>
				<span>Action_Folder_Delete</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-folder-delete') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-folder-favor')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-folder-favor</v-icon></div>
                <span>Action_Folder_Favor</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-folder-favor') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-previous')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-previous</v-icon></div>
                <span>Action_Previous</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-previous') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-next')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-next</v-icon></div>
				<span>Action_Next</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-next') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-object-delete')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-object-delete</v-icon></div>
                <span>Action_Object_Delete</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-object-delete') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-settings')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-settings</v-icon></div>
                <span>Action_Settings</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-settings') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-edit-columns')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-edit-columns</v-icon></div>
				<span>Action_Edit_Columns</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-edit-columns') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-sign')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-sign</v-icon></div>
                <span>Action_Sign</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-sign') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-revoke-signature')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-revoke-signature</v-icon></div>
                <span>Action_Revoke_Signature</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-revoke-signature') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-object-report')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-object-report</v-icon></div>
				<span>Action_Object_Report</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-object-report') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-import')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-import</v-icon></div>
                <span>Action_Import</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-import') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-export')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-export</v-icon></div>
                <span>Action_Export</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-export') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-send-to-external-app')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-send-to-external-app</v-icon></div>
				<span>Action_Send_To_External_App</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-send-to-external-app') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-task-create')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtActionTaskCreate</v-icon></div>
                <span>Action_Task_Create</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtActionTaskCreate') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-backup')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-backup</v-icon></div>
                <span>Action_Backup</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-backup') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-restore')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-restore</v-icon></div>
				<span>Action_Restore</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-restore') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-primary-object-save')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-primary-object-save</v-icon></div>
                <span>Action_Primary_Object_Save</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-primary-object-save') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-primary-reload-list')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-primary-reload-list</v-icon></div>
                <span>Action_Primary_Reload_List</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-primary-reload-list') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-primary-download-task-template')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-primary-download-task-template</v-icon></div>
				<span>Action_Primary_Download_Task_Template</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-primary-download-task-template') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
            
          </div>

        </v-col>
      </v-row>
    </v-container>	

    <v-container>
      <v-row>
        <v-col>		
          <h2>Mini App Bar</h2>
          <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap">

			<v-row>
              <v-col v-show="isVisible('action-local-new')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-new</v-icon></div>
				<span>Action_Local_New</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-new') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-local-edit')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-edit</v-icon></div>
                <span>Action_Local_Edit</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-edit') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-local-delete')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-delete</v-icon></div>
                <span>Action_Local_Delete</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-delete') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('action-local-generic')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-generic</v-icon></div>
				<span>Action_Local_Generic</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-generic') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-local-collapse-all')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-collapse-all</v-icon></div>
                <span>Action_Local_Collapse_All</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-collapse-all') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('action-local-expand-all')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>mt-action-local-expand-all</v-icon></div>
                <span>Action_Local_Expand_All</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('mt-action-local-expand-all') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
            
          </div>

        </v-col>
      </v-row>
    </v-container>
    
    <v-container>
      <v-row>
        <v-col>		
          <h2 id="statusdialogs">Status Dialogs</h2>
          <status-badge status="progress" inline="inline"></status-badge>          
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap" >

			<v-row>
              <v-col v-show="isVisible('dialog-finished')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtDialogFinished</v-icon></div>
				<span>Dialog_Finished</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtDialogFinished') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('dialog-information')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon color="info">$mtDialogInformation</v-icon></div>
                <span>Dialog_Information</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtDialogInformation') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('dialog-warning')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtDialogWarning</v-icon></div>
                <span>Dialog_Warning</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtDialogWarning') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('dialog-error')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtDialogError</v-icon></div>
				<span>Dialog_Error</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtDialogError') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
            
          </div>

        </v-col>
      </v-row>
    </v-container>
    
    <v-container>
      <v-row>
        <v-col>		
          <h2 id="listfields">Lists / Fields</h2>
          <status-badge status="progress" inline="inline"></status-badge>          
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap" >

			<v-row>
              <v-col v-show="isVisible('field-success')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldSuccess</v-icon></div>
				<span>Field_Success</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldSuccess') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('field-error')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon color="info">$mtFieldError</v-icon></div>
                <span>Field_Error</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldError') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('field-warning')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldWarning</v-icon></div>
                <span>Field_Warning</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldWarning') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('field-favorite')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldFavorite</v-icon></div>
				<span>Field_Favorite</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldFavorite') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('field-signature-step-0')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldSignatureStep0</v-icon></div>
                <span>Field_Signature_Step_0</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldSignatureStep0') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('field-signature-step-1')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldSignatureStep1</v-icon></div>
                <span>Field_Signature_Step_1</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldSignatureStep1') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('field-signature-step-2')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldSignatureStep2</v-icon></div>
				<span>Field_Signature_Step_2</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldSignatureStep2') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('field-signature-step-3')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldSignatureStep3</v-icon></div>
                <span>Field_Signature_Step_3</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldSignatureStep3') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('field-signature-revoked')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldSignatureRevoked</v-icon></div>
                <span>Field_Signature_Revoked</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldSignatureRevoked') }}</code></pre>
				</v-sheet>
              </v-col>
			  <v-col v-show="isVisible('field-archiving-archived')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldArchivingArchived</v-icon></div>
				<span>Field_Archiving_Archived</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldArchivingArchived') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('field-archiving-undone')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtFieldArchivingUndone</v-icon></div>
                <span>Field_Archiving_Undone</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtFieldArchivingUndone') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
            
          </div>

        </v-col>
      </v-row>
    </v-container>
	
    <v-container>
      <v-row>
        <v-col>		
          <h2 id="overlays">Overlays</h2>
          <status-badge status="progress" inline="inline"></status-badge>          
        </v-col>
      </v-row>
    </v-container>
  
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap" >

			<v-row>
              <v-col v-show="isVisible('overlay-busy')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtOverlayBusy</v-icon></div>
				<span>Overlay_Busy</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtOverlayBusy') }}</code></pre>
				</v-sheet>
              </v-col>
              <v-col v-show="isVisible('overlay-finished')" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"><v-icon>$mtOverlayFinished</v-icon></div>
                <span>Overlay_Finished</span>
				<v-sheet class="pa-6">
				  <pre v-highlightjs><code class="html pa-0">{{ getSourceCode('\$mtOverlayFinished') }}</code></pre>
				</v-sheet>
              </v-col>
            </v-row>
            
          </div>

        </v-col>
      </v-row>
    </v-container>
    
  </div>
</template>

<script>
export default {
  name: "Icons",
  data() {
    return {
	  scrollNavigationList: [
	    {
		  linkType: 'position',
		  name: 'Navigation',
		  link: '#navigation'
		},
        {
		  linkType: 'position',
		  name: 'Tile Menu',
		  link: '#tilemenu'
		},
        {
		  linkType: 'position',
		  name: 'App Bar',
		  link: '#appbar'
		},
        {
		  linkType: 'position',
		  name: 'Status Dialogs',
		  link: '#statusdialogs'
		},
        {
		  linkType: 'position',
		  name: 'Lists / Fields',
		  link: '#listfields'
		},
        {
		  linkType: 'position',
		  name: 'Overlays',
		  link: '#overlays'
		}
	  ],
	  searchTerm: ''
    }
  },
  methods: {
    isVisible (stringVal) {
	  this.searchTerm = this.searchTerm ? this.searchTerm : ''
      return stringVal.indexOf(this.searchTerm) !== -1
    },
	getSourceCode (prop) {
	  return '<v-icon>\n' +
            '     ' + prop + '\n' +
            '</v-icon>'
	}
  }
}
</script>

<style scoped>
.col-filler .v-icon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: var(--v-midnight_blue-base);
}

.element-example-wrap .v-sheet {
  margin-top: 8px;
}
</style>