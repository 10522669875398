export default {
  rules: {
    minValidator(min) {
      return (v) => v >= min || "Number must be higher than " + min;
    },
    maxValidator(max) {
      return (v) => v <= max || "Number must be lower than " + max;
    },
    textLengthValidator(maxNum) {
      return (v) =>
        (v || "").length <= maxNum || `Please use maximum ${maxNum} characters`;
    },
    cannotBeNullOrUndefinedValidator: (value) =>
      !!value || "This field is required.",
    integerValidator: (v) =>
      Number.isInteger(Number(v)) || "The value must be an integer number",
    floatValidator: (num) =>
      (!isNaN(num) && num % 1 !== 0) || "The value must be a float number",
    numericRangeValidator(min, max) {
      return (
        (v) => v <= max || "Number must be lower than " + max,
        (v) => v >= min || "Number must be higher than " + min
      );
    },
    uuidValidator: (value) =>
      //eslint-disable-next-line
      /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/.test(
        value
      ) || "This field is not a valid UUID.",
    internalIdValidator: (value) =>
      isInternalId(value) || "This is not a valid format for an internal ID.",
    decimalPlacesValidator(maxDecimalPlaces) {
      return (value) =>
        countDecimals(value) <= maxDecimalPlaces ||
        `Maximum ${maxDecimalPlaces} decimal places.`;
    },
    collectionCountValidator(itemsLength, maxNrItems) {
      return (v) =>
        itemsLength <= maxNrItems || `Please use maximum ${maxNrItems} items`;
    },
    timespanValidator: (value) =>
        /^(0{1}|[1-9]{1}[0-9]{0,2})d-(2[0-3]|[01][0-9])h:([0-5][0-9])m$/.test(value) ||
      `This field is not a valid timespan.`,
    dateTimeValidator: (value) =>
        /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d (2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/.test(
        value
      ) || `This field is not a valid datetime.`,
  },
};
function isInternalId(value) {
  if (value.length > 20) {
    return false;
  }
  var firstDigitIdx = value.search(/\d/);
  if (firstDigitIdx == 0 || firstDigitIdx > 3) {
    return false;
  }
  for (let i = firstDigitIdx; i < value.length; i++) {
    if (value[i] < "0" || value[i] > "9") {
      return false;
    }
  }
  return true;
}
function countDecimals(value) {
  if (
    value === undefined ||
    Math.floor(value) === value ||
    Number.isInteger(Number(value))
  )
    return 0;
  return value.toString().split(".")[1].length || 0;
}
