<template>
  <div class="home-page">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>Home</h1>
          <p>Styleguide for LabX</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>