<template>
  <div class="tiles-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Tiles</mt-page-header>
  
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="tiles">Tiles</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
		
		  <!-- Tiles -->
          <div class="element-example-wrap">

            <h3>Demo:</h3>
		
            <MtAppGrid>
              <MtAppTile title="Task Creation" :mdIcon="'mt-action-menu-applications'"/>
              <MtAppTile title="Method Editing" :mdIcon="'mt-action-menu-applications'"/>
              <MtAppTile title="Workflow Management" :mdIcon="'mt-action-menu-applications'"/>
              <MtAppTile title="Results Processing" :mdIcon="'mt-action-menu-applications'"/>
              <MtAppTile title="Statistical Evaluation" :mdIcon="'mt-action-menu-applications'"/>
              <MtAppTile title="Performance Analysis" :mdIcon="'mt-action-menu-applications'"/>
            </MtAppGrid>

			<h3>Usage:</h3>
			
			<v-sheet class="pa-6">
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.usage }}</code></pre>
              <props-table :propsData="propsData"></props-table>
            </v-sheet>
			
		  </div>
		  
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MtAppTile from "../../src/lib-components/MtAppTile";
import AppGrid from "../../src/lib-components/MtAppGrid";
import PropsTable from "../docs-components/PropsTable";

export default {
  name: 'TilesPage',
  components: {
    AppGrid,
    MtAppTile,
    PropsTable
  },
  data() {
    return {
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Tiles',
          link: '#tiles'
        }
      ],
      propsData: [
        {
          name: 'title',
          type: 'String',
          default: '',
          desc: ''
        },
        {
          name: 'icon',
          type: '',
          default: '',
          desc: ''
        },
      ],
      sourceCode: {
        usage: '<MtAppGrid>\n' +
            '    <MtAppTile title="Task Creation" :mdIcon="\'mt-action-menu-applications\'/>\n' +
            '    ...\n' +
            '</MtAppGrid>'
      }
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>
.element-example-wrap .container {
  padding-top: 0;
  padding-left: 0;
}

.element-example-wrap .grid {
  margin: 0;
  padding: 0;
}
</style>


