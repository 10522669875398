<template>
  <div class="mt-progress-circular">
    <v-progress-circular
        :size="96"
        :width="15"
        indeterminate
    ></v-progress-circular>
    <div class="progress-text">Please wait...</div>
  </div>

</template>

<script>
export default {
  name: "MtProgressCircular"
}
</script>

<style scoped>
.mt-progress-circular {
  max-width: 96px;
  display: flex;
  flex-direction: column;
}

.progress-text {
  color: var(--v-midnight_blue-base);
  text-align: center;
  white-space: nowrap;
}
</style>
