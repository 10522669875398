<template>
  <v-app app>
    <MtHeader>
      <template v-slot:headerLeft>
        <MtHeaderItem title="LabX Apps" :mdIcon="'mt-action-menu-applications'" :to="'/apps'"/>
        <MtHeaderItem title="Menu" :mdIcon="'mt-action-navigation-menu'" @click="collapseSidebar"/>
      </template>
      <template v-slot:headerMiddle>
        <MtHeaderItem title="Layout" :mdIcon="'mdi-view-grid-outline'" @click="setActiveSidebar('layout', 'colors')"/>
        <MtHeaderItem title="Components" :mdIcon="'mdi-widgets-outline'"
                      @click="setActiveSidebar('components', 'buttons')"/>
        <MtHeaderItem title="Settings" :mdIcon="'mt-navigation-system'"
                      @click="setActiveSidebar('settings', 'visual')"/>
		<MtHeaderItem disabled title="Disabled" :mdIcon="'mt-navigation-system'"/>
      </template>
      <template v-slot:headerRight>
	    <MtHeaderItem disabled title="Disabled" :mdIcon="'mt-action-primary-object-save'"/>
        <MtHeaderItem title="Primary" :mdIcon="'mt-action-primary-object-save'"/>
      </template>
    </MtHeader>

    <MtSideBar :sidebarMenues="sidebarMenues" :activeMenue="activeMenue" :collapsed="collapsedDrawer"></MtSideBar>

    <v-main :class="{'disable-x-overflow': !collapsedDrawer }" ref="mainBox">
      <router-view></router-view>

    </v-main>

  </v-app>
</template>

<script>
export default {
  name: 'ServeDev',
  components: {},
  methods: {
    collapseSidebar() {
      this.collapsedDrawer = !this.collapsedDrawer
    },
    setActiveSidebar(activeSidebar, activeroute) {
      this.activeMenue = activeSidebar
	  if (this.$router.currentRoute.path !== '/' + activeroute) {
        this.$router.push(activeroute)
      }
    }
  },
  mounted() {
  },
  data() {
    return {
      collapsedDrawer: false,
      activeMenue: 'layout',
      sidebarMenues: [
        {
          id: 'components',
          name: 'Components',
          mdIcon: 'mdi-widgets-outline',
          menuItems: [
            {
              name: 'Buttons',
              mdIcon: 'mdi-cursor-default-click',
              link: '/buttons'
            },
            {
              name: 'Dialog',
              mdIcon: 'mdi-application-outline',
              link: '/dialog'
            },
            {
              name: 'Form',
              mdIcon: 'mdi-form-select',
              link: '/form-inputs'
            },
            {
              name: 'Progress & Loaders',
              mdIcon: 'mdi-loading',
              link: '/progress'
            },
            {
              name: 'Tiles',
              mdIcon: 'mdi-view-grid-plus',
              link: '/tiles'
            },
            {
              name: 'Data Table',
              mdIcon: 'mdi-table',
              link: '/data-table'
            },
            {
              name: 'Search Table',
              mdIcon: 'mdi-table-search',
              link: '/search-table'
            },
            {
              name: 'Tree Table',
              mdIcon: 'mdi-table-large',
              link: '/tree-table'
            }
          ]
        },
        {
          id: 'layout',
          name: 'Layout',
          mdIcon: 'mdi-view-grid-outline',
          menuItems: [
            {
              name: 'Colors',
              mdIcon: 'mdi-palette',
              link: '/colors'
            },
            {
              name: 'Columns',
              mdIcon: 'mdi-view-column',
              link: '/columns'
            },
            {
              name: 'Helper',
              mdIcon: 'mdi-tools',
              link: '/layout-helper-left-right-col'
            },
			{
              name: 'Icons',
              mdIcon: 'mdi-checkbox-blank-circle-outline',
              link: '/icons'
            },
            {
              name: 'Page Header',
              mdIcon: 'mdi-page-layout-header',
              link: '/page-header'
            },
            {
              name: 'Typography',
              mdIcon: 'mdi-format-header-1',
              link: '/typography'
            }
          ]
        },
        {
          id: 'settings',
          name: 'Settings',
          mdIcon: 'mt-navigation-system',
          menuItems: [
            {
              name: 'Visual',
              mdIcon: 'mdi-brightness-6 ',
              link: '/visual'
            },
            {
              name: 'Locale',
              mdIcon: 'mdi-translate',
              link: '/locale'
            }
          ]
        }
      ],
    }
  }
};
</script>
