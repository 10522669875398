<template>
  <div class="dialog-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Dialog</mt-page-header>
  
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="dialog">Dialog</h2>
		  <status-badge status="progress" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
		
		  <!-- Dialog -->
          <div class="element-example-wrap">

            <h3>Demo:</h3>

            <v-dialog
              v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="midnight_blue"
                  v-bind="attrs"
                  v-on="on">
                Open Dialog
              </v-btn>
            </template>

            <v-card>
              <v-toolbar>
                <v-toolbar-title class="text-center">Settings</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                      icon
                      @click="dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialog = false">
                  Previous
                </v-btn>
                <v-btn
                    class="success"
                    text
                    @click="dialog = false">
                  OK
                </v-btn>
                <v-btn
                    text
                    @click="dialog = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
		</div>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>
<script>

import PropsTable from "../docs-components/PropsTable";

export default {
  name: 'DialogDemoPage',
  components: {
    PropsTable
  },
  data() {
    return {
      dialog: false,
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Dialog',
          link: '#dialog'
        }
      ],
      propsData: [
        {
          name: 'title',
          type: 'String',
          default: '',
          desc: ''
        },
        {
          name: 'icon',
          type: '',
          default: '',
          desc: ''
        },
      ],
      sourceCode: {
        usage: '<MtAppGrid>\n' +
            '    <MtAppTile title="Task Creation" :icon="require(\'../icons/icon_menu_applications_default.svg\')"/>\n' +
            '    ...\n' +
            '</MtAppGrid>'
      }
    }
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>