import Vue from 'vue';
import Dev from './serve.vue';
import router from './router'
import vuetify from './plugins/vuetify';

// To register individual components where they are used (serve.vue) instead of using the
// library as a whole, comment/remove this import and it's corresponding "Vue.use" call

// Import components
import MtUiComponents from '@/entry.esm';
import '../src/lib-components/styles/index.scss'
Vue.use(MtUiComponents);

Vue.config.productionTip = false;

// Import vue-highlightjs (for demo code snippets)
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/default.css' // or other highlight.js theme
Vue.use(VueHighlightJS)

// global Docs components
Vue.component('StatusBadge', require('./docs-components/StatusBadge').default);

document.title = 'Mettler Toledo LabX Styleguide';

new Vue({
    vuetify,
    router,
    render: (h) => h(Dev),
}).$mount('#app');
