<template>
  <div class="demo-grids">
    <!-- Page Title-->
    <v-container class="mb-3">
      <v-row>
        <v-col>
          <h1>Grids</h1>
          <status-badge status="draft">Currently under development</status-badge>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Grids"
}
</script>

<style scoped>

</style>