<template>
  <div class="typography-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Typography</mt-page-header>
  
    <!-- Elements Title -->
    <v-container>
      <v-row>
        <v-col>		
          <h2 id="headings">Headings</h2>		  
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section -->
    <v-container>
      <v-row>
        <v-col>

          <!-- Header 1 -->
          <div class="element-example-wrap" >

			<h3>Header 1<status-badge status="review_design" inline="inline"></status-badge></h3>
			
			<h3>Demo:</h3>			
            <h1>This is a header 1</h1>			
			<h3>Usage:</h3>
			
            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <!-- Element code snippet -->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.heading1 }}</code></pre>
            </v-sheet>
          </div>

          <!-- Header 2 -->
          <div class="element-example-wrap" >
			<h3>Header 2<status-badge status="review_design" inline="inline"></status-badge></h3>

            <h3>Demo:</h3>
            <h2>This is a header 2</h2>
			<h3>Usage:</h3>

            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <!-- Element code snippet -->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.heading2 }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
	  scrollNavigationList: [
	    {
		  linkType: 'position',
		  name: 'Headings',
		  link: '#headings'
		}
	  ],
      sourceCode: {
        heading1: '<h1>Title</h1>',
        heading2: '<h2>Title</h2>'
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>