<template>
  <div class="demo-page-expansion-panel">
    <!-- Page Title-->
    <v-container class="mb-3">
      <v-row>
        <v-col>
          <h1>Expansion Panel</h1>
          <status-badge status="draft">Currently under development</status-badge>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="mt-10">
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel
                v-for="(item,i) in 5"
                :key="i"
            >
              <v-expansion-panel-header>
                Item
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  name: "ExpansionPanels"
}
</script>

<style scoped>

</style>