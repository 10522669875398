<template>
<div class="unit-value-pair-container">
  <mt-text-field>
  </mt-text-field>
  <mt-select :items="units">
  </mt-select>
</div>
</template>

<script>
export default {
  name: "UnitValuePair",
  props:{
    units: Array,
  }
}
</script>

<style scoped>
.unit-value-pair-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.unit-value-pair-container .v-input{
  flex: 0 0 calc(50% - 5px);
}
</style>