<template>
  <div class="page-header-page">

    <mt-page-header :scrollNavigationList="scrollNavigationList">Page Header</mt-page-header>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="pageheader">Page Header</h2>
          <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col>

          <!-- Element Demo -->
          <div id="pageheader" class="element-example-wrap">

            <h3>Demo:
              <status-badge status="progress" inline="inline"></status-badge>
            </h3>

            <mt-page-header :scrollNavigationList="scrollNavigationListExample" :breadcrumbs="breadcrumbs">Page title
            </mt-page-header>

            <h3>Usage:
              <status-badge status="progress" inline="inline"></status-badge>
            </h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6" elevation="2">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.basicGridWithHeader }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

export default {

  data() {
    return {
      breadcrumbs: [
        {
          text: "Layout",
          disabled: false,
          href: 'page-header'
        },
        {
          text: "Page Header",
          disabled: true,
          href: 'page-header'
        }
      ],
      scrollNavigationList: [
        {
          linkType: "position",
          name: 'Page Header',
          link: '#pageheader'
        }
      ],
      scrollNavigationListExample: [
        {
          linkType: "position",
          name: 'Navigation item 1',
          link: '#pageheader'
        },
        {
          linkType: "position",
          name: 'Navigation item 2',
          link: '#pageheader'
        },
        {
          linkType: "link",
          name: 'Navigation item 3',
          link: 'page-header'
        }
      ],
      sourceCode: {
        basicGridWithHeader: '<template>\n' +
            '  <div class="example-page">\n' +
            '    <mt-page-header :scrollNavigationList="scrollNavigationList">Page title</mt-page-header>\n' +
            '    <v-container fluid>\n' +
            '      <v-row>\n' +
            '        <v-col>\n' +
            '          <!-- column content -->\n' +
            '        </v-col>\n' +
            '      </v-row>\n' +
            '    </v-container>\n' +
            '  </div>\n' +
            '</template>',
      }
    }
  }

}
</script>

<style scoped>
.page-header-page {
  padding-top: 96px !important;
}

.element-example-wrap .page-header {
  position: static;
  width: 100% !important;
  padding: 0 0 0 24px;
  margin-bottom: 48px;
}
</style>

