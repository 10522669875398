<template>
    <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
      <template #activator="{ on, attrs }">
        <mt-text-field
          :value="formatedDate"
          clearable
          :rules="[...validators.timespanValidator]"
          v-bind="attrs"
          append-icon="mdi-clock"
          @click:append="menu = true"
          @click:clear="formatedDate = null"
        ></mt-text-field>
      </template>
      <v-time-picker
        v-model="time"
        format="24hr"
      >
	    <mt-text-field
          v-model="days"
          solo
          outlined
          flat
          type="text"
          suffix="Days"
          hide-details="auto"
		>
		  <template slot="append">
		    <v-icon @click="increment">mdi-plus</v-icon>
		    <v-icon @click="decrement">mdi-minus</v-icon>
		  </template>
        </mt-text-field>
	  </v-time-picker>
    </v-menu>
</template>

<script lang="js">

import validationUtil from "./validationUtil";
import {format} from "date-fns";

export default {
  name: "MtTimePicker",
  data: () => ({
    time: "",
    menu: false,
    days: "0",
  }),
  computed: {
    validators: {
      get: function () {
        return validationUtil.rules;
      },
    },
    formatedDate: {
      get: function () {
        return this.getFormattedTimeSpan;
      },
      set: function (val) {
        this.$emit('input', val);
      },
    },
    getFormattedTimeSpan() {
      // format is defined in the SRD SAT page 124
      var time = this.time.split(':');

        var formattedValue = this.time  ? this.days + "d-" +  time[0] + "h:" + time[1] + "m"
          : "";
      this.updateProperty(formattedValue);
      return formattedValue;

    },
  },
  methods: {
    increment () {
      this.days = parseInt(this.days,10) + 1
    },
    decrement () {
      var entry = parseInt(this.days,10);
      this.days = entry<=0? 0 : entry - 1
    },
    updateProperty: function(val){
      this.$emit('input', val);
      return val;
    },
  }
};
</script>
