<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Field_Favorite" xmlns="http://www.w3.org/2000/svg" width="32.001" height="32" viewBox="0 0 32.001 32" class="mt-icon mt-field-favorite">
    <rect id="Icon_Bounding_Box_16x16" width="31.999" height="32" fill="#bf1718" opacity="0"/>
    <path class="a" d="M966.5,1306l4.944,10.018,11.056,1.606-8,7.8,1.889,11.011-9.888-5.2-9.889,5.2,1.889-11.011-8-7.8,11.056-1.606Z" transform="translate(-950.499 -1305.5)"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "FieldFavorite"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
  .a {
    fill: currentColor;
  }
</style>

