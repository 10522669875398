import { render, staticRenderFns } from "./MtFieldWarning.vue?vue&type=template&id=72bfceb2&scoped=true&"
import script from "./MtFieldWarning.vue?vue&type=script&lang=js&"
export * from "./MtFieldWarning.vue?vue&type=script&lang=js&"
import style0 from "./MtFieldWarning.vue?vue&type=style&index=0&id=72bfceb2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72bfceb2",
  null
  
)

export default component.exports