<template>
  <v-select
      v-bind="computedProps"
      v-on="$listeners"
  >
    <!-- Dynamically inherit slots from parent -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-select>
</template>
<script>
import { VSelect } from 'vuetify/lib';

export default {
  name: "MtSelect",
  extends: VSelect,
  props: {
    appendIcon: {
      type: String,
      default: 'mdi-chevron-down'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    chips: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
        dense: !this.chips,
      }
    }
  }
}
</script>

<style scoped>

</style>