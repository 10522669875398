<template>
  <svg id="Icon_Field_Success" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <rect id="Icon_Bounding_Box_32x32" width="16" height="16" fill="#bf1718" opacity="0"/>
    <path id="Path_958" data-name="Path 958" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(1 1)" fill="#168a00"/>
    <path id="Path_1184" data-name="Path 1184" d="M684.04,1700.849l-1.63-1.141-3.808,5.438-2.711-1.9-1.141,1.63,4.346,3.043Z" transform="translate(-671.551 -1695.508)" fill="#fff"/>
  </svg>
</template>

<script>
export default {
  name: "MtIconFieldSuccess",
  props: {
  },
}
</script>

