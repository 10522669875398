<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Field_Signature_Step_3" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-field-signature-step-3">
    <rect id="Icon_Bounding_Box_32x32" width="32" height="32" fill="#bf1718" opacity="0"/>
    <path class="a" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(1 1)"/>
    <path class="b" d="M694.751,1702.369l-3.509-2.661-8.2,12.679-5.836-4.426-2.457,3.8,9.357,7.095Z" transform="translate(-668.751 -1693.282)" fill="#fff"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "FieldSignatureStep3"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
  .a {
    fill: currentColor;
  }
  .b {
    fill: #FFF;
  }
  .theme--dark .b {
    fill: #000;
  }
</style>

