<template>
  <v-card class="mx-auto">
    <v-toolbar color="titan_white" dark>
      <v-toolbar-title>{{ headerName }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <v-list-group
        v-for="item in dataContext"
        :key="item.name"
        v-model="item.active"
        sub-group>
        <template #activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          link
          @click="$emit('onSelect', child)">
          <v-list-item-content>
            <v-list-item-title v-text="child.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "MtExpandableList",
  props: {
    headerName: String,
    dataContext: Object,
  },
};
</script>

<style scoped>
/* TODO cubera: refactor to sass file and remove usage of !important*/
.v-list {
  top: 0px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.v-toolbar__title {
  color: #001366 !important;
  font-weight: bolder;
  font-size: medium;
}
.v-toolbar {
  z-index: 10 !important;
}
.v-list-group {
  top: 0px !important;
  padding-top: 0 !important;
}
.v-list-item--link:hover {
  background: #8d9bd9 !important;
  cursor: pointer;
}
.v-list-item--link {
  height: 15px;
}
.v-list-group__header {
  background: #e0e6ff !important;
  cursor: pointer;
}
.v-list-item--link:nth-of-type(odd) {
  background-color: #eff1fc;
}
</style>
