<template>
  <v-container fluid>
    <v-overlay v-model="show" persistent>
      <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
      ></v-progress-circular>
      {{ title }}
    </v-overlay>
  </v-container>
</template>

<script>

export default {
  name: 'MtLoadingOverlay',
  props: {
    title: String,
    show: Boolean,
  }
}
</script>

<style>
.v-overlay {
  display: flex;
  flex-direction: column !important;
}

.v-progress-circular {

}

.custom-loading_indicator {
  position: fixed !important;
  bottom: 0px !important;
  left: 0px !important;
  width: 100% !important;
}
</style>
