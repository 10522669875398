<template>
  <div class="mb-2">
    <v-row class="pb-2 align-center">
      <v-col cols="4"><h2 class="py-0"><slot></slot></h2></v-col>
      <v-col align="right">
        <slot name="actionbarButtons"></slot>
        <slot name="defaultAction"></slot>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="pt-2">
      <v-col>
        <slot name="actionbarDescription"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MtDataTableActionbar",
}
</script>
<style scoped>

h2 {
  border-bottom: none;
}

.v-btn {
  font-size: 1rem;
}
</style>