<template>
  <div class="columns-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Columns</mt-page-header>
  
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="basicgrid">Basic Grid</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- Element Demo -->
          <div class="element-example-wrap">
		  
		    <h3>Demo:</h3>
			
			<v-row class="grid-demo">
			  <v-col>
				<div class="col-filler"></div>
			  </v-col>
		    </v-row>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.basicGrid }}</code></pre>
            </v-sheet>
          </div>
		  
		</v-col>
	  </v-row>
	</v-container>  

	<!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="twocolumnsgrid">2 Columns Grid</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
		
          <!-- Element Demo -->
          <div class="element-example-wrap">
            
			<h3>Demo (1:1):</h3>
			
            <v-row class="grid-demo">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div class="col-filler"></div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <div class="col-filler"></div>
              </v-col>
            </v-row>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.gridTwoColumns }}</code></pre>
              <br>
			  <a href="https://vuetifyjs.com/en/components/grids/#usage" target="_blank">For more grid details see here</a>
            </v-sheet>
          </div>
		  
		  <!-- Element Demo -->
          <div class="element-example-wrap">
            
			<h3>Demo (2:1):</h3>
			
            <v-row class="grid-demo">
              <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <div class="col-filler"></div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"></div>
              </v-col>
            </v-row>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.gridTwoColumnsRight }}</code></pre>
              <br>
			  <a href="https://vuetifyjs.com/en/components/grids/#usage" target="_blank">For more grid details see here</a>
            </v-sheet>
          </div>
		  
		  <!-- Element Demo -->
          <div class="element-example-wrap">
            
			<h3>Demo (1:2):</h3>
			
            <v-row class="grid-demo">
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"></div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <div class="col-filler"></div>
              </v-col>
            </v-row>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.gridTwoColumnsLeft }}</code></pre>
              <br>
			  <a href="https://vuetifyjs.com/en/components/grids/#usage" target="_blank">For more grid details see here</a>
            </v-sheet>
          </div>
		  
		</v-col>
	  </v-row>
	</v-container>  

	<!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="threecolumnsgrid">3 Columns Grid</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
		
		  <!-- Element Demo -->
          <div class="element-example-wrap">
            
			<h3>Demo:</h3>
			
            <v-row class="grid-demo">
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"></div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"></div>
              </v-col>
			  <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <div class="col-filler"></div>
              </v-col>
            </v-row>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.gridThreeColumns }}</code></pre>
              <br>
			  <a href="https://vuetifyjs.com/en/components/grids/#usage" target="_blank">For more grid details see here</a>
            </v-sheet>
          </div>
		  
		</v-col>
	  </v-row>
	</v-container> 

  </div>
</template>

<script>

export default {

  data() {
    return {
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Basic grid',
          link: '#basicgrid'
        },
		{
          linkType: "position",
          name: '2 Columns Grid',
          link: '#twocolumnsgrid'
        },
		{
          linkType: "position",
          name: '3 Columns Grid',
          link: '#threecolumnsgrid'
        }
      ],
	  scrollNavigationListExample: [
        {
          linkType: "position",
          name: 'Navigation item 1',
          link: '#pageheader'
        },
		{
          linkType: "position",
          name: 'Navigation item 2',
          link: '#pageheader'
        },
		{
          linkType: "position",
          name: 'Navigation item 3',
          link: '#pageheader'
        }
      ],
      sourceCode: {
        basicGrid: '<template>\n' +
            '  <div>\n' +
            '    <v-container fluid>\n' +
            '      <v-row>\n' +
            '        <v-col>\n' +
            '          <!-- column content -->\n' +
            '        </v-col>\n' +
            '      </v-row>\n' +
            '    </v-container>\n' +
            '  </div>\n' +
            '</template>',
        gridTwoColumns: '<template>\n' +
            '  <div>\n' +
            '    <v-container fluid>\n' +
            '      <v-row>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >\n' +
            '          <!-- left column content -->\n' +
            '        </v-col>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >\n' +
            '          <!-- right column content -->\n' +
            '        </v-col>\n' +
            '      </v-row>\n' +
            '    </v-container>\n' +
            '  </div>\n' +
            '</template>',
		gridTwoColumnsRight: '<template>\n' +
            '  <div>\n' +
            '    <v-container fluid>\n' +
            '      <v-row>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" >\n' +
            '          <!-- left column content -->\n' +
            '        </v-col>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >\n' +
            '          <!-- right column content -->\n' +
            '        </v-col>\n' +
            '      </v-row>\n' +
            '    </v-container>\n' +
            '  </div>\n' +
            '</template>',
		gridTwoColumnsLeft: '<template>\n' +
            '  <div>\n' +
            '    <v-container fluid>\n' +
            '      <v-row>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >\n' +
            '          <!-- left column content -->\n' +
            '        </v-col>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" >\n' +
            '          <!-- right column content -->\n' +
            '        </v-col>\n' +
            '      </v-row>\n' +
            '    </v-container>\n' +
            '  </div>\n' +
            '</template>',
		gridThreeColumns: '<template>\n' +
            '  <div>\n' +
            '    <v-container fluid>\n' +
            '      <v-row>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >\n' +
            '          <!-- left column content -->\n' +
            '        </v-col>\n' +
            '        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >\n' +
            '          <!-- middle column content -->\n' +
            '        </v-col>\n' +
			'        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" >\n' +
            '          <!-- right column content -->\n' +
            '        </v-col>\n' +
            '      </v-row>\n' +
            '    </v-container>\n' +
            '  </div>\n' +
            '</template>',
      }
    }
  }

}
</script>

<style scoped>
.grid-demo {
  padding-bottom: 32px;
}

.col-filler {
  height: 72px;
  border: 1px dashed var(--v-midnight_blue-base);
}

.element-example-wrap .page-header {
  position: static;
  padding: 0 24px;
  margin-bottom: 48px;
}
</style>

