<template>
  <div class="layout-helpers-page">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Layout Helpers</mt-page-header>
  
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="basicgrid">MtLeftRightCol</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
		
		  <!-- Element Demo -->
          <div class="element-example-wrap">

		    <h3>Demo:</h3>
		
            <MtLeftRightCol left="Content left" right="Content right" />
            <MtLeftRightCol left="Content left" right="Content right" :right_error=true />
		
		    <h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.leftRightCol }}</code></pre>
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.leftRightColError }}</code></pre>
            </v-sheet>
		  </div>
		
        </v-col>
      </v-row>
    </v-container>
	
  </div>
</template>

<script>
import MtLeftRightCol from "../../src/lib-components/MtLeftRightCol";
export default {
  components: {MtLeftRightCol},
  data() {
    return {
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'MtLeftRightCol',
          link: '#leftrightcol'
        }
      ],
      sourceCode: {
        leftRightCol: '<mt-left-right-col left="Content left" right="Content right" />',
        leftRightColError: '<mt-left-right-col left="Content left" right="Content right" :right_error=true  />'
      }
    }
  },
}
</script>

<style scoped>

</style>