<template>
  <div class="typography-page">

    <mt-page-header :scrollNavigationList="scrollNavigationList">Form Fields</mt-page-header>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="textinput">Text</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- Text Input -->
          <div class="element-example-wrap">

            <h3>Text Input
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <div class="d-inline-flex">
              <v-checkbox
                  label="disabled"
                  v-model="myinputs.mtTexField.disabled"
              >
              </v-checkbox>
              <v-checkbox
                  class="ml-3"
                  label="readonly"
                  v-model="myinputs.mtTexField.readonly"
              >
              </v-checkbox>
              <v-checkbox
                  class="ml-3"
                  label="state success"
                  v-model="myinputs.mtTexField.stateOk"
              >
              </v-checkbox>
              <v-checkbox
                  class="ml-3"
                  label="state warning"
                  v-model="myinputs.mtTexField.stateWarn"
              >
              </v-checkbox>
              <v-checkbox
                  class="ml-3"
                  label="state error"
                  v-model="myinputs.mtTexField.stateErr"
              >
              </v-checkbox>
            </div>

            <mt-text-field
                label="Some label"
                hint="Hint placeholder"
                :value="''"
                :disabled="myinputs.mtTexField.disabled"
                :readonly="myinputs.mtTexField.readonly"
                :state="inputReadonlyState"
            ></mt-text-field>

            <h3>Usage:</h3>
            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <pre v-highlightjs="getInputSrc"><code class="html pa-0"></code></pre>
            </v-sheet>
          </div>

          <!-- Text Area -->
          <div class="element-example-wrap">

            <h3>Textarea
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <mt-text-area
                name="input-7"
                label="Some label"
                value=""
                hint="Hint text"
                :disabled="false"
                :readonly="false"
                state=""
            ></mt-text-area>

            <h3>Usage:</h3>

            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <!-- Element code snippet -->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.textArea }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="passwordinput">Password</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- Password -->
          <div class="element-example-wrap">

            <h3>Password Input
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <mt-password-field
                name="pwfield"
                hint="At least 8 characters"
                value="12345678"
                label="Password"
                :disabled="false"
                :readonly="false"
                state=""
            ></mt-password-field>

            <h3>Usage:</h3>

            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <!-- Element code snippet -->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.passwordInput }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="emailinput">Email</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- E-Mail -->
          <div class="element-example-wrap">

            <h3>Email Input
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <mt-email-field
                name="emailfield"
                label="E-Mail"
                hint="Enter E-Mail address"
                :disabled="false"
                :readonly="false"
                state=""
            ></mt-email-field>

            <h3>Usage:</h3>

            <!-- Element usage description -->
            <v-sheet class="pa-6 small-margin-bottom">
              <!-- Element code snippet -->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.emailInput }}</code></pre>
            </v-sheet>

            <h3>Validation:</h3>

            <v-sheet class="pa-6">
              <p><code>emailMatch</code> validation by default.</p>
              <p>You can override it by passing new rules. <a @click="$vuetify.goTo('#validation', options)">
                See Validation
                <v-icon left>
                  mdi-arrow-right
                </v-icon>
              </a>
              </p>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="selectinput">Select</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- Select -->
          <div class="element-example-wrap">

            <h3>Select
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <mt-select
                v-model="valueSelect"
                :items="items"
                value="active"
                label="Items"
            ></mt-select>

            <h3>Usage:</h3>

            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <!-- Element code snippet -->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.selectInput }}</code></pre>
            </v-sheet>
          </div>

          <!-- Select Chips -->
          <div class="element-example-wrap">

            <h3>Select Chips
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <mt-select
                v-model="valueSelectChips"
                :items="items"
                label="Required roles"
                chips
                multiple
            ></mt-select>

            <h3>Usage:</h3>

            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <!-- Element code snippet -->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.selectInput }}</code></pre>
              <br>
              <p>Note: no dense applied for select as chips</p>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="checkbox">Checkbox</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- Checkbox-->
          <div class="element-example-wrap">

            <h3>Checkbox
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <v-checkbox label="User must change change password at next login " value="true"></v-checkbox>

            <h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.checkBox }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="radiobox">Radiobox</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
          <!-- Radiobox-->
          <div class="element-example-wrap">

            <h3>Radiobox
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Demo:</h3>

            <v-radio-group v-model="radioGroup">
              <v-radio label="Radio 1 "></v-radio>
              <v-radio label="Radio 2 "></v-radio>
            </v-radio-group>

            <h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.radioBox }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="validation">Validation</h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- Validation -->
          <div class="element-example-wrap">

            <h3>Validation Rules
              <status-badge status="review_design" inline="inline"></status-badge>
            </h3>

            <h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <p>Define validation rules and pass them to input field.</p>
              <h5>Basic validation rules example</h5>
              <pre v-highlightjs><code class="javascript pa-0">{{ sourceCode.validationRules }}</code></pre>

              <!-- Element code snippet-->
              <h5>Adding rules to input</h5>
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.validationBinding }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import StatusBadge from "../docs-components/StatusBadge";

export default {
  name: "FormInputs",
  components: {
    StatusBadge
  },
  data() {
    return {
      myinputs: {
        mtTexField: {
          disabled: false,
          readonly: false,
          stateOk: false,
          stateWarn: false,
          stateErr: false,
        },
        mtTexArea: {
          disabled: false,
          readonly: false,
          stateOk: false,
          stateWarn: false,
          stateErr: false,
        },
      },
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      },
      scrollNavigationList: [
        {
          linkType: "position",
          name: 'Text',
          link: '#textinput'
        },
        {
          linkType: "position",
          name: 'Password',
          link: '#passwordinput'
        },
        {
          linkType: "position",
          name: 'Email',
          link: '#emailinput'
        },
        {
          linkType: "position",
          name: 'Select',
          link: '#selectinput'
        },
        {
          linkType: "position",
          name: 'Checkbox',
          link: '#checkbox'
        },
        {
          linkType: "position",
          name: 'Radiobox',
          link: '#radiobox'
        },
        {
          linkType: "position",
          name: 'Validation',
          link: '#validation'
        }
      ],
      eyeToggle: false,
      password: 'Password',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      radioGroup: null,
      valueSelect: null,
      valueSelectChips: null,
      items: ['Lab Administrator', 'Lab Assistant', 'Lab Manager', 'Lab Technician'],
      sourceCode: {
        textInput: '<mt-text-field\n' +
            '       name="somefield"\n' +
            '       label="Some label"\n' +
            '       hint="Hint placeholder"\n' +
            '       value="example"\n' +
            '></mt-text-field>',
        textInputDisabled: '<mt-text-field\n' +
            '       name="somefield"\n' +
            '       label="Some label"\n' +
            '       hint="Hint placeholder"\n' +
            '       value="example"\n' +
            '       disabled\n' +
            '></mt-text-field>',
        textInputReadonly: '<mt-text-field\n' +
            '       name="somefield"\n' +
            '       label="Some label"\n' +
            '       hint="Hint placeholder"\n' +
            '       value="example"\n' +
            '       readonly\n' +
            '></mt-text-field>',
        textArea: '<mt-text-area\n' +
            '       name="somefield"\n' +
            '       label="Some label"\n' +
            '       value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."\n' +
            '       hint="Hint text"\n' +
            '></mt-text-area>',
        passwordInput: '<mt-password-field\n' +
            '       name="somefield"\n' +
            '       hint="At least 8 characters"\n' +
            '       value="12345678"\n' +
            '       label="Password"\n' +
            '></mt-password-field>',
        emailInput: '<mt-email-field\n' +
            '       name="emailfield"\n' +
            '       label="E-Mail"\n' +
            '></mt-email-field>',
        selectInput: '<mt-select\n' +
            '       name="somefield"\n' +
            '       :items="selectItems"\n' +
            '       value="active"\n' +
            '       label="Items"\n' +
            '></mt-select>',
        checkBox: '<v-checkbox label="User must change password at next login" value="true"></v-checkbox>',
        radioBox: '<v-radio-group v-model="radioGroup">\n' +
            '  <v-radio label="Radio 1 "></v-radio>\n' +
            '  <v-radio label="Radio 2 "></v-radio>\n' +
            '</v-radio-group>',
        validationRules: 'data() {\n' +
            '  return {\n' +
            '    rules: {\n' +
            '      required: value => !!value || \'Required\',\n' +
            '      min: v => v.length >= 8 || \'Min 8 characters\',\n' +
            '      emailMatch: value => {\n' +
            '        const pattern = /^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/\n' +
            '        return pattern.test(value) || \'Invalid e-mail.\'\n' +
            '      }\n' +
            '    }\n' +
            '  }\n' +
            '}',
        validationBinding: '<mt-password-field\n' +
            '       :rules="[rules.required, rules.emailMatch]"\n' +
            '       label="Password"\n' +
            '></mt-password-field>',
      },
    }
  },
  computed: {
    options() {
      return {
        duration: this.scrollOptions.duration,
        offset: this.scrollOptions.offset,
        easing: this.scrollOptions.easing,
      }
    },
    inputReadonlyState() {

      if (this.myinputs.mtTexField.stateErr) {
        return 'error'
      }

      if (this.myinputs.mtTexField.stateWarn) {
        return 'warning'
      }

      if (this.myinputs.mtTexField.stateOk) {
        return 'success'
      }

      return
    },
    getInputSrc() {
      const data = this.myinputs.mtTexField

      let src = '<mt-text-field\n' +
      '       name="somefield"\n' +
      '       label="Some label"\n' +
      '       hint="Hint placeholder"\n' +
      '       value="example"\n'

      if(data.disabled) {
        src += '       :disabled="BooleanState"\n'
      }

      if(data.readonly) {
        src += '       :readonly="BooleanState"\n'
      }

      if(data.readonlyOK) {
        src += '       :state="\'success\'"\n'
      } else if (data.readonlyWarn) {
        src += '       :state="\'warning\'"\n'
      } else if (data.readonlyErr) {
        src += '       :state="\'error\'"\n'
      }

      src += '></mt-text-field>'
      return src
    }
  },
  watch: {
  },
  mounted() {
  }
}
</script>

<style scoped>
.v-input--selection-controls {
  padding: 0;
  margin: 0;
}

pre + h5 {
  padding-top: 24px;
}

.v-sheet.small-margin-bottom {
  margin-bottom: 24px;
}
</style>