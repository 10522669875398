<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Dialog_Warning" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-dialog-warning">
    <rect id="Icon_Bounding_Box_32x32" width="32" height="32" fill="#bf1718" opacity="0"/>
    <g id="Pfad_923" data-name="Pfad 923" transform="translate(-680 -1247)" fill="#ffcf33" stroke-miterlimit="10">
      <path d="M 710.1504516601562 1276.5 L 681.8499755859375 1276.5 L 696.0001831054688 1251.029541015625 L 710.1504516601562 1276.5 Z" stroke="none"/>
      <path d="M 709.3006591796875 1276 L 696.0001831054688 1252.05908203125 L 682.6997680664062 1276 L 709.3006591796875 1276 M 711.0001831054688 1277 L 681.000244140625 1277 L 696.0001831054688 1250 L 711.0001831054688 1277 Z" stroke="none" fill="#000"/>
    </g>
    <path id="Pfad_959" data-name="Pfad 959" d="M694,1275v-3h4v3Zm.282-4-.55-11h4.4l-.55,11Z" transform="translate(-680 -1247)"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "DialogWarning"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
</style>

