<template>
  <div class="tree-table-page">

    <mt-page-header>Tree Table</mt-page-header>

    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="treetable">Tree Table
            <status-badge status="review_design" inline="inline"></status-badge>
          </h2>
        </v-col>
      </v-row>
    </v-container>

    <!-- Demo container-->
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Demo:</h3>
          <tree-table
              :columns="columns"
              :rows="rows"
          >
            <template slot="firstName" slot-scope="props">
              <a :href="`https://www.google.com/search?q=${props.row.firstName.value}+${props.row.lastName.value}`" target="_blank" >{{ props.row.firstName.value }}</a>
            </template>

          </tree-table>

          <h3 class="mt-5">Usage:</h3>
          <!-- Element usage description -->
          <v-sheet class="pa-6">
            <!-- Element code snippet -->
            <h2>General</h2>
            <pre v-highlightjs><code class="html pa-0">{{ sourceCode.treeTable }}</code></pre>
            <p class="mt-5">Customize data – Example by adding link to FirstName</p>
            <pre v-highlightjs><code class="html pa-0">{{ sourceCode.treeTableCustomizeData }}</code></pre>

            <h2 class="mt-5">Columns</h2>
            <p>Bind via :columns="columns"</p>
            <pre v-highlightjs><code class="javascript pa-0">{{ sourceCode.columns }}</code></pre>
            <props-table :props-data="propsTreeTable"></props-table>


            <h2 class="mt-5">Data Item / Row</h2>
            <p>Each row contains: n-Data (must be equal n-Columns), meta, _children </p>
            <p>Bind via :rows="rows"</p>

            <pre v-highlightjs><code class="javascript pa-0">{{ sourceCode.rows }}</code></pre>
            <props-table :props-data="propsRowItem"></props-table>

            <h2 class="mt-5">Status Icons per Cell</h2>
            <v-icon small >$mtIconFieldSuccess</v-icon> <code>success</code><br>
            <v-icon small >$mtIconFieldWarning</v-icon> <code>warning</code><br>
            <v-icon small >$mtIconFieldError</v-icon> <code>error</code><br>

            <h2 class="mt-5">Row Background</h2>
            <code class="white">white</code>
            <code class="solitude">solitude</code>
            <code class="panache">panache</code>
            <code class="blue_romance">blue_romance</code>
            <code class="white_nectar">white_nectar</code>
            <code class="drover">drover</code>
            <code class="white_smoke">white_smoke</code>
            <code class="pale_pink">pale_pink</code>

          </v-sheet>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

import PropsTable from "../docs-components/PropsTable";
import TreeTable from "../../src/lib-components/TreeTable";

export default {
  components: {
    TreeTable,
    PropsTable,
  },
  data() {
    return {
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Tree Table',
          link: '#treetable'
        }
      ],
      columns: [
        {
          property: "firstName",
          title: "First Name",
          direction: null,
          // filterable: true,
          collapseIcon: true,
        },
        {
          property: "lastName",
          title: "Last Name",
          direction: null,
          // filterable: true,
          collapseIcon: true,
        }
      ],
      rows: [
        {
          firstName: {
            value: 'Boudewijn',
            bold: true,
            status: null,
          },
          lastName: {
            value: 'Van Brabandt',
            bold: true,
            status: null,
          },
          meta: {
            background: null
          },
          _children: [
            {
              firstName: {
                value: 'Garret',
                bold: false,
                status: 'success',
              },
              lastName: {
                value: 'Muster',
                bold: false,
                status: 'success',
              },
              meta: {
                background: 'panache'
              },
              _children: [
                {
                  firstName: {
                    value: 'Garret 2',
                    bold: false,
                    status: 'success',
                  },
                  lastName: {
                    value: 'Muster',
                    bold: false,
                    status: 'success',
                  },
                  meta: {
                    background: 'white_nectar'
                  },
                },
              ],
            },
          ],
        },
        {
          firstName: {
            value: 'Lorem',
            bold: false,
            through: false,
            status: null,
          },
          lastName: {
            value: 'Ipsum',
            bold: false,
            through: false,
            status: null,
          },
          meta: {
            background: null
          },
          _children: [
            {
              firstName: {
                value: 'Garret 2',
                bold: true,
                through: false,
                status: null,
              },
              lastName: {
                value: 'Muster',
                bold: false,
                through: true,
                status: null,
              },
              meta: {
                background: 'white'
              },
            },
            {
              firstName: {
                value: 'Garret 2',
                bold: false,
                through: false,
                status: null,
              },
              lastName: {
                value: 'Muster',
                bold: false,
                through: false,
                status: null,
              },
              meta: {
                background: 'solitude'
              },
            },
            {
              firstName: {
                value: 'Garret 2',
                bold: false,
                status: 'success',
              },
              lastName: {
                value: 'Muster',
                bold: false,
                status: 'success',
              },
              meta: {
                background: 'panache'
              },
            },
            {
              firstName: {
                value: 'Garret 2',
                bold: false,
                status: 'success',
              },
              lastName: {
                value: 'Muster',
                bold: false,
                status: 'success',
              },
              meta: {
                background: 'blue_romance'
              },
            },
            {
              firstName: {
                value: 'Garret 2',
                bold: false,
                status: 'warning',
              },
              lastName: {
                value: 'Muster',
                bold: false,
                status: 'warning',
              },
              meta: {
                background: 'white_nectar'
              },
            },
            {
              firstName: {
                value: 'Garret 2',
                bold: false,
                status: 'warning',
              },
              lastName: {
                value: 'Muster',
                bold: false,
                status: 'warning',
              },
              meta: {
                background: 'drover'
              },
            },
            {
              firstName: {
                value: 'Garret',
                bold: false,
                status: 'error',
              },
              lastName: {
                value: 'Muster',
                bold: false,
                status: 'error',
              },
              meta: {
                background: 'white_smoke'
              },
            },
            {
              firstName: {
                value: 'Boudewijn',
                bold: false,
                status: 'error',
              },
              lastName: {
                value: 'Van Test',
                bold: false,
                status: 'error',
              },
              meta: {
                background: 'pale_pink'
              },
            },
          ],
        },
        {
          firstName: {
            value: 'Garret 2',
            bold: false,
            status: null,
          },
          lastName: {
            value: 'Muster',
            bold: false,
            through: true,
            status: null,
          },
          meta: {
            background: 'white'
          },
        },
        {
          firstName: {
            value: 'Garret 2',
            bold: false,
            status: null,
          },
          lastName: {
            value: 'Muster',
            bold: false,
            through: true,
            status: null,
          },
          meta: {
            background: 'solitude'
          },
        },
        {
          firstName: {
            value: 'Garret 2',
            bold: false,
            status: 'success',
          },
          lastName: {
            value: 'Muster',
            bold: false,
            status: 'success',
          },
          meta: {
            background: 'panache'
          },
        },
        {
          firstName: {
            value: 'Garret 2',
            bold: false,
            status: 'success',
          },
          lastName: {
            value: 'Muster',
            bold: false,
            status: 'success',
          },
          meta: {
            background: 'blue_romance'
          },
        },
        {
          firstName: {
            value: 'Garret 2',
            bold: false,
            status: 'warning',
          },
          lastName: {
            value: 'Muster',
            bold: false,
            status: 'warning',
          },
          meta: {
            background: 'white_nectar'
          },
        },
        {
          firstName: {
            value: 'Garret 2',
            bold: false,
            status: 'warning',
          },
          lastName: {
            value: 'Muster',
            bold: false,
            status: 'warning',
          },
          meta: {
            background: 'drover'
          },
        },
        {
          firstName: {
            value: 'Garret',
            bold: false,
            status: 'error',
          },
          lastName: {
            value: 'Muster',
            bold: false,
            status: 'error',
          },
          meta: {
            background: 'white_smoke'
          },
        },
        {
          firstName: {
            value: 'Boudewijn',
            bold: false,
            status: 'error',
          },
          lastName: {
            value: 'Van Test',
            bold: false,
            status: 'error',
          },
          meta: {
            background: 'pale_pink'
          },
        },

      ],
      propsTreeTable: [
        {
          name: 'property',
          type: 'String',
          default: 'false',
          desc: 'Column name',
          required: true
        },
        {
          name: 'title',
          type: 'String',
          default: 'false',
          desc: 'Column title',
          required: true
        },
        {
          name: 'collapseIcon',
          type: 'Boolean',
          default: 'false',
          desc: 'Indicates if this column will contain the collapse icon.',
          required: false
        },
        {
          name: 'direction',
          type: 'Boolean',
          default: 'null',
          desc: 'The initial sort direction. If null, the rows are not sorted by this column. If true, the sorting is ascending. If false, the sorting is descending. ',
          required: false
        },
      ],
      propsRowItem: [
        {
          name: 'bold',
          type: 'Boolean',
          default: '',
          desc: 'Bold text',
          required: false
        },
        {
          name: 'through',
          type: 'Boolean',
          default: '',
          desc: 'Strikethrough text ',
          required: false
        },
        {
          name: 'status',
          type: 'String',
          default: '',
          desc: 'Used to display icon per cell, see "Status Icons" below. For no status - <code>null</code>',
          required: false
        },
        {
          name: 'meta.background',
          type: 'String',
          default: '',
          desc: 'Background color of row, see "Row Background" below. For no status - <code>null</code>',
          required: false
        },
      ],
      sourceCode: {
        treeTable: '<tree-table\n' +
            '       :columns="columns"\n' +
            '       :rows="rows"\n' +
            '>\n' +
            '</tree-table>',
        treeTableCustomizeData: '<tree-table\n' +
            '       :columns="columns"\n' +
            '       :rows="rows"\n' +
            '>\n' +
            '     <template slot="firstName" slot-scope="props">\n' +
            '       <a :href="`https://www.google.com/search?q=${props.row.firstName.value}+${props.row.lastName.value}`" target="_blank">{{ props.row.firstName.value }}</a>\n' +
            '     </template>\n' +
            '</tree-table>',
        methods: 'methods: {\n' +
            '    filterChanged (filter) {\n' +
            '      this.filter = filter;\n' +
            '    },\n' +
            '\n' +
            '    pageChanged (page) {\n' +
            '      this.page = page;\n' +
            '    },\n' +
            '},',
        columns: 'columns = [\n' +
            '  {\n' +
            '    property: "firstName",\n' +
            '    title: "First Name",\n' +
            '    direction: null,\n' +
            '    collapseIcon: true,\n' +
            '  },\n' +
            '  {\n' +
            '    property: "lastName",\n' +
            '    title: "Last Name",\n' +
            '    direction: null,\n' +
            '    collapseIcon: true,\n' +
            '  }\n' +
            ' ],',
        rows: 'rows = [\n' +
            '  {\n' +
            '    firstName: {\n' +
            '      value: \'Boudewijn\',\n' +
            '      bold: false,\n' +
            '      through: false,\n' +
            '      status: null,\n' +
            '    },\n' +
            '    lastName: {\n' +
            '      value: \'Van Brabandt\',\n' +
            '      bold: false,\n' +
            '      through: false,\n' +
            '      status: null,\n' +
            '    },\n' +
            '    meta: {\n' +
            '       background: null\n' +
            '    },\n' +
            '    _children: [\n' +
            '       {\n' +
            '          firstName: {\n' +
            '            value: \'Garret\',\n' +
            '            bold: true,\n' +
            '            through: false,\n' +
            '            status: \'warning\',\n' +
            '          },\n' +
            '          lastName: {\n' +
            '            value: \'Ipsum\',\n' +
            '            bold: false,\n' +
            '            through: true,\n' +
            '            status: \'warning\',\n' +
            '          },\n' +
            '          meta: {\n' +
            '            background: \'panache\'\n' +
            '          },\n' +
            '          _children: [\n' +
            '            ...\n' +
            '          ],\n' +
            '       },\n' +
            '    ],\n' +
            '  },\n' +
            '],'
      }
    }
  },
  mounted() {

  },
  methods: {

  },

}
</script>

<style>
.element-example-wrap .v-sheet {
  margin-bottom: 24px;
}

.element-example-wrap .v-sheet:last-child {
  margin-bottom: 0;
}

.element-example-wrap .v-sheet.hasMenu {
  position: relative;
  padding-right: 224px !important;  
}

.element-example-wrap .v-sheet.hasMenu a {
  position: absolute;
  right: 16px;
  top: 24px;
}
</style>
