<template>
  <div class="left-right-row">
    <div class="left" v-if="!left_error">{{ left }}</div>
    <div class="right" v-if="!right_error">{{ right }}</div>
    <div class="left validate_error" v-if="left_error">{{ left }}</div>
    <div class="right validate_error" v-if="right_error">{{ right }}</div>
  </div>
</template>

<script>
export default {
  name: "MtLeftRightCol",
  provide() {
    return {
      MtLeftRightCol: this.MtLeftRightCol
    }
  },
  props: {
    left: String,
    right: String,
    left_error: Boolean,
    right_error: Boolean
  }
}
</script>

<style scoped>
  .left-right-row{
    width: 100%;
    min-width: 300px;
  }
  .left{
    width: calc(50% - 4px);
	line-height: 1.75em;
    text-align: right;
    float: left;
    margin-right: 4px;
    color: var(--v-chetwode_blue-base);
  }
  .right{
    width: calc(50% - 4px);
	line-height: 1.75em;
    text-align: left;
    float: left;
    padding-left: 4px;
    color: #001366;
  }
  .validate_error{
    color: #e6223a;
  }
</style>
