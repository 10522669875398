<template>
  <div>
    <!-- actionbar with default buttons -->
    <data-table-actionbar v-if="actionbar || chooseColumns">
      {{ actionbar }}

      <!-- Default show/hide columns button for this table -->
      <template slot="defaultAction" v-if="chooseColumns">
        <v-btn
            small
            text
            @click.stop="dialogChooseColumns = true"
        >
          <v-icon>mdi-view-column-outline</v-icon>
          Columns
        </v-btn>
      </template>

      <!-- custom slots -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <!-- only for actionbarButtons, actionbarDescription slots-->
        <slot :name="slot" v-bind="scope" v-if="slot.startsWith('actionbar')" />
      </template>
    </data-table-actionbar>

    <!-- dialog - show/hide columns -->
    <v-dialog
        v-if="chooseColumns"
        v-model="dialogChooseColumns"
        max-width="350"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title class="text-center">Columns show/hide</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                @click="dialogChooseColumns = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text >
          <v-list shaped flat >
            <v-list-item-group
                v-model="selectedHeaders"
                multiple
            >
              <template v-for="(item, i) in allHeaders">
                <v-list-item
                    v-if="item.value != 'data-table-expand'"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="active"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                          :input-value="active"
                          color="accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="success"
              text
              @click="dialogChooseColumns = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <!-- table -->
      <v-data-table
          dense
          calculate-widths
          :headers="showHeaders"
          :items="items"
          :item-class="getRowBackground"
          :class="[{'resizable-cols': resizeColumns, 'show-expand': showExpand, 'readonly': readonly}, 'mb-0']"
          v-sortable-table="{headers, dragColumns, showSelect, showExpand, onEnd:sortTheHeadersAndUpdateTheKey}"
          v-columns-resizable="{resizeColumns, tableRef}"
          :show-select="showSelect"
          :show-expand="showExpand"
          :single-expand="singleExpand"
          :key="anIncreasingNumber"
          :ref="tableRef"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
      >
        <!-- header customization -->
        <template v-for="header in showHeaders" v-slot:[`header.${header.value}`]="{ header }">
          <v-icon small class="drag" v-if="dragColumns && header.value != 'data-table-expand'">
            mdi-arrow-left-right-bold
          </v-icon>
          {{ header.text }}
        </template>

        <template v-for="(item, key) in itemSlots" v-slot:[`item.data.${key}.value`]="{ item }">
          <!-- error icon  -->
          <v-icon small v-if="item.data[key].status && item.data[key].status == 'error'">$mtIconFieldError
          </v-icon>
          <!-- success icon  -->
          <v-icon small v-if="item.data[key].status && item.data[key].status == 'success'">$mtIconFieldSuccess
          </v-icon>
          <!-- warning icon  -->
          <v-icon small v-if="item.data[key].status && item.data[key].status == 'warning'">$mtIconFieldWarning
          </v-icon>
          <!-- text styling customization: bold, line-through -->
          <span
              :class="{ 'font-weight-bold': item.data[key].bold, 'text-decoration-line-through': item.data[key].through }">
            {{ item.data[key].value }}
          </span>
        </template>

        <!-- other slots if needed  -->
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>

      </v-data-table>

      <!-- Pagination  -->
      <div class="mt-pagination-wrap">
        <div class="px-5 mr-auto d-flex align-center">
          <span>Rows per page</span>
          <v-select
              class="mt-rows-per-page mt-0"
              background-color="titan_white"
              dense
              solo
              flat
              hide-details
              :value="itemsPerPage"
              :items="rowsPerPage"
              @change="itemsPerPage = parseInt($event, 10)"
          ></v-select>
        </div>
        <div class="px-5">Page {{ page }} of {{ pageCount }} ({{ items.length }} rows)</div>
        <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            color="corn_flower_blue"
            v-model="page"
            :length="pageCount"
        ></v-pagination>
      </div>

  </div>
</template>

<script>
import Sortable from 'sortablejs'; // sortableJs lib for dragging columns
import DataTableActionbar from "./DataTableActionbar"; // action bar for description/buttons

export default {
  name: "MtDataTable",
  components: {
    DataTableActionbar
  },
  data() {
    return {
      dialogChooseColumns: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rowsPerPage: [5, 10, 15],
      tableRef: null,
      anIncreasingNumber: 1,
      allHeaders: Array,
      selectedHeaders: [],
      headersMap: null,
      expanded: [],
      isColumnChooser: false,
    }
  },
  props: {
    actionbar: null,
    tableTitle: String,
    headers: Array,
    items: Array,
    readonly: Boolean,
    showSelect: Boolean,
    showExpand: Boolean,
    singleExpand: Boolean,
    dragColumns: Boolean,
    resizeColumns: Boolean,
    chooseColumns: Boolean
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots['actionbar']
    },
    showHeaders(selectedHeaders) {
      return this.allHeaders.filter(s => this.selectedHeaders.includes(s));
    },
    itemSlots: function () {
      return this.items[0].data
    },
  },
  watch: {},
  directives: {
    // dragging columns
    'sortable-table': {
      inserted: (el, binding) => {
        if (binding.value.dragColumns) {
          el.querySelectorAll('th').forEach((draggableEl, index) => {
            // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
            watchClass(draggableEl, 'sortHandle');

            if (binding.value.showSelect && binding.value.showExpand) {  // If datatable has selectable and expandable rows
              // add class to table headers except first one and one with value "data-table-expand"
              if (index > 0 && binding.value.headers[index - 1].value != 'data-table-expand') {
                draggableEl.classList.add('sortHandle');
              }
            } else if (binding.value.showExpand && binding.value.headers[index].value != 'data-table-expand') { // if only expandable rows
              draggableEl.classList.add('sortHandle');
            } else if (binding.value.showSelect && index > 0) { // if only selectable rows
              draggableEl.classList.add('sortHandle');
            } else {
              draggableEl.classList.add('sortHandle');
            }
          });

          // bind sortablejs
          Sortable.create(el.querySelector('tr'), binding.value ? {...binding.value, handle: '.sortHandle'} : {});
        }
      },
    },
    // resizing columns
    'columns-resizable': {
      inserted(el, binding, vnode) {
        // only if resizeColumns is set
        if (binding.value.resizeColumns) {
          // get table element of component
          el = vnode.context.$refs[binding.value.tableRef].$el.querySelector('table')
          const nodeName = el.nodeName;

          if (['TABLE', 'THEAD'].indexOf(nodeName) < 0) return;
          const table = nodeName === 'TABLE' ? el : el.parentElement;

          // create container that contains resize bars, ass some styles
          const resizeContainer = document.createElement('div');
          table.style.position = 'relative';
          resizeContainer.style.position = 'relative';
          resizeContainer.className = "vue-columns-resizable";

          // insert resize container
          table.parentElement.insertBefore(resizeContainer, table);

          // callback - generate resize bars itself
          setTimeout(function () {
            vnode.context.generateResizeBars(el)
          }, 10);
        }
      },
    },
  },
  created() {
    // copy original headers to avoid mutating props
    let origHeaders = this.headers
    // if expandable - add expand-column at the end
    if (this.showExpand) {
      origHeaders.push({text: '', value: 'data-table-expand'})
    }

    this.allHeaders = Object.values(origHeaders)
    this.selectedHeaders = Object.values(origHeaders) // for column chooser
    this.tableRef = 'table' + this._uid // reference to datatable

    // if resizable add eventListener to handle resize-bars on window resize
    if (this.resizeColumns) {
      window.addEventListener("resize", this.handleWindowResize);
    }
  },
  mounted() {
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    // Hook to handle resize bars if window resizes
    handleWindowResize(e) {
      this.windowWidth = window.innerWidth
      this.generateResizeBars(this.$refs[this.tableRef].$el.querySelector('table'))
    },
    // background each row
    getRowBackground(item) {
      return item.meta.background
    },
    // need to reorder dataTable headers
    sortTheHeadersAndUpdateTheKey(evt) {
      let maxIndex = this.headers.length - 1
      // decrease maxIndex if expandable row active
      if (this.showExpand) {
        maxIndex = maxIndex - 1;
      }
      const headersTmp = this.showHeaders;
      // if showSelect decrease the old and new Index
      let oldIndex = this.showSelect ? --evt.oldIndex : evt.oldIndex;
      let newIndex = this.showSelect ? --evt.newIndex : evt.newIndex;
      // escape select-column
      if (newIndex < 0) {
        newIndex = 0
      }

      // escape expand-column
      if (newIndex > maxIndex) {
        newIndex = maxIndex
      }

      // reorder headers
      if (newIndex >= this.headers.length) {
        let k = newIndex - this.headers.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.allHeaders = headersTmp
      this.anIncreasingNumber += 1;
    },
    // generate resize bars for column width resize container
    generateResizeBars(el) {
      // get container that was created in columns-resizable directive
      const resizeContainer = el.parentElement.querySelector('.vue-columns-resizable')
      resizeContainer.innerHTML = ''
      const nodeName = el.nodeName;
      const table = nodeName === 'TABLE' ? el : el.parentElement;
      const thead = table.querySelector('thead');
      const ths = thead.querySelectorAll('th');
      const barHeight = nodeName === 'TABLE' ? table.offsetHeight : thead.offsetHeight;
      let moving = false;
      let movingIndex = 0;

      // generate resize-bar for each head cell (column) in the table
      ths.forEach((th, index) => {
        if (index + 1 >= ths.length) return;

        //init header cell width
        th.style.width = th.offsetWidth + 'px';
        const nextTh = ths[index + 1];

        // create resize-bar div
        const bar = document.createElement('div');

        // style resize-bar
        bar.style.position = 'absolute';
        bar.style.left = nextTh.offsetLeft - 4 + 'px';
        bar.style.top = 0;
        bar.style.height = barHeight + 'px';
        bar.style.width = '8px';
        bar.style.cursor = 'col-resize';
        bar.style.zIndex = 1;
        bar.className = 'columns-resize-bar';

        // add eventListener in order to detect which resize-bar is moving
        bar.addEventListener('mousedown', () => {
          moving = true;
          movingIndex = index;
          document.body.style.cursor = 'col-resize';
          document.body.style.userSelect = 'none'; // Prevent text selection
        });

        // add resize-bar to resize-container
        resizeContainer.appendChild(bar);
      });

      // get all resize-bars
      const bars = resizeContainer.querySelectorAll('.columns-resize-bar');

      // add general event after dragging done
      document.addEventListener('mouseup', () => {
        if (!moving) return;
        moving = false;
        document.body.style.cursor = '';
        document.body.style.userSelect = '';
        bars.forEach((bar, index) => {
          const th = ths[index];
          const nextTh = ths[index + 1];
          th.style.width = th.offsetWidth + 'px';
          bar.style.left = nextTh.offsetLeft - 4 + 'px';
        });
      });

      // on resize
      const handleResize = e => {
        if (moving) {
          const th = ths[movingIndex];
          const nextTh = ths[movingIndex + 1];
          const bar = bars[movingIndex];

          // set new width to current and next header cells
          th.style.width = th.offsetWidth + e.movementX + 'px';
          nextTh.style.width = nextTh.offsetWidth - e.movementX + 'px';

          // current bar position
          bar.style.left = nextTh.offsetLeft - 4 + e.movementX + 'px';
        }
      };

      // Add mousemove event to resize container and table itself
      resizeContainer.addEventListener('mousemove', handleResize);
      table.addEventListener('mousemove', handleResize);
    }
  }
}

/* Watcher for dragging columns class
** MutationObserver provides the ability to watch for changes being made to the DOM tree.
** Add back the "sortHandle" class if it gets stripped away by external code
*/
function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, {attributes: true});
}

</script>
<!-- todo: styles check -->
<style scoped>
h2 {
  border-bottom: none;
}

.v-btn {
  font-size: 1rem;
}

/deep/ table {
  overflow: hidden !important;
}

span.bold {
  font-weight: bold;
}

.vue-columns-resizable {
  width: 100% !important;
}

/deep/ .v-select__selections {
  line-height: 20px;
}

/deep/ .mt-rows-per-page > .v-input__control {
  min-height: 32px !important;
  max-width: 80px;
}

.v-icon.drag {
  cursor: grabbing;
}

.mt-pagination-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background: var(--v-titan_white-base);
  border: 1px solid var(--v-cold_purple-base);
  border-top: none;
}

.v-dialog__content .theme--light.v-list {
  background: transparent;
}

.v-list-item {
  min-height: 10px;
  height: 30px;
  padding: 4px 0;
}
</style>

