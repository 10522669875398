<template>
  <v-navigation-drawer
      clipped
	  width="270"
      :mini-variant="collapsed"
      stateless
      fixed
      app
      permanent
      color="periwinkel">

    <v-list class="pt-0">
      <v-list-item class="sidebar-title" v-if="activeSidebar.name">
        <div class="v-list-item__action">
		  <v-img :src="activeSidebar.icon" v-if="activeSidebar.icon" width="24" height="24"/>
		  <v-icon style="font-size: 24px" v-if="activeSidebar.mdIcon">{{ activeSidebar.mdIcon }}</v-icon>
        </div>
        <v-list-item-content>
          <v-list-item-title>
          {{ activeSidebar.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
	  <MtSideBarItem v-for="menuItem in activeSidebar.menuItems " :menuItem="menuItem" :collapsed="collapsed"></MtSideBarItem>
      <slot></slot>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'MtSideBar',
  provide() {
    return {
      MtSideBar: this.MtSideBar
    }
  },
  props: {
    sidebarMenues: Array,
	activeMenue: String,
    sidebarIcon: String,
    collapsed: Boolean
  },
  mounted() {
  },
  created() {
  },
  computed: {
    activeSidebar() {
	  return this.sidebarMenues.filter(menu => menu.id === this.activeMenue)[0]
	}
  }
}
</script>

<style scoped lang="scss">
.sidebar-title {
  background: var(--v-lavender_blue-base);
  border-bottom: 1px solid var(--v-midnight_blue-base);

  .v-list-item__title{
    font-weight: bold;
    color: var(--v-midnight_blue-base);
  }
}
</style>