<template>
  <li>
    <v-hover v-slot="{hover}" open-delay="100">
      <v-card
          @click="$emit('click')"
          :to="to"
          class="d-flex align-center"
          height="200"
          max-width="350"
          v-ripple>
        <div class="app-tile-card-content">
          <img v-if="icon" :src="icon" width="32" height="32">
		  <v-icon v-if="mdIcon" class="mt-32px">{{ mdIcon }}</v-icon>
          {{ title }}
        </div>
      </v-card>
    </v-hover>
  </li>

</template>

<script>
export default {
  name: "MtAppTile",
  props: {
    title: String,
    icon: String,
	mdIcon: String,
    to: String,
  }

}
</script>

<style scoped>
.v-card {
  background: linear-gradient(var(--v-periwinkel-base), var(--v-sail-base));
}

.v-card:hover {
  background: var(--v-titan_white-base);
}

.v-card:active {
  background: linear-gradient(0deg, var(--v-periwinkel-base), var(--v-sail-base));
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.app-tile-card-content {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
}

.app-tile-card-content i {
  margin-bottom: 4px;
}
</style>