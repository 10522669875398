<template>
  <div class="demo-page-buttons">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Buttons</mt-page-header>
  
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="basicbutton">Button Basic</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <!-- Buttons -->
          <div class="element-example-wrap">

            <h3>Demo:</h3>
			
			<v-btn
			  :disabled=btnDisabledState
			>
              Demo
            </v-btn>
			
			<v-checkbox
			  label="disabled"
			  v-model="btnDisabledState"
			>
			</v-checkbox>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6" v-if="!btnDisabledState">
              <pre v-highlightjs="sourceCode.basic_btn"><code class="html pa-0"></code></pre>
            </v-sheet>
			<v-sheet class="pa-6" v-if="btnDisabledState">
			  <pre v-highlightjs="sourceCode.basic_btn_disabled"><code class="html pa-0"></code></pre>
            </v-sheet>
          </div>
		  
		</v-col>
	  </v-row>
	</v-container>

	<!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="successbutton">Button Primary</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>
	
	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>

          <div class="element-example-wrap">
          
		    <h3>Demo:</h3>
		  
			<v-btn
			  class="success"
			  :disabled=btnSuccessDisabledState
			>
              Demo
            </v-btn>
			
			<v-checkbox
			  label="disabled"
			  v-model="btnSuccessDisabledState"
			>
			</v-checkbox>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6" v-if="!btnSuccessDisabledState">
              <pre v-highlightjs="sourceCode.success_btn"><code class="html pa-0"></code></pre>
            </v-sheet>
			<v-sheet class="pa-6" v-if="btnSuccessDisabledState">
              <pre v-highlightjs="sourceCode.success_btn_disabled"><code class="html pa-0"></code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
export default {
  data() {
    return {
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Basic',
          link: '#basicbutton'
        },
        {
          linkType: "position",
          name: 'Primary',
          link: '#successbutton'
        }
      ],
      sourceCode: {
        basic_btn: '<v-btn>\n' +
            '   Demo\n' +
            '</v-btn>',
		basic_btn_disabled: '<v-btn\n' +
			'       :disabled=disabledState\n' +
			'>\n' +
            '   Demo\n' +
            '</v-btn>',
        success_btn: '<v-btn\n' +
            '       class="success"\n' +
			'>\n' +
            '   Demo\n' +
            '</v-btn>',
		success_btn_disabled: '<v-btn\n' +
            '       class="success"\n' +
			'       :disabled=disabledState\n' +
			'>\n' +
            '   Demo\n' +
            '</v-btn>',
      },
	  btnDisabledState: false,
	  btnSuccessDisabledState: false
    }
  }
}
</script>

<style scoped>
.demo-page-buttons .element-example-wrap button {
  margin-bottom: 24px;
}
button + .v-input--checkbox {
  margin-top: 0;
}
</style>