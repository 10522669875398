<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Field_Signature_Revoked" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-field-signature-revoked">
    <rect id="Icon_Bounding_Box_32x32" width="32" height="32" fill="#bf1718" opacity="0"/>
    <path id="Pfad_1191" data-name="Pfad 1191" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z" transform="translate(1 1)" fill="#7e88b1"/>
    <path id="Pfad_1192" data-name="Pfad 1192" d="M773.086,1256.94l-2.855-2.854-6.145,6.144-6.144-6.144-2.856,2.854,6.145,6.146-6.145,6.144,2.856,2.856,6.144-6.146,6.145,6.146,2.855-2.856-6.144-6.144Z" transform="translate(-748.086 -1247.086)" fill="#fff"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "FieldSignatureRevoked"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
</style>

