<template>
  <div class="page-header" :style="[position, pageHeaderWidth]" ref="pageHeader">
    <v-container fluid class="py-0">
      <div>

          <v-breadcrumbs
              v-if="breadcrumbs"
              class="mtbreadcrubs d-print-none py-2"
              :items="breadcrumbs"
              divider=">"
          ></v-breadcrumbs>

          <h1>
            <slot></slot>
          </h1>

          <v-slide-group
              show-arrows
              class="d-print-none mt-2 mb-2"
              v-if="scrollNavigationList"
          >
            <v-slide-item v-for="(item, index) in scrollNavigationList" :key="index" v-if="item.linkType === 'position'">
              <v-btn
                  class="scroll-navigation-button"
                  plain
                  ref="link"
                  @click="$vuetify.goTo(item.link, options)"
              >
                <v-icon left>
                  mt-action-local-arrow
                </v-icon>
                {{ item.name }}
              </v-btn>
            </v-slide-item>
            <v-slide-item v-for="(item, index) in scrollNavigationList" :key="index" v-if="item.linkType === 'link'">
              <v-btn
                  class="scroll-navigation-button"
                  plain
                  ref="link"
                  :to="item.link"
              >
                <v-icon left>
                  mt-action-local-crosslink
                </v-icon>
                {{ item.name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>

        </div>
    </v-container>

  </div>
</template>
<script>

export default {
  name: "MtPageHeader",
  data() {
    return {
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      }
    }
  },
  props: {
    pageTitle: String,
    scrollNavigationList: Array,
	  breadcrumbs: Array
  },
  created: function(){
  },
  mounted: function(){
    this.$parent.$el.style.paddingTop = this.$el.clientHeight + 'px'
    this.scrollOptions.offset = this.$el.clientHeight + 20
  },
  computed: {
    position: function () {
      return {
        top: `${this.$vuetify.application.top + this.$vuetify.application.bar}px`,
        left: `${this.$vuetify.application.left}px`,
      }
    },
    pageHeaderWidth: function() {
      return {
        width: `calc(100% - ${this.$vuetify.application.left}px)`
      }
    },
    options () {
      return {
        duration: this.scrollOptions.duration,
        offset: this.scrollOptions.offset,
        easing: this.scrollOptions.easing,
      }
    },
  },
  destroyed: function () {
  },
  methods: {
  }
}
</script>

<style scoped>

.page-header {
  position: fixed;
  z-index: 1;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
  background: var(--v-titan_white-base);
  border-bottom: 1px solid var(--v-midnight_blue-base); 
}

.page-header h1 {
  position: relative;
  line-height: 3em;
  margin: 0;
  border: 0;
  z-index: 1;
}

.page-header h1:before {
  content: "";
  position: absolute;
  background: var(--v-white-base);
  width: calc(100% + 48px);
  height: 48px;
  left: -24px;
  top: 0;
  z-index: -1;
}

.mtbreadcrubs {
  line-height: 2em;
  padding: 0;
}

.v-list {
  padding: 0;
  margin-left: -16px;
  background-color: var(--v-titan_white-base);
}

.v-btn.scroll-navigation-button {
  background: transparent;
  height: 32px;
  padding: 0 48px 0 8px;
}

.v-btn.scroll-navigation-button:hover {
  background: var(--v-periwinkel-base);
}

.v-btn.scroll-navigation-button:active {
  background: linear-gradient(0deg, var(--v-periwinkel-base) 0%, var(--v-sail-base) 100%) 0% 0% no-repeat padding-box;
}

</style>