<template>
  <i class="mt-icon-tricolor">
  <svg id="Icon_Dialog_Finished" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="mt-icon mt-dialog-finished">
    <rect id="Icon_Bounding_Box_32x32" width="32" height="32" fill="#bf1718" opacity="0"/>
    <g id="Ellipse_131" data-name="Ellipse 131" transform="translate(1 1)" fill="#168a00" stroke="#fff" stroke-miterlimit="10" stroke-width="1">
      <circle cx="15" cy="15" r="15" stroke="none"/>
      <circle cx="15" cy="15" r="14.5" fill="none"/>
    </g>
    <path id="Pfad_934" data-name="Pfad 934" d="M830.7,1272.036l-9.181-6.121,2.219-3.328,5.819,3.879,8.271-12.683,3.35,2.186Z" transform="translate(-816 -1247)" fill="#fff"/>
  </svg>
  </i>
</template>

<script>
export default {
  name: "DialogFinished"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
</style>

