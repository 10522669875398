<template>
  <v-text-field
      :class="[state ? 'v-input--is-state--'+state : '']"
      v-bind="computedProps"
      @click:append="eyeToggle = !eyeToggle"
  >
    <!-- Dynamically inherit slots from parent -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>
<script>
import { VTextField } from 'vuetify/lib';
export default {
  name: "MtPasswordField",
  extends: VTextField,
  data() {
    return {
      eyeToggle: false,
      password: 'Password',
    }
  },
  props: {
    revealed: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: 'mdi-eye-off'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    state: null
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
        type: this.eyeToggle ? 'text' : 'password',
        appendIcon: this.eyeToggle ? 'mdi-eye' : 'mdi-eye-off'
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.v-input {

}
</style>