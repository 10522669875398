<template>
  <svg id="Icon_Field_Error" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <rect id="Icon_Bounding_Box_32x32" width="16" height="16" fill="#bf1718" opacity="0"/>
    <path id="Path_958" data-name="Path 958" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(1 1)" fill="#e6223a"/>
    <path id="Path_929" data-name="Path 929" d="M763.086,1255.355l-1.269-1.268-2.731,2.73-2.731-2.73-1.269,1.268,2.731,2.732-2.731,2.73,1.269,1.27,2.731-2.732,2.731,2.732,1.269-1.27-2.731-2.73Z" transform="translate(-751 -1250)" fill="#fff"/>
  </svg>
</template>

<script>
export default {
  name: "MtIconFieldError",
  props: {
    content: String
  },
}
</script>

