<template>
  <div>
    <v-badge
	  :inline=inline
      :color="statusTypes[status].color"
      :content="statusTypes[status].title">
      <h3><slot></slot></h3>
    </v-badge>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  data () {
    return {
      statusTypes: {
        draft: {
          title: "Draft",
          color: "logan"
        },
        progress: {
          title: "In development",
          color: "accent"
        },
        review_design: {
          title: "Ready for design review",
          color: "sun_glow"
        },
        review_tech: {
          title: "Ready for development review",
          color: "sun_glow"
        },
        ready: {
          title: "Ready",
          color: "lime_green"
        }
      }
    }
  },
  props: {
    status: String,
	inline: String
  }
}
</script>
