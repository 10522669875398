var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('data-table-actionbar',{scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [(slot.startsWith('actionbar'))?_vm._t(slot,null,null,scope):_vm._e()]}}})],null,true)},[_vm._v(" "+_vm._s(_vm.actionbar)+" "),_c('template',{slot:"defaultAction"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.add()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add ")],1),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove()}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Remove ")],1)],1)],2),_c('v-data-table',{staticClass:"search-data-table mb-0",attrs:{"headers":_vm.headers,"items":_vm.items,"dense":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'selected-row': item.selected },on:{"click":function($event){return _vm.selectRow(item)}}},[_c('td',[_c('mt-select',{attrs:{"menu-props":"auto","items":_vm.folderEntry.fieldTypes(),"outlined":""},on:{"change":function($event){return _vm.changedField(item)}},model:{value:(item.field),callback:function ($$v) {_vm.$set(item, "field", $$v)},expression:"item.field"}})],1),_c('td',[_c('mt-select',{attrs:{"items":_vm.folderEntry.operatorTypes(item.field),"outlined":""},on:{"change":function($event){return _vm.changedField(item)}},model:{value:(item.operator),callback:function ($$v) {_vm.$set(item, "operator", $$v)},expression:"item.operator"}})],1),_c('td',[(
                    _vm.folderEntry.argumentsControl(
                      item.field,
                      item.operator
                    )
                  )?_c(_vm.folderEntry.argumentsControl(
                      item.field,
                      item.operator
                    ).type,_vm._b({tag:"component",attrs:{"items":_vm.folderEntry.getEnum(item.field),"rules":[].concat( _vm.folderEntry.validationRules(
                      item.field,
                      item.operator) ),"outlined":""},model:{value:(item.argument),callback:function ($$v) {_vm.$set(item, "argument", $$v)},expression:"item.argument"}},'component',
                    _vm.folderEntry.argumentsControl(
                      item.field,
                      item.operator
                    ).args
                  ,false)):_vm._e()],1)])]}}])}),_c('div',{staticClass:"mt-pagination-wrap"},[_c('div',{staticClass:"px-5 mr-auto d-flex align-center"},[_c('span',[_vm._v("Rows per page")]),_c('v-select',{staticClass:"mt-rows-per-page mt-0",attrs:{"background-color":"titan_white","dense":"","solo":"","flat":"","hide-details":"","value":_vm.itemsPerPage,"items":_vm.rowsPerPage},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('div',{staticClass:"px-5"},[_vm._v("Page "+_vm._s(_vm.page)+" of "+_vm._s(_vm.pageCount)+" ("+_vm._s(_vm.items.length)+" rows)")]),_c('v-pagination',{attrs:{"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right","color":"corn_flower_blue","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }