<template>
  <div class="searchtable-demo-page">
    <!-- Page Title-->
    <mt-page-header>Search Table (Folder)</mt-page-header>
    <v-container>
      <!-- Element section-->
      <v-row class="mt-10">
        <v-col>

          <!-- Element Demo -->
          <div class="element-example-wrap">
            <h2>Search Table
              <status-badge status="review_design" inline="inline"></status-badge>
            </h2>

            <search-folder-edit actionbar="Filter Criteria">
              <template slot="actionbarDescription">
                <p>Enter filter criteria to view all audit trail records that match
                  these specific criteria.</p>
              </template>
            </search-folder-edit>

            <h3 class="mt-5">Usage:</h3>
            <!-- Element usage description -->
            <v-sheet class="pa-6">
              <!-- Element code snippet -->
              <h2>General</h2>
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.searchTable }}</code></pre>

              <h2 class="mt-5">With Actionbar</h2>
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.searchTableActionbar }}</code></pre>
            </v-sheet>

          </div>


        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import SearchFolderEdit from "../../src/lib-components/SearchFolderEdit";
export default {
  components: {
    SearchFolderEdit,
  },
  data() {
    return {
      dialog: false,
      sourceCode: {
        searchTable: '<search-folder-edit></search-folder-edit>',
        searchTableActionbar: '<search-folder-edit actionbar="Filter Criteria">\n' +
            '     <template slot="actionbarDescription">\n' +
            '       <p>Enter filter criteria to view all audit trail records that match these specific criteria.</p>\n' +
            '     </template>\n' +
            '</search-folder-edit>'
      }
    }
  }
}
</script>

<style scoped>


</style>

