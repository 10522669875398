<template>
  <div class="demo-page-progress">
  
    <mt-page-header :scrollNavigationList="scrollNavigationList">Progress & Loaders</mt-page-header>
  
    <!-- Elements Title-->
    <v-container>
      <v-row>
        <v-col>
          <h2 id="progresscircular">Progress circular</h2>
		  <status-badge status="review_design" inline="inline"></status-badge>
        </v-col>
      </v-row>
    </v-container>

	<!-- Elements section-->
    <v-container>
      <v-row>
        <v-col>
		
		  <!-- Progress circular -->
          <div class="element-example-wrap">

            <h3>Demo:</h3>
			
            <mt-progress-circular></mt-progress-circular>
			
			<h3>Usage:</h3>

            <!-- Element usage description-->
            <v-sheet class="pa-6">
              <!-- Element code snippet-->
              <pre v-highlightjs><code class="html pa-0">{{ sourceCode.progressCircular }}</code></pre>
            </v-sheet>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PropsTable from "../docs-components/PropsTable";

export default {
  name: 'ProgressDemoPage',
  components: {
    PropsTable
  },
  data() {
    return {
      dialog: false,
	  scrollNavigationList: [
        {
          linkType: "position",
          name: 'Progress circular',
          link: '#progresscircular'
        }
      ],
      sourceCode: {
        progressCircular: '<mt-progress-circular></mt-progress-circular>'
      }
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>
.element-example-wrap .mt-progress-circular {
  padding-bottom: 24px;
}
</style>
