<template>
  <v-combobox
    v-model="model"
    search-input="entry"
    :filter="filter"
    :hide-no-data="!entry"
    :items="items"
    hide-selected
    multiple
    outlined
    dense
    :menu-props="{ auto: false, overflowY: true, maxHeight: 200}"
  >
    <template #no-data>
      <v-list-item>
        <span class="subheading">Create</span>
        <v-chip color="secondary" label small>
          {{ entry }}
        </v-chip>
      </v-list-item>
    </template>
    <template #selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        color="secondary"
        :input-value="selected"
        label
        small
      >
        <span class="pr-2">
          {{ item.text }}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
    <template #item="{ index, item }">
      <mt-text-field
        v-if="editing === item"
        v-model="editing.text"
        autofocus
        flat
        background-color="transparent"
        hide-details
        solo
        @keyup.enter="edit(index, item)"
      ></mt-text-field>
      <v-chip v-else color="secondary" dark label small>
        {{ item.text }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-list-item-action @click.stop>
        <v-btn icon @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? "mdi-pencil" : "mdi-check" }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
const _ = require("lodash");
export default {
  data: () => ({
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    items: [{ header: "Select an option or create one" }],
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    entry: null,
    y: 0,
  }),

  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
          };
          this.items.push(v);
          this.nonce++;
        }
        this.$emit('input', this.entriesToArray(this.items));
        return v;
      });
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    entriesToArray(entries){
      var resultArray = [];
      for(var el in entries)
      {
        if(entries[el]["text"])
        {
         resultArray.push(entries[el]["text"]);
        }
      }
      return resultArray;
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
};
</script>
<style scoped>
.v-select.v-input--dense .v-chip {
  margin: 4px;
}

/deep/ .v-select__selections {
  max-height: 100px;
  overflow-y: auto;
}

</style>
