<template>
  <v-form>
    <v-container class="pa-0" fluid>
      <!-- actionbar with default buttons  -->
      <data-table-actionbar>
        {{ actionbar }}
        <template slot="defaultAction">
          <v-btn
              small
              text
              class="font-weight-bold"
              @click.stop="add()"
          >
            <v-icon>mdi-plus</v-icon>
            Add
          </v-btn>
          <v-btn
              small
              text
              class="font-weight-bold"
              @click.stop="remove()"
          >
            <v-icon>mdi-delete</v-icon>
            Remove
          </v-btn>
        </template>

        <!-- Dynamically inherit slots from parent, only actionbarXYZ -->
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" v-if="slot.startsWith('actionbar')"/>
        </template>
      </data-table-actionbar>

      <v-data-table class="search-data-table mb-0"
                    :headers="headers"
                    :items="items"
                    dense
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    @page-count="pageCount = $event"
      >
        <template #item="{ item }">
          <tr :class="{ 'selected-row': item.selected }"
              @click="selectRow(item)">
            <td>
              <mt-select v-model="item.field"
                         menu-props="auto"
                         :items="folderEntry.fieldTypes()"
                         @change="changedField(item)"
                         outlined>
              </mt-select>
            </td>
            <td>
              <mt-select v-model="item.operator"
                         :items="folderEntry.operatorTypes(item.field)"
                         @change="changedField(item)"
                         outlined>
              </mt-select>
            </td>
            <td>
              <component
                  v-model="item.argument"
                  :is="
                      folderEntry.argumentsControl(
                        item.field,
                        item.operator
                      ).type
                    "
                  v-if="
                      folderEntry.argumentsControl(
                        item.field,
                        item.operator
                      )
                    "
                  :items="folderEntry.getEnum(item.field)"
                  v-bind="
                      folderEntry.argumentsControl(
                        item.field,
                        item.operator
                      ).args
                    "
                  :rules="[
                      ...folderEntry.validationRules(
                        item.field,
                        item.operator),]"
                  outlined>
              </component>
            </td>
          </tr>
        </template>

      </v-data-table>

      <div class="mt-pagination-wrap">
        <div class="px-5 mr-auto d-flex align-center">
          <span>Rows per page</span>
          <v-select
              class="mt-rows-per-page mt-0"
              background-color="titan_white"
              dense
              solo
              flat
              hide-details
              :value="itemsPerPage"
              :items="rowsPerPage"
              @change="itemsPerPage = parseInt($event, 10)"
          ></v-select>
        </div>

        <div class="px-5">Page {{ page }} of {{ pageCount }} ({{ items.length }} rows)</div>
        <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            color="corn_flower_blue"
            v-model="page"
            :length="pageCount"
        ></v-pagination>

      </div>

    </v-container>
  </v-form>
</template>

<script lang="js">
import Vue from "vue";
import validationUtil from "./validationUtil";
import searchFolderEntry from "./search-folder-entry";
import MtDateTimePicker from "./MtDateTimePicker";
import MtTimePicker from "./MtTimeSpanPicker";
import MtChipList from "./MtChipList";
import MtTextField from "./extended/MtTextField";
import MtSelect from "./extended/MtSelect";
import UnitValuePair from "./UnitValuePair";
import DataTableActionbar from "./DataTableActionbar";

Vue.component('DataTableActionbar', DataTableActionbar);
Vue.component('MtDateTimePicker', MtDateTimePicker);
Vue.component('MtTimePicker', MtTimePicker);
Vue.component('MtChipList', MtChipList);
Vue.component("MtSelect", MtSelect);
Vue.component("MtTextField", MtTextField);
Vue.component('UnitValuePair', UnitValuePair);
Vue.component('QuantifiedPastTimeSelection', {
  extends: UnitValuePair,
  props: {
    units: {type: Array, default: () => ['pastdays', 'pastweeks', 'pastmonths', 'pastyears'],},
  },
});

Vue.component('PastTimeSelection', {
  extends: MtSelect,
  props: {
    items: {type: Array, default: () => ['today', 'thisWeek', 'thisMonth', 'thisYear'],},
  },
});

Vue.use(validationUtil);

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
    },
    folderId: {
      type: [Number, String],
      default: null,
    },
    folders: {
      type: Object,
    },
    actionbar: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      idx: 1,
      newFolder: {
        id: "",
        isFavourite: false,
        name: "",
      },
      hasErrors: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Field",
          align: "start",
          sortable: true,
          value: "field",
          width: "230px",
        },
        {text: "Operator", value: "operator", width: "230px"},
        {text: "Argument", value: "argument"},
      ],
      items: [],
      selectedRows: [],
      defaultItem: {
        field: "fullName",
        operator: "equals",
        argument: "",
      },
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rowsPerPage: [5, 10, 15],
    };
  },
  computed: {
    show: {
      get: function () {
        let value = this.value;
        return value;
      },
      set(value) {
        // eslint-disable-next-line
        this.$emit("input", value);
      },
    },
    validators: {
      get: function () {
        return validationUtil.rules;
      },
    },

    folderEntry: {
      get: function () {
        return searchFolderEntry;
      },
    },
    folder() {
      if (this.folderId) {
        return this.$store.getters.searchFolder(this.folderId);
      } else {
        return this.newFolder;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    getData: function () {
      return JSON.stringify(this.items);
    },
    setData: function (jsonData) {
      this.items = JSON.parse(jsonData);
    },
    selectRow(value) {
      // set active row and deselect others
      this.items.map((item, index) => {
        item.selected = item === value;
        this.$set(this.items, index, item);
      });
      this.selectedIndex = this.items.indexOf(value);
    },
    changedField(item) {
      var operatorTypes = this.folderEntry.operatorTypes(item.field);

      if (!operatorTypes.includes(item.operator)) {
        item.operator = this.folderEntry.operatorTypes(item.field)[0];
      }

    },
    remove() {
      this.items.splice(this.selectedIndex, 1);
    },
    add() {
      this.items.push(Object.assign({}, this.defaultItem));
    },
  },
});
</script>

<style scoped>
/deep/ .mt-rows-per-page > .v-input__control {
  min-height: 32px !important;
  max-width: 80px;
}

h2 {
  border-bottom: none;
}

/deep/ .selected-row {
  background: var(--v-corn_flower_blue-base);
}

/deep/ .selected-row:hover {
  background: var(--v-corn_flower_blue-base) !important;
}

</style>

<style scoped lang="scss">

.v-btn {
  max-height: 20px;
  font-size: 1rem;
}

.v-data-table {
  > .v-data-table__wrapper {
    > table {
      > tbody {
        > tr {
          > td {
            padding-top: 20px;
          }
        }
      }
    }
  }
}

.mt-pagination-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background: var(--v-titan_white-base);
  border: 1px solid var(--v-cold_purple-base);
  border-top: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 20px 8px 0 8px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding: 20px 8px 0 16px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding: 20px 16px 0 8px;
}

.v-input--is-disabled {
  display: none;
}

</style>