<template>
  <svg id="Icon_Field_Warning" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <rect id="Icon_Bounding_Box_16x16" width="16" height="16" fill="#bf1718" opacity="0"/>
    <g id="Path_472" data-name="Path 472" transform="translate(-1884 -2101)" fill="#ffcf33">
      <path d="M 1899.191162109375 2116.5 L 1884.809204101562 2116.5 L 1892.000122070312 2102.117919921875 L 1899.191162109375 2116.5 Z" stroke="none"/>
      <path d="M 1898.382080078125 2116 L 1892.000122070312 2103.236083984375 L 1885.618286132812 2116 L 1898.382080078125 2116 M 1900.000122070312 2117 L 1884.000244140625 2117 L 1892.000122070312 2101 L 1900.000122070312 2117 Z" stroke="none" fill="#000"/>
    </g>
    <path id="Path_978" data-name="Path 978" d="M-3010-11620v-2h2v2Zm.14-3.187-.14-4.809h2l-.207,4.809Z" transform="translate(3016.999 11634.004)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
  </svg>

</template>

<script>
export default {
  name: "MtIconFieldWarning",
  props: {
  },
}
</script>

