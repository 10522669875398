<template>

  <v-tab :to="to" @click="$emit('click')" :disabled="disabled" :ripple="false">
    {{ title }}
    <img :src="icon" width="32" height="32" v-if="icon"/>
    <v-icon v-if="mdIcon">{{ mdIcon }}</v-icon>
  </v-tab>

</template>

<script>
export default {
  name: 'MtHeaderItem',
  props: {
    title: String,
    icon: String,
    mdIcon: String,
    primary: Boolean,
    to: String,
	disabled: Boolean
  },
}
</script>