<template>
  <div class="apps-page">
  
    <mt-page-header>LabX Apps</mt-page-header>
  
  </div>
</template>

<script>

export default {
  name: 'AppsPage',
  components: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>

</style>


