<template>
  <i class="mt-icon-tricolor mt-spin">
  <svg id="Icon_Overlay_Busy" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" class="mt-icon mt-overlay-busy">
  <rect id="Rechteck_6816" data-name="Rechteck 6816" width="96" height="96" fill="none"/>
  <g id="Gruppe_3832" data-name="Gruppe 3832" transform="translate(-1224.667 -1128.667)">
    <path id="Pfad_769" data-name="Pfad 769" d="M1257.841,1145.347a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1257.841,1145.347Z" class="a"/>
    <path id="Pfad_770" data-name="Pfad 770" d="M1256.814,1146.111a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1256.814,1146.111Z" class="a" opacity="0.993"/>
    <path id="Pfad_771" data-name="Pfad 771" d="M1255.809,1146.911a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1255.809,1146.911Z" class="a" opacity="0.987"/>
    <path id="Pfad_772" data-name="Pfad 772" d="M1254.832,1147.742a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1254.832,1147.742Z" class="a" opacity="0.98"/>
    <path id="Pfad_773" data-name="Pfad 773" d="M1253.882,1148.6a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1253.882,1148.6Z" class="a" opacity="0.974"/>
    <path id="Pfad_774" data-name="Pfad 774" d="M1252.962,1149.5a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1252.962,1149.5Z" class="a" opacity="0.967"/>
    <path id="Pfad_775" data-name="Pfad 775" d="M1252.071,1150.419a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1252.071,1150.419Z" class="a" opacity="0.96"/>
    <path id="Pfad_776" data-name="Pfad 776" d="M1251.21,1151.37a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1251.21,1151.37Z" class="a" opacity="0.954"/>
    <path id="Pfad_777" data-name="Pfad 777" d="M1250.38,1152.348a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1250.38,1152.348Z" class="a" opacity="0.947"/>
    <path id="Pfad_778" data-name="Pfad 778" d="M1249.582,1153.354a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1249.582,1153.354Z" class="a" opacity="0.94"/>
    <path id="Pfad_779" data-name="Pfad 779" d="M1248.817,1154.386a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1248.817,1154.386Z" class="a" opacity="0.934"/>
    <path id="Pfad_780" data-name="Pfad 780" d="M1248.085,1155.443a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1248.085,1155.443Z" class="a" opacity="0.927"/>
    <path id="Pfad_781" data-name="Pfad 781" d="M1247.388,1156.525a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1247.388,1156.525Z" class="a" opacity="0.921"/>
    <path id="Pfad_782" data-name="Pfad 782" d="M1246.725,1157.632a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1246.725,1157.632Z" class="a" opacity="0.914"/>
    <path id="Pfad_783" data-name="Pfad 783" d="M1246.1,1158.761a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1246.1,1158.761Z" class="a" opacity="0.907"/>
    <path id="Pfad_784" data-name="Pfad 784" d="M1245.508,1159.913a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1245.508,1159.913Z" class="a" opacity="0.901"/>
    <path id="Pfad_785" data-name="Pfad 785" d="M1244.955,1161.087a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1244.955,1161.087Z" class="a" opacity="0.894"/>
    <path id="Pfad_786" data-name="Pfad 786" d="M1244.44,1162.281a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1244.44,1162.281Z" class="a" opacity="0.887"/>
    <path id="Pfad_787" data-name="Pfad 787" d="M1243.964,1163.5a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1243.964,1163.5Z" class="a" opacity="0.881"/>
    <path id="Pfad_788" data-name="Pfad 788" d="M1243.528,1164.729a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1243.528,1164.729Z" class="a" opacity="0.874"/>
    <path id="Pfad_789" data-name="Pfad 789" d="M1243.132,1165.982a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1243.132,1165.982Z" class="a" opacity="0.868"/>
    <path id="Pfad_790" data-name="Pfad 790" d="M1242.777,1167.252a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1242.777,1167.252Z" class="a" opacity="0.861"/>
    <path id="Pfad_791" data-name="Pfad 791" d="M1242.465,1168.539a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1242.465,1168.539Z" class="a" opacity="0.854"/>
    <path id="Pfad_792" data-name="Pfad 792" d="M1242.195,1169.842a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1242.195,1169.842Z" class="a" opacity="0.848"/>
    <path id="Pfad_793" data-name="Pfad 793" d="M1241.969,1171.161a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.969,1171.161Z" class="a" opacity="0.841"/>
    <path id="Pfad_794" data-name="Pfad 794" d="M1241.787,1172.494a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.787,1172.494Z" class="a" opacity="0.834"/>
    <path id="Pfad_795" data-name="Pfad 795" d="M1241.65,1173.841a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.65,1173.841Z" class="a" opacity="0.828"/>
    <path id="Pfad_796" data-name="Pfad 796" d="M1241.56,1175.2a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.56,1175.2Z" class="a" opacity="0.821"/>
    <path id="Pfad_797" data-name="Pfad 797" d="M1241.516,1176.574a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.516,1176.574Z" class="a" opacity="0.815"/>
    <path id="Pfad_798" data-name="Pfad 798" d="M1241.519,1177.952a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.519,1177.952Z" class="a" opacity="0.808"/>
    <path id="Pfad_799" data-name="Pfad 799" d="M1241.57,1179.317a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.57,1179.317Z" class="a" opacity="0.801"/>
    <path id="Pfad_800" data-name="Pfad 800" d="M1241.666,1180.671a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.666,1180.671Z" class="a" opacity="0.795"/>
    <path id="Pfad_801" data-name="Pfad 801" d="M1241.808,1182.011a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.808,1182.011Z" class="a" opacity="0.788"/>
    <path id="Pfad_802" data-name="Pfad 802" d="M1241.995,1183.337a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1241.995,1183.337Z" class="a" opacity="0.781"/>
    <path id="Pfad_803" data-name="Pfad 803" d="M1242.226,1184.649a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1242.226,1184.649Z" class="a" opacity="0.775"/>
    <path id="Pfad_804" data-name="Pfad 804" d="M1242.5,1185.946a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1242.5,1185.946Z" class="a" opacity="0.768"/>
    <path id="Pfad_805" data-name="Pfad 805" d="M1242.816,1187.226a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1242.816,1187.226Z" class="a" opacity="0.762"/>
    <path id="Pfad_806" data-name="Pfad 806" d="M1243.174,1188.49a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1243.174,1188.49Z" class="a" opacity="0.755"/>
    <path id="Pfad_807" data-name="Pfad 807" d="M1243.573,1189.735a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1243.573,1189.735Z" class="a" opacity="0.748"/>
    <path id="Pfad_808" data-name="Pfad 808" d="M1244.012,1190.962a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1244.012,1190.962Z" class="a" opacity="0.742"/>
    <path id="Pfad_809" data-name="Pfad 809" d="M1244.49,1192.17a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1244.49,1192.17Z" class="a" opacity="0.735"/>
    <path id="Pfad_810" data-name="Pfad 810" d="M1245.007,1193.358a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1245.007,1193.358Z" class="a" opacity="0.728"/>
    <path id="Pfad_811" data-name="Pfad 811" d="M1245.561,1194.525a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1245.561,1194.525Z" class="a" opacity="0.722"/>
    <path id="Pfad_812" data-name="Pfad 812" d="M1246.153,1195.671a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1246.153,1195.671Z" class="a" opacity="0.715"/>
    <path id="Pfad_813" data-name="Pfad 813" d="M1246.78,1196.794a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1246.78,1196.794Z" class="a" opacity="0.709"/>
    <path id="Pfad_814" data-name="Pfad 814" d="M1247.443,1197.894a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1247.443,1197.894Z" class="a" opacity="0.702"/>
    <path id="Pfad_815" data-name="Pfad 815" d="M1248.141,1198.971a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1248.141,1198.971Z" class="a" opacity="0.695"/>
    <path id="Pfad_816" data-name="Pfad 816" d="M1248.872,1200.022a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1248.872,1200.022Z" class="a" opacity="0.689"/>
    <path id="Pfad_817" data-name="Pfad 817" d="M1249.637,1201.048a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1249.637,1201.048Z" class="a" opacity="0.682"/>
    <path id="Pfad_818" data-name="Pfad 818" d="M1250.434,1202.048a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1250.434,1202.048Z" class="a" opacity="0.675"/>
    <path id="Pfad_819" data-name="Pfad 819" d="M1251.263,1203.021a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1251.263,1203.021Z" class="a" opacity="0.669"/>
    <path id="Pfad_820" data-name="Pfad 820" d="M1252.122,1203.966a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1252.122,1203.966Z" class="a" opacity="0.662"/>
    <path id="Pfad_821" data-name="Pfad 821" d="M1253.012,1204.883a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1253.012,1204.883Z" class="a" opacity="0.656"/>
    <path id="Pfad_822" data-name="Pfad 822" d="M1253.93,1205.77a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1253.93,1205.77Z" class="a" opacity="0.649"/>
    <path id="Pfad_823" data-name="Pfad 823" d="M1254.877,1206.627a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1254.877,1206.627Z" class="a" opacity="0.642"/>
    <path id="Pfad_824" data-name="Pfad 824" d="M1255.852,1207.454a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1255.852,1207.454Z" class="a" opacity="0.636"/>
    <path id="Pfad_825" data-name="Pfad 825" d="M1256.854,1208.249a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1256.854,1208.249Z" class="a" opacity="0.629"/>
    <path id="Pfad_826" data-name="Pfad 826" d="M1257.882,1209.011a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1257.882,1209.011Z" class="a" opacity="0.623"/>
    <path id="Pfad_827" data-name="Pfad 827" d="M1258.935,1209.74a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1258.935,1209.74Z" class="a" opacity="0.616"/>
    <path id="Pfad_828" data-name="Pfad 828" d="M1260.013,1210.436a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1260.013,1210.436Z" class="a" opacity="0.609"/>
    <path id="Pfad_829" data-name="Pfad 829" d="M1261.115,1211.1a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1261.115,1211.1Z" class="a" opacity="0.603"/>
    <path id="Pfad_830" data-name="Pfad 830" d="M1262.239,1211.721a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1262.239,1211.721Z" class="a" opacity="0.596"/>
    <path id="Pfad_831" data-name="Pfad 831" d="M1263.386,1212.31a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1263.386,1212.31Z" class="a" opacity="0.589"/>
    <path id="Pfad_832" data-name="Pfad 832" d="M1264.555,1212.862a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1264.555,1212.862Z" class="a" opacity="0.583"/>
    <path id="Pfad_833" data-name="Pfad 833" d="M1265.744,1213.376a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1265.744,1213.376Z" class="a" opacity="0.576"/>
    <path id="Pfad_834" data-name="Pfad 834" d="M1266.954,1213.852a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1266.954,1213.852Z" class="a" opacity="0.57"/>
    <path id="Pfad_835" data-name="Pfad 835" d="M1268.182,1214.288a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1268.182,1214.288Z" class="a" opacity="0.563"/>
    <path id="Pfad_836" data-name="Pfad 836" d="M1269.429,1214.684a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1269.429,1214.684Z" class="a" opacity="0.556"/>
    <path id="Pfad_837" data-name="Pfad 837" d="M1270.694,1215.039a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1270.694,1215.039Z" class="a" opacity="0.55"/>
    <path id="Pfad_838" data-name="Pfad 838" d="M1271.975,1215.352a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1271.975,1215.352Z" class="a" opacity="0.543"/>
    <path id="Pfad_839" data-name="Pfad 839" d="M1273.273,1215.623a7.5,7.5,0,1,0-7.5,7.5A7.5,7.5,0,0,0,1273.273,1215.623Z" class="a" opacity="0.536"/>
    <path id="Pfad_840" data-name="Pfad 840" d="M1274.586,1215.851a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1274.586,1215.851Z" class="a" opacity="0.53"/>
    <path id="Pfad_841" data-name="Pfad 841" d="M1275.913,1216.035a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1275.913,1216.035Z" class="a" opacity="0.523"/>
    <path id="Pfad_842" data-name="Pfad 842" d="M1277.254,1216.174a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1277.254,1216.174Z" class="a" opacity="0.517"/>
    <path id="Pfad_843" data-name="Pfad 843" d="M1278.609,1216.267a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1278.609,1216.267Z" class="a" opacity="0.51"/>
    <path id="Pfad_844" data-name="Pfad 844" d="M1279.975,1216.315a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1279.975,1216.315Z" class="a" opacity="0.503"/>
    <path id="Pfad_845" data-name="Pfad 845" d="M1281.35,1216.315a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1281.35,1216.315Z" class="a" opacity="0.497"/>
    <path id="Pfad_846" data-name="Pfad 846" d="M1282.717,1216.268a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1282.717,1216.268Z" class="a" opacity="0.49"/>
    <path id="Pfad_847" data-name="Pfad 847" d="M1284.071,1216.175a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1284.071,1216.175Z" class="a" opacity="0.483"/>
    <path id="Pfad_848" data-name="Pfad 848" d="M1285.413,1216.036a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1285.413,1216.036Z" class="a" opacity="0.477"/>
    <path id="Pfad_849" data-name="Pfad 849" d="M1286.74,1215.852a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1286.74,1215.852Z" class="a" opacity="0.47"/>
    <path id="Pfad_850" data-name="Pfad 850" d="M1288.053,1215.625a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1288.053,1215.625Z" class="a" opacity="0.464"/>
    <path id="Pfad_851" data-name="Pfad 851" d="M1289.351,1215.354a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1289.351,1215.354Z" class="a" opacity="0.457"/>
    <path id="Pfad_852" data-name="Pfad 852" d="M1290.632,1215.041a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1290.632,1215.041Z" class="a" opacity="0.45"/>
    <path id="Pfad_853" data-name="Pfad 853" d="M1291.9,1214.686a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1291.9,1214.686Z" class="a" opacity="0.444"/>
    <path id="Pfad_854" data-name="Pfad 854" d="M1293.144,1214.29a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1293.144,1214.29Z" class="a" opacity="0.437"/>
    <path id="Pfad_855" data-name="Pfad 855" d="M1294.373,1213.855a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1294.373,1213.855Z" class="a" opacity="0.43"/>
    <path id="Pfad_856" data-name="Pfad 856" d="M1295.582,1213.379a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1295.582,1213.379Z" class="a" opacity="0.424"/>
    <path id="Pfad_857" data-name="Pfad 857" d="M1296.771,1212.866a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1296.771,1212.866Z" class="a" opacity="0.417"/>
    <path id="Pfad_858" data-name="Pfad 858" d="M1297.94,1212.314a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1297.94,1212.314Z" class="a" opacity="0.411"/>
    <path id="Pfad_859" data-name="Pfad 859" d="M1299.087,1211.725a7.5,7.5,0,1,0-7.5,7.5A7.505,7.505,0,0,0,1299.087,1211.725Z" class="a" opacity="0.404"/>
    <path id="Pfad_860" data-name="Pfad 860" d="M1300.212,1211.1a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1300.212,1211.1Z" class="a" opacity="0.397"/>
    <path id="Pfad_861" data-name="Pfad 861" d="M1301.314,1210.44a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1301.314,1210.44Z" class="a" opacity="0.391"/>
    <path id="Pfad_862" data-name="Pfad 862" d="M1302.392,1209.745a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1302.392,1209.745Z" class="a" opacity="0.384"/>
    <path id="Pfad_863" data-name="Pfad 863" d="M1303.446,1209.016a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1303.446,1209.016Z" class="a" opacity="0.377"/>
    <path id="Pfad_864" data-name="Pfad 864" d="M1304.474,1208.254a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1304.474,1208.254Z" class="a" opacity="0.371"/>
    <path id="Pfad_865" data-name="Pfad 865" d="M1305.476,1207.459a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1305.476,1207.459Z" class="a" opacity="0.364"/>
    <path id="Pfad_866" data-name="Pfad 866" d="M1306.45,1206.633a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1306.45,1206.633Z" class="a" opacity="0.358"/>
    <path id="Pfad_867" data-name="Pfad 867" d="M1307.4,1205.776a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1307.4,1205.776Z" class="a" opacity="0.351"/>
    <path id="Pfad_868" data-name="Pfad 868" d="M1308.317,1204.889a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1308.317,1204.889Z" class="a" opacity="0.344"/>
    <path id="Pfad_869" data-name="Pfad 869" d="M1309.206,1203.973a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1309.206,1203.973Z" class="a" opacity="0.338"/>
    <path id="Pfad_870" data-name="Pfad 870" d="M1310.066,1203.028a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1310.066,1203.028Z" class="a" opacity="0.331"/>
    <path id="Pfad_871" data-name="Pfad 871" d="M1310.894,1202.055a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1310.894,1202.055Z" class="a" opacity="0.325"/>
    <path id="Pfad_872" data-name="Pfad 872" d="M1311.692,1201.055a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1311.692,1201.055Z" class="a" opacity="0.318"/>
    <path id="Pfad_873" data-name="Pfad 873" d="M1312.457,1200.029a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1312.457,1200.029Z" class="a" opacity="0.311"/>
    <path id="Pfad_874" data-name="Pfad 874" d="M1313.188,1198.978a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1313.188,1198.978Z" class="a" opacity="0.305"/>
    <path id="Pfad_875" data-name="Pfad 875" d="M1313.886,1197.9a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1313.886,1197.9Z" class="a" opacity="0.298"/>
    <path id="Pfad_876" data-name="Pfad 876" d="M1314.549,1196.8a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1314.549,1196.8Z" class="a" opacity="0.291"/>
    <path id="Pfad_877" data-name="Pfad 877" d="M1315.177,1195.679a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1315.177,1195.679Z" class="a" opacity="0.285"/>
    <path id="Pfad_878" data-name="Pfad 878" d="M1315.769,1194.533a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1315.769,1194.533Z" class="a" opacity="0.278"/>
    <path id="Pfad_879" data-name="Pfad 879" d="M1316.323,1193.367a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1316.323,1193.367Z" class="a" opacity="0.272"/>
    <path id="Pfad_880" data-name="Pfad 880" d="M1316.84,1192.179a7.5,7.5,0,1,0-7.5,7.5A7.506,7.506,0,0,0,1316.84,1192.179Z" class="a" opacity="0.265"/>
    <path id="Pfad_881" data-name="Pfad 881" d="M1317.319,1190.971a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1317.319,1190.971Z" class="a" opacity="0.258"/>
    <path id="Pfad_882" data-name="Pfad 882" d="M1317.758,1189.744a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1317.758,1189.744Z" class="a" opacity="0.252"/>
    <path id="Pfad_883" data-name="Pfad 883" d="M1318.157,1188.5a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1318.157,1188.5Z" class="a" opacity="0.245"/>
    <path id="Pfad_884" data-name="Pfad 884" d="M1318.515,1187.235a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1318.515,1187.235Z" class="a" opacity="0.238"/>
    <path id="Pfad_885" data-name="Pfad 885" d="M1318.832,1185.955a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1318.832,1185.955Z" class="a" opacity="0.232"/>
    <path id="Pfad_886" data-name="Pfad 886" d="M1319.106,1184.659a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1319.106,1184.659Z" class="a" opacity="0.225"/>
    <path id="Pfad_887" data-name="Pfad 887" d="M1319.337,1183.347a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1319.337,1183.347Z" class="a" opacity="0.219"/>
    <path id="Pfad_888" data-name="Pfad 888" d="M1319.524,1182.02a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1319.524,1182.02Z" class="a" opacity="0.212"/>
    <path id="Pfad_889" data-name="Pfad 889" d="M1319.666,1180.68a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1319.666,1180.68Z" class="a" opacity="0.205"/>
    <path id="Pfad_890" data-name="Pfad 890" d="M1319.763,1179.327a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1319.763,1179.327Z" class="a" opacity="0.199"/>
    <path id="Pfad_891" data-name="Pfad 891" d="M1319.814,1177.961a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1319.814,1177.961Z" class="a" opacity="0.192"/>
    <path id="Pfad_892" data-name="Pfad 892" d="M1319.818,1176.584a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1319.818,1176.584Z" class="a" opacity="0.185"/>
    <path id="Pfad_893" data-name="Pfad 893" d="M1319.774,1175.211a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1319.774,1175.211Z" class="a" opacity="0.179"/>
    <path id="Pfad_894" data-name="Pfad 894" d="M1319.684,1173.851a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1319.684,1173.851Z" class="a" opacity="0.172"/>
    <path id="Pfad_895" data-name="Pfad 895" d="M1319.547,1172.5a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1319.547,1172.5Z" class="a" opacity="0.166"/>
    <path id="Pfad_896" data-name="Pfad 896" d="M1319.366,1171.17a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1319.366,1171.17Z" class="a" opacity="0.159"/>
    <path id="Pfad_897" data-name="Pfad 897" d="M1319.14,1169.851a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1319.14,1169.851Z" class="a" opacity="0.152"/>
    <path id="Pfad_898" data-name="Pfad 898" d="M1318.87,1168.547a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1318.87,1168.547Z" class="a" opacity="0.146"/>
    <path id="Pfad_899" data-name="Pfad 899" d="M1318.558,1167.26a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1318.558,1167.26Z" class="a" opacity="0.139"/>
    <path id="Pfad_900" data-name="Pfad 900" d="M1318.2,1165.99a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1318.2,1165.99Z" class="a" opacity="0.132"/>
    <path id="Pfad_901" data-name="Pfad 901" d="M1317.808,1164.737a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1317.808,1164.737Z" class="a" opacity="0.126"/>
    <path id="Pfad_902" data-name="Pfad 902" d="M1317.372,1163.5a7.5,7.5,0,1,0-7.5,7.5A7.507,7.507,0,0,0,1317.372,1163.5Z" class="a" opacity="0.119"/>
    <path id="Pfad_903" data-name="Pfad 903" d="M1316.9,1162.289a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1316.9,1162.289Z" class="a" opacity="0.113"/>
    <path id="Pfad_904" data-name="Pfad 904" d="M1316.382,1161.094a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1316.382,1161.094Z" class="a" opacity="0.106"/>
    <path id="Pfad_905" data-name="Pfad 905" d="M1315.829,1159.92a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1315.829,1159.92Z" class="a" opacity="0.099"/>
    <path id="Pfad_906" data-name="Pfad 906" d="M1315.239,1158.768a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1315.239,1158.768Z" class="a" opacity="0.093"/>
    <path id="Pfad_907" data-name="Pfad 907" d="M1314.613,1157.639a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1314.613,1157.639Z" class="a" opacity="0.086"/>
    <path id="Pfad_908" data-name="Pfad 908" d="M1313.95,1156.532a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1313.95,1156.532Z" class="a" opacity="0.079"/>
    <path id="Pfad_909" data-name="Pfad 909" d="M1313.253,1155.45a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1313.253,1155.45Z" class="a" opacity="0.073"/>
    <path id="Pfad_910" data-name="Pfad 910" d="M1312.522,1154.392a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1312.522,1154.392Z" class="a" opacity="0.066"/>
    <path id="Pfad_911" data-name="Pfad 911" d="M1311.757,1153.36a7.5,7.5,0,1,0-7.5,7.5A7.508,7.508,0,0,0,1311.757,1153.36Z" class="a" opacity="0.06"/>
    <path id="Pfad_912" data-name="Pfad 912" d="M1310.959,1152.355a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1310.959,1152.355Z" class="a" opacity="0.053"/>
    <path id="Pfad_913" data-name="Pfad 913" d="M1310.13,1151.376a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1310.13,1151.376Z" class="a" opacity="0.046"/>
    <path id="Pfad_914" data-name="Pfad 914" d="M1309.269,1150.425a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1309.269,1150.425Z" class="a" opacity="0.04"/>
    <path id="Pfad_915" data-name="Pfad 915" d="M1308.378,1149.5a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1308.378,1149.5Z" class="a" opacity="0.033"/>
    <path id="Pfad_916" data-name="Pfad 916" d="M1307.458,1148.611a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1307.458,1148.611Z" class="a" opacity="0.026"/>
    <path id="Pfad_917" data-name="Pfad 917" d="M1306.509,1147.748a7.5,7.5,0,1,0-7.5,7.5A7.509,7.509,0,0,0,1306.509,1147.748Z" class="a" opacity="0.02"/>
    <circle id="Ellipse_125" data-name="Ellipse 125" cx="7.5" cy="7.5" r="7.5" transform="translate(1288.922 1141.485) rotate(-14.194)" class="a" opacity="0.013"/>
    <circle id="Ellipse_126" data-name="Ellipse 126" cx="7.5" cy="7.5" r="7.5" transform="translate(1287.785 1140.914) rotate(-15.624)" class="a" opacity="0.007"/>
    <circle id="Ellipse_127" data-name="Ellipse 127" cx="7.5" cy="7.5" r="7.5" transform="translate(1287.393 1139.148) rotate(-9.217)" class="a" opacity="0"/>
  </g>
  <line id="Linie_12" data-name="Linie 12" x2="79" transform="translate(9 49)" fill="none"/>
  <circle id="Ellipse_128" data-name="Ellipse 128" cx="46.5" cy="46.5" r="46.5" transform="translate(2 2)" fill="none"/>
</svg>

  </i>
</template>

<script>
export default {
  name: "OverlayBusy"
}
</script>

<style scoped>
  .mt-icon-tricolor {
    width: 100%;
	height: 100%;
  }
  .mt-icon-tricolor svg {
    width: 100%;
	height: 100%;
  }
  .a {
    fill: currentColor;
  }
</style>

